/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Meta record for result pagination. All results from API are paginated, ie. delivered in chunks of X results. This records describes how many results there are in total, and how to access previous and next pages.
 * @export
 * @interface MetaDefinition
 */
export interface MetaDefinition {
    /**
     * Total amount of items found
     * @type {number}
     * @memberof MetaDefinition
     */
    count?: number;
    /**
     * URL for the next page of items
     * @type {string}
     * @memberof MetaDefinition
     */
    next?: string;
    /**
     * URL for the previous page of items
     * @type {string}
     * @memberof MetaDefinition
     */
    previous?: string;
}

export function MetaDefinitionFromJSON(json: any): MetaDefinition {
    return MetaDefinitionFromJSONTyped(json, false);
}

export function MetaDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
    };
}

export function MetaDefinitionToJSON(value?: MetaDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
    };
}


