/**
 * Set keycloak action
 */
export const SET_KEYCLOAK = "SET_KEYCLOAK";
export type SET_KEYCLOAK = typeof SET_KEYCLOAK;

/**
 * Login action
 */
export const LOGIN = "LOGIN";
export type LOGIN = typeof LOGIN;

/**
 * Logout action
 */
export const LOGOUT = "LOGOUT";
export type LOGOUT = typeof LOGOUT;

/**
 * Set locale action
 */
export const SET_LOCALE = "SET_LOCALE";
export type SET_LOCALE = typeof SET_LOCALE;

/**
 * Event link names array
 */
export const EVENT_LINK_NAME = [ "YouTube", "Twitter", "Facebook", "Instagram", "Instagram HashTag", "YouTube Or Vimeo Link", "Event Organizer Link" ];