/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Event,
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
    Eventlink,
    EventlinkFromJSON,
    EventlinkFromJSONTyped,
    EventlinkToJSON,
    IdRef,
    IdRefFromJSON,
    IdRefFromJSONTyped,
    IdRefToJSON,
    Keyword,
    KeywordFromJSON,
    KeywordFromJSONTyped,
    KeywordToJSON,
    Language,
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
    Offer,
    OfferFromJSON,
    OfferFromJSONTyped,
    OfferToJSON,
    PostEventDescription,
    PostEventDescriptionFromJSON,
    PostEventDescriptionFromJSONTyped,
    PostEventDescriptionToJSON,
    PostEventExtensionCourse,
    PostEventExtensionCourseFromJSON,
    PostEventExtensionCourseFromJSONTyped,
    PostEventExtensionCourseToJSON,
    PostEventInfoUrl,
    PostEventInfoUrlFromJSON,
    PostEventInfoUrlFromJSONTyped,
    PostEventInfoUrlToJSON,
    PostEventLocationExtraInfo,
    PostEventLocationExtraInfoFromJSON,
    PostEventLocationExtraInfoFromJSONTyped,
    PostEventLocationExtraInfoToJSON,
    PostEventName,
    PostEventNameFromJSON,
    PostEventNameFromJSONTyped,
    PostEventNameToJSON,
    PostEventProvider,
    PostEventProviderFromJSON,
    PostEventProviderFromJSONTyped,
    PostEventProviderToJSON,
    PostEventShortDescription,
    PostEventShortDescriptionFromJSON,
    PostEventShortDescriptionFromJSONTyped,
    PostEventShortDescriptionToJSON,
} from './';

/**
 * Describes the actual events. Linked events API supports organizing events into hierarchies. This is implemented with collection events called "super events". Super events are normal event objects, that reference contained events in "sub_events" property. Currently there are two major use cases: events such as "Helsinki Festival", which consist of unique events over a span of time and recurring events such as theatrical productions with multiple showings. It is implementation dependent how the grouping of events is done. It should be noted that grouping might be automatic based on eg. event name and thus group unrelated events together and miss related events. Users of data are advised to prepare for this.
 * @export
 * @interface PostEvent
 */
export interface PostEvent {
    /**
     * consists of source prefix and source specific identifier. These should be URIs uniquely identifying the event, and preferably also well formed http-URLs pointing to more information about the event.
     * @type {string}
     * @memberof PostEvent
     */
    id?: string;
    /**
     * 
     * @type {IdRef}
     * @memberof PostEvent
     */
    location: IdRef;
    /**
     * The keywords that describe the topic and type of this event.
     * @type {Array<IdRef>}
     * @memberof PostEvent
     */
    keywords: Array<IdRef>;
    /**
     * the languages spoken or supported at the event
     * @type {Array<Language>}
     * @memberof PostEvent
     */
    inLanguage?: Array<Language>;
    /**
     * 
     * @type {IdRef}
     * @memberof PostEvent
     */
    superEvent?: IdRef;
    /**
     * If the event has sub_events, describes the type of the event. Current options are 'null', 'recurring', which means a repeating event, and 'umbrella', which means a major event that has subevents.
     * @type {string}
     * @memberof PostEvent
     */
    superEventType?: string;
    /**
     * As defined in schema.org/Event. Postponed events do not have a date set, rescheduled events have been moved to different date.
     * @type {string}
     * @memberof PostEvent
     */
    eventStatus?: string;
    /**
     * Only available in POST/PUT. Specifies whether the event should be published in the API ('public') or not ('draft').
     * @type {string}
     * @memberof PostEvent
     */
    publicationStatus: string;
    /**
     * See external link definition
     * @type {Array<Eventlink>}
     * @memberof PostEvent
     */
    externalLinks?: Array<Eventlink>;
    /**
     * See offer definition
     * @type {Array<Offer>}
     * @memberof PostEvent
     */
    offers?: Array<Offer>;
    /**
     * for aggregate events this contains references to all sub events. Usually this means that the sub events are part of series. The field 'super_event_type' tells the type of the aggregate event.
     * @type {Array<IdRef>}
     * @memberof PostEvent
     */
    subEvents?: Array<IdRef>;
    /**
     * Key value field for custom data. FIXME: is there 6Aika-wide use case for this?
     * @type {object}
     * @memberof PostEvent
     */
    customData?: object;
    /**
     * 
     * @type {PostEventName}
     * @memberof PostEvent
     */
    name: PostEventName;
    /**
     * 
     * @type {Array<IdRef>}
     * @memberof PostEvent
     */
    images?: Array<IdRef>;
    /**
     * Creation time for the event entry.
     * @type {Date}
     * @memberof PostEvent
     */
    createdTime?: Date;
    /**
     * Time this event was modified in the datastore behind the API (not necessarily in the originating system)
     * @type {Date}
     * @memberof PostEvent
     */
    lastModifiedTime?: Date;
    /**
     * 
     * @type {PostEventInfoUrl}
     * @memberof PostEvent
     */
    infoUrl?: PostEventInfoUrl;
    /**
     * 
     * @type {PostEventDescription}
     * @memberof PostEvent
     */
    description?: PostEventDescription;
    /**
     * 
     * @type {PostEventShortDescription}
     * @memberof PostEvent
     */
    shortDescription?: PostEventShortDescription;
    /**
     * 
     * @type {string}
     * @memberof PostEvent
     */
    context?: string;
    /**
     * 
     * @type {string}
     * @memberof PostEvent
     */
    type?: string;
    /**
     * Date this event is free to be published
     * @type {Date}
     * @memberof PostEvent
     */
    datePublished?: Date;
    /**
     * 
     * @type {PostEventProvider}
     * @memberof PostEvent
     */
    provider?: PostEventProvider;
    /**
     * 
     * @type {PostEventLocationExtraInfo}
     * @memberof PostEvent
     */
    locationExtraInfo?: PostEventLocationExtraInfo;
    /**
     * Time the event will start
     * @type {Date}
     * @memberof PostEvent
     */
    startTime: Date;
    /**
     * Time the event will end
     * @type {Date}
     * @memberof PostEvent
     */
    endTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PostEvent
     */
    hasStartTime?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostEvent
     */
    hasEndTime?: boolean;
    /**
     * The audience groups (picked from keywords) this event is intended for
     * @type {Array<Keyword>}
     * @memberof PostEvent
     */
    audience?: Array<Keyword>;
    /**
     * Unique identifier (URI)for the system from which this event came from, preferably URL with more information about the system and its policies
     * @type {string}
     * @memberof PostEvent
     */
    dataSource?: string;
    /**
     * Name and email of the user who created this event. Only available for authorized users in the publisher organization hierarchy.
     * @type {string}
     * @memberof PostEvent
     */
    createdBy?: string;
    /**
     * Name and email of the user who last edited this event. Only available for authorized users in the publisher organization hierarchy.
     * @type {string}
     * @memberof PostEvent
     */
    lastModifiedBy?: string;
    /**
     * Id for the organization that published this event in Linkedevents.
     * @type {string}
     * @memberof PostEvent
     */
    publisher?: string;
    /**
     * Whether this event has been deleted in the original data source.
     * @type {boolean}
     * @memberof PostEvent
     */
    deleted?: boolean;
    /**
     * 
     * @type {Event}
     * @memberof PostEvent
     */
    replacedBy?: Event;
    /**
     * 
     * @type {PostEventExtensionCourse}
     * @memberof PostEvent
     */
    extensionCourse?: PostEventExtensionCourse;
}

export function PostEventFromJSON(json: any): PostEvent {
    return PostEventFromJSONTyped(json, false);
}

export function PostEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': IdRefFromJSON(json['location']),
        'keywords': ((json['keywords'] as Array<any>).map(IdRefFromJSON)),
        'inLanguage': !exists(json, 'in_language') ? undefined : ((json['in_language'] as Array<any>).map(LanguageFromJSON)),
        'superEvent': !exists(json, 'super_event') ? undefined : IdRefFromJSON(json['super_event']),
        'superEventType': !exists(json, 'super_event_type') ? undefined : json['super_event_type'],
        'eventStatus': !exists(json, 'event_status') ? undefined : json['event_status'],
        'publicationStatus': json['publication_status'],
        'externalLinks': !exists(json, 'external_links') ? undefined : ((json['external_links'] as Array<any>).map(EventlinkFromJSON)),
        'offers': !exists(json, 'offers') ? undefined : ((json['offers'] as Array<any>).map(OfferFromJSON)),
        'subEvents': !exists(json, 'sub_events') ? undefined : ((json['sub_events'] as Array<any>).map(IdRefFromJSON)),
        'customData': !exists(json, 'custom_data') ? undefined : json['custom_data'],
        'name': PostEventNameFromJSON(json['name']),
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(IdRefFromJSON)),
        'createdTime': !exists(json, 'created_time') ? undefined : (new Date(json['created_time'])),
        'lastModifiedTime': !exists(json, 'last_modified_time') ? undefined : (new Date(json['last_modified_time'])),
        'infoUrl': !exists(json, 'info_url') ? undefined : PostEventInfoUrlFromJSON(json['info_url']),
        'description': !exists(json, 'description') ? undefined : PostEventDescriptionFromJSON(json['description']),
        'shortDescription': !exists(json, 'short_description') ? undefined : PostEventShortDescriptionFromJSON(json['short_description']),
        'context': !exists(json, '@context') ? undefined : json['@context'],
        'type': !exists(json, '@type') ? undefined : json['@type'],
        'datePublished': !exists(json, 'date_published') ? undefined : (new Date(json['date_published'])),
        'provider': !exists(json, 'provider') ? undefined : PostEventProviderFromJSON(json['provider']),
        'locationExtraInfo': !exists(json, 'location_extra_info') ? undefined : PostEventLocationExtraInfoFromJSON(json['location_extra_info']),
        'startTime': (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
        'hasStartTime': !exists(json, 'has_start_time') ? undefined : json['has_start_time'],
        'hasEndTime': !exists(json, 'has_end_time') ? undefined : json['has_end_time'],
        'audience': !exists(json, 'audience') ? undefined : ((json['audience'] as Array<any>).map(KeywordFromJSON)),
        'dataSource': !exists(json, 'data_source') ? undefined : json['data_source'],
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'lastModifiedBy': !exists(json, 'last_modified_by') ? undefined : json['last_modified_by'],
        'publisher': !exists(json, 'publisher') ? undefined : json['publisher'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'replacedBy': !exists(json, 'replaced_by') ? undefined : EventFromJSON(json['replaced_by']),
        'extensionCourse': !exists(json, 'extension_course') ? undefined : PostEventExtensionCourseFromJSON(json['extension_course']),
    };
}

export function PostEventToJSON(value?: PostEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'location': IdRefToJSON(value.location),
        'keywords': ((value.keywords as Array<any>).map(IdRefToJSON)),
        'in_language': value.inLanguage === undefined ? undefined : ((value.inLanguage as Array<any>).map(LanguageToJSON)),
        'super_event': IdRefToJSON(value.superEvent),
        'super_event_type': value.superEventType,
        'event_status': value.eventStatus,
        'publication_status': value.publicationStatus,
        'external_links': value.externalLinks === undefined ? undefined : ((value.externalLinks as Array<any>).map(EventlinkToJSON)),
        'offers': value.offers === undefined ? undefined : ((value.offers as Array<any>).map(OfferToJSON)),
        'sub_events': value.subEvents === undefined ? undefined : ((value.subEvents as Array<any>).map(IdRefToJSON)),
        'custom_data': value.customData,
        'name': PostEventNameToJSON(value.name),
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(IdRefToJSON)),
        'created_time': value.createdTime === undefined ? undefined : (value.createdTime.toISOString()),
        'last_modified_time': value.lastModifiedTime === undefined ? undefined : (value.lastModifiedTime.toISOString()),
        'info_url': PostEventInfoUrlToJSON(value.infoUrl),
        'description': PostEventDescriptionToJSON(value.description),
        'short_description': PostEventShortDescriptionToJSON(value.shortDescription),
        '@context': value.context,
        '@type': value.type,
        'date_published': value.datePublished === undefined ? undefined : (value.datePublished.toISOString()),
        'provider': PostEventProviderToJSON(value.provider),
        'location_extra_info': PostEventLocationExtraInfoToJSON(value.locationExtraInfo),
        'start_time': (value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'has_start_time': value.hasStartTime,
        'has_end_time': value.hasEndTime,
        'audience': value.audience === undefined ? undefined : ((value.audience as Array<any>).map(KeywordToJSON)),
        'data_source': value.dataSource,
        'created_by': value.createdBy,
        'last_modified_by': value.lastModifiedBy,
        'publisher': value.publisher,
        'deleted': value.deleted,
        'replaced_by': EventToJSON(value.replacedBy),
        'extension_course': PostEventExtensionCourseToJSON(value.extensionCourse),
    };
}


