import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";

export default createStyles({

  button: {
    padding: "10px 15px",
    margin: "5px 10px",
    textTransform: "none",
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }

});