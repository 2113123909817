/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Link (URL) to a page with more information about event
 * @export
 * @interface PostEventInfoUrl
 */
export interface PostEventInfoUrl {
    /**
     * Link to Finnish page
     * @type {string}
     * @memberof PostEventInfoUrl
     */
    fi?: string;
    /**
     * Link to Swedish page
     * @type {string}
     * @memberof PostEventInfoUrl
     */
    sv?: string;
    /**
     * Link to English page
     * @type {string}
     * @memberof PostEventInfoUrl
     */
    en?: string;
}

export function PostEventInfoUrlFromJSON(json: any): PostEventInfoUrl {
    return PostEventInfoUrlFromJSONTyped(json, false);
}

export function PostEventInfoUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostEventInfoUrl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fi': !exists(json, 'fi') ? undefined : json['fi'],
        'sv': !exists(json, 'sv') ? undefined : json['sv'],
        'en': !exists(json, 'en') ? undefined : json['en'],
    };
}

export function PostEventInfoUrlToJSON(value?: PostEventInfoUrl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fi': value.fi,
        'sv': value.sv,
        'en': value.en,
    };
}


