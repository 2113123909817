import * as React from "react";
import { Button, WithStyles, withStyles } from "@material-ui/core";
import { Keyword } from "../../generated/client/models/Keyword";
import styles from "../../styles/generic/search-list-item-styles";
import strings from "../../localization/strings";
import { KeywordName } from "../../generated/client";

/**
 * Component Props
 */
interface Props extends WithStyles<typeof styles> {
  isTypeWhom?: boolean;
  selected: boolean;
  keyword: Keyword;
  onChange: (id: string, selectOn: boolean) => void;
}

/**
 * Creates list item for SearchList
 *
 * @param props
 */
const SearchListItem = (props: Props) => {
  const { onChange, keyword, classes, isTypeWhom } = props;

  /**
   * Handles Click event for button component
   */
  const onClick = () => {
    onChange(props.keyword.id ? props.keyword.id : "", !props.selected);
  };

  const name = keyword.name[strings.getLanguage() as keyof KeywordName];

  return (
    <Button className={ `${ classes.button } ${ isTypeWhom ? classes.whom : "" }` } onClick={ onClick }>
      { name }
    </Button>
  );
};

export default withStyles(styles, { withTheme: true })(SearchListItem);
