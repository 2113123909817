import * as React from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../store";

import strings from "../../localization/strings";

import styles from "../../styles/generic/cookie-consent";
import { setLocale } from "../../actions/locale";
import { CustomStyles } from "../../types";
import ReactCookieConsent from "react-cookie-consent";
import { withStyles, WithStyles } from "@material-ui/core";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  locale: string;
  customStyles?: CustomStyles;
}

/**
 * Cookie consent component
 *
 * @param props component props
 */
const CookieConsent: React.FC<Props> = ({
  classes
}) => {

  return (
    <ReactCookieConsent
      disableButtonStyles={ true }
      buttonText={ strings.cookieConsent.approve }
      buttonClasses={ classes.button }
      >
      { strings.cookieConsent.mainText }
    </ReactCookieConsent>
  );
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
function mapStateToProps(state: ReduxState) {
  return {
    keycloak: state.auth.keycloak,
    locale: state.locale.locale,
    accessToken: state.auth.accessToken
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
function mapDispatchToProps(dispatch: Dispatch<ReduxActions>) {
  return {
    setLocale: (locale: string) => dispatch(setLocale(locale))
  };
}

const Styled = withStyles(styles)(CookieConsent);
const Connected = connect(mapStateToProps, mapDispatchToProps)(Styled);

export default Connected;
