import { Typography, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import { Event } from "../../generated/client";
import styles from "../../styles/event/event-banner";
import { CustomStyles } from "../../types";
import EventUtils from "../../utils/event-utils";
import { Helmet, HelmetProvider } from "react-helmet-async";

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  event: Event;
  customStyles?: CustomStyles;
}

/**
 * Event banner component
 *
 * @param props component properties
 */
class EventBanner extends React.Component<Props> {

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles, event } = this.props;

    const name = EventUtils.getEventName(event);
    const imageSrc = EventUtils.getEventImageSrc(event);

    return (
      <HelmetProvider>
        <div
          className={ classes.container }
          style={{
            ...customStyles?.eventImage,
            backgroundImage: `url(${ imageSrc })`
          }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <meta property="title" content={ name } />
            <meta property="og:title" content={ name } />
            <meta property="og:image" content={ imageSrc } />
          </Helmet>
          <Typography
            variant={ "h1" }
            className={ classes.eventTitle }
            style={ customStyles?.eventTitle }
          >
            { name }
          </Typography>
        </div>
      </HelmetProvider>
    );
  }

}

export default withStyles(styles)(EventBanner);