import * as React from 'react';
import { withStyles, WithStyles } from "@material-ui/core";
import { NullableToken, CustomStyles } from "../../types";
import { ReduxActions, ReduxState } from "../../store";
import styles from "../../styles/generic/google-map-marker";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Event } from "../../generated/client";

/**
 * Component properties
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  lat: number;
  lng: number;
  events?: Event[];
}

/**
 * Component state
 */
interface State { }

/**
 * Single event map marker component
 */
class SingleEventGoogleMapMarker extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { }
  }

  /**
   * Component render
   */
  public render = () => {

    const { classes } = this.props;

    return (
      <div className={ classes.pinContainer }>
        <div className={ classes.pin }>
        
        </div>
      </div>
    );
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(SingleEventGoogleMapMarker);
const Connected = connect(mapStateToProps, mapDispatchToProps)(Styled);

export default Connected;
