/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KeywordSetName
 */
export interface KeywordSetName {
    /**
     * Keyword set name in Finnish
     * @type {string}
     * @memberof KeywordSetName
     */
    fi?: string;
    /**
     * Keyword set name in Swedish
     * @type {string}
     * @memberof KeywordSetName
     */
    sv?: string;
    /**
     * Keyword set name in English
     * @type {string}
     * @memberof KeywordSetName
     */
    en?: string;
}

export function KeywordSetNameFromJSON(json: any): KeywordSetName {
    return KeywordSetNameFromJSONTyped(json, false);
}

export function KeywordSetNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeywordSetName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fi': !exists(json, 'fi') ? undefined : json['fi'],
        'sv': !exists(json, 'sv') ? undefined : json['sv'],
        'en': !exists(json, 'en') ? undefined : json['en'],
    };
}

export function KeywordSetNameToJSON(value?: KeywordSetName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fi': value.fi,
        'sv': value.sv,
        'en': value.en,
    };
}


