import * as React from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxState, ReduxActions } from "../../store";
import { setKeycloak, login } from "../../actions/auth";

import { NullableToken } from "../../types";
import Keycloak, { KeycloakInstance } from "keycloak-js";

/**
 * Component props
 */
interface Props {
  accessToken?: NullableToken;
  onLogin: typeof login;
  setKeycloak: typeof setKeycloak;
  children?: React.ReactNode;
}

/**
 * Component providing access token and keeping it fresh
 */
class AccessTokenProvider extends React.Component<Props> {

  /**
   * Keycloak instance
   */
  private keycloak: KeycloakInstance;
  /**
   * Refresh token timer
   */
  private timer?: NodeJS.Timer;

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);

    this.keycloak = Keycloak({
      url: process.env.REACT_APP_KEYCLOAK_URL,
      realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || ""
    });
  }

  /**
   * Component did mount life cycle event
   */
  public componentDidMount = async () => {
    const { setKeycloak, onLogin } = this.props;

    const auth = await this.keycloakInit();
    setKeycloak(this.keycloak);
    if (auth) {
      const { token, tokenParsed } = this.keycloak;

      if (this.keycloak && tokenParsed?.sub && token) {
        await this.loadUserProfile(this.keycloak);
        const signedToken = this.buildToken(this.keycloak);
        onLogin(signedToken ?? null);
      }

      this.refreshAccessToken();

      this.timer = setInterval(() => this.refreshAccessToken(), 1000 * 60);
    } else {
      onLogin(null);
    }
  }

  /**
   * Component will unmount life cycle event
   */
  public componentWillUnmount = () => {
    this.timer && clearInterval(this.timer);
  }

  /**
   * Component render method
   */
  public render = () => {
    const { accessToken, children } = this.props;

    return accessToken !== undefined ? children : null;
  }

  /**
   * Refreshes access token
   */
  private refreshAccessToken = async () => {
    const { onLogin } = this.props;

    try {
      const refreshed = await this.updateToken(this.keycloak);

      if (refreshed) {
        const nullableToken = this.buildToken(this.keycloak);
        onLogin(nullableToken);
      }
    } catch (error) {
      this.setState({ error });
    }
  }

  /**
   * Initializes Keycloak client
   */
  private keycloakInit = async () => {
    try {
      return await Promise.resolve(this.keycloak.init({ onLoad: "check-sso", checkLoginIframe: false }));
    } catch (error) {
      this.setState({ error: error });
    }
  }

  /**
   * Updates token
   *
   * @param keycloak Keycloak instance
   */
  private updateToken = (keycloak: KeycloakInstance) => {
    return Promise.resolve(keycloak.updateToken(70));
  }

  /**
   * Loads user profile
   *
   * @param keycloak Keycloak instance
   */
  private loadUserProfile = (keycloak: KeycloakInstance) => {
    return Promise.resolve(keycloak.loadUserProfile());
  }

  /**
   * Builds access token using Keycloak instance
   *
   * @param keycloak Keycloak instance
   * @returns access token or undefined if building fails
   */
  private buildToken = (keycloak: KeycloakInstance): NullableToken => {
    const { token, tokenParsed, refreshToken, refreshTokenParsed, profile } = keycloak;

    if (!tokenParsed || !tokenParsed.sub || !token) {
      return null;
    }

    return {
      created: new Date(),
      access_token: token,
      expires_in: tokenParsed.exp,
      refresh_token: refreshToken,
      refresh_expires_in: refreshTokenParsed?.exp,
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      userId: tokenParsed.sub,
      email: profile?.email,
      realmRoles: tokenParsed.realm_access?.roles || [],
      clientRoles: tokenParsed.resource_access?.["realm-management"]?.roles || []
    };
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  onLogin: (accessToken: NullableToken) => dispatch(login(accessToken)),
  setKeycloak: (keycloak: KeycloakInstance) => dispatch(setKeycloak(keycloak))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessTokenProvider);