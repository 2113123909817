/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Links to entities that the event publisher considers related to this event. Eg. links to catering service available during theatrical production. The links will most likely point to unstructured content, ie. web pages suitable for human viewing.
 * @export
 * @interface Eventlink
 */
export interface Eventlink {
    /**
     * Name describing contents of the link
     * @type {string}
     * @memberof Eventlink
     */
    name?: string;
    /**
     * link to an external related entity
     * @type {string}
     * @memberof Eventlink
     */
    link?: string;
    /**
     * language of the content behind the link
     * @type {string}
     * @memberof Eventlink
     */
    language?: string;
}

export function EventlinkFromJSON(json: any): Eventlink {
    return EventlinkFromJSONTyped(json, false);
}

export function EventlinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Eventlink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function EventlinkToJSON(value?: Eventlink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'link': value.link,
        'language': value.language,
    };
}


