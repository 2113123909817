import { createStyles } from "@material-ui/core";
import theme from "../../../dynamic-content/theme";

export const styles = createStyles({

  loaderContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },

  container: {
    width: "100%"
  },
  
  title: {
    marginTop: theme.spacing(1)
  },

  tableHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  addButton: {
    marginRight: "10px",
    marginTop: "10px"
  },

  eventFormWrapper: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper
  }
});
