/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Linked Courses extra fields
 * @export
 * @interface PostEventExtensionCourse
 */
export interface PostEventExtensionCourse {
    /**
     * Time enrolment for the course will start
     * @type {Date}
     * @memberof PostEventExtensionCourse
     */
    enrolmentStartTime?: Date;
    /**
     * Time enrolment for the course will end
     * @type {Date}
     * @memberof PostEventExtensionCourse
     */
    enrolmentEndTime?: Date;
    /**
     * Maximum number of people allowed to enrol for the course
     * @type {number}
     * @memberof PostEventExtensionCourse
     */
    maximumAttendeeCapacity?: number;
    /**
     * Minimum number of people required to enrol for the course
     * @type {number}
     * @memberof PostEventExtensionCourse
     */
    minimumAttendeeCapacity?: number;
    /**
     * Number of people still allowed to enrol for the course before it is full
     * @type {number}
     * @memberof PostEventExtensionCourse
     */
    remainingAttendeeCapacity?: number;
}

export function PostEventExtensionCourseFromJSON(json: any): PostEventExtensionCourse {
    return PostEventExtensionCourseFromJSONTyped(json, false);
}

export function PostEventExtensionCourseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostEventExtensionCourse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enrolmentStartTime': !exists(json, 'enrolment_start_time') ? undefined : (new Date(json['enrolment_start_time'])),
        'enrolmentEndTime': !exists(json, 'enrolment_end_time') ? undefined : (new Date(json['enrolment_end_time'])),
        'maximumAttendeeCapacity': !exists(json, 'maximum_attendee_capacity') ? undefined : json['maximum_attendee_capacity'],
        'minimumAttendeeCapacity': !exists(json, 'minimum_attendee_capacity') ? undefined : json['minimum_attendee_capacity'],
        'remainingAttendeeCapacity': !exists(json, 'remaining_attendee_capacity') ? undefined : json['remaining_attendee_capacity'],
    };
}

export function PostEventExtensionCourseToJSON(value?: PostEventExtensionCourse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enrolment_start_time': value.enrolmentStartTime === undefined ? undefined : (value.enrolmentStartTime.toISOString()),
        'enrolment_end_time': value.enrolmentEndTime === undefined ? undefined : (value.enrolmentEndTime.toISOString()),
        'maximum_attendee_capacity': value.maximumAttendeeCapacity,
        'minimum_attendee_capacity': value.minimumAttendeeCapacity,
        'remaining_attendee_capacity': value.remainingAttendeeCapacity,
    };
}


