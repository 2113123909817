import { createStyles } from "@material-ui/core";

export default createStyles({

  container: {
    height: "100vh",
    width: "100%",
    background: "#eeeeee",
    maxHeight: 600,
  },

  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "100%"
  }

});
