import * as React from 'react';

import Dropzone from "react-dropzone";
import strings from '../../localization/strings';
import styles from "../../styles/generic/form-item";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button, withStyles, WithStyles } from '@material-ui/core';
import { Attachment, CustomStyles, PostData, PresignedPostData } from '../../types';

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  attachments: Attachment[];
  customStyles?: CustomStyles;
  onRemoveAttachment: (index: number) => void;
  onChange: (attachements: Attachment[]) => void;
}

/**
 * Interface describing component state
 */
interface State { }

/**
 * Form item component
 */
class EventOtherAttachments extends React.Component<Props, State> {

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles } = this.props;

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        { this.renderAttachments() }
        <Dropzone>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} type="file" onChange={ this.onChange } />
                <Button>
                  { strings.eventForm.chooseAttachments }
                </Button>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }

  /**
   * Method for rendering attachments
   */
  private renderAttachments = () => {
    const { attachments } = this.props;

    return attachments.map((attachment, index) => (
      <Button key={ index } onClick={ this.removeAttachment(index) } endIcon={ <HighlightOffIcon /> }>
        { attachment.name }
      </Button>
    ));
  }

  /**
   * Method for removing attachment
   *
   * @param index index
   */
  private removeAttachment = (index: number) => () => {
    const { onRemoveAttachment } = this.props;
    onRemoveAttachment(index);
  }

  /**
   * Method for creating attachments
   *
   * @param files file list
   * @returns array of attachments
   */
  private createAttachments = async (files: FileList): Promise<Attachment[]> => {
    const attachments: Promise<Attachment>[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const attachment = this.createAttachment(file);
      attachments.push(attachment);
    }
    return await Promise.all(attachments);
  }

  /**
   * Method for creating attachment
   *
   * @oaram file file
   * @returns attachment
   */
  private createAttachment = async (file: File): Promise<Attachment> => {
    const presignedPostData = await this.getPresignedPostData(file);
    await this.uploadAttachment(file, presignedPostData.data);
    const attachmentUrl = `${presignedPostData.basePath}/${presignedPostData.data.fields.key}`;
    const attachment = {
      name: file.name,
      url: attachmentUrl
    };
    return attachment;
  }

  /**
   * Retrieve pre-signed POST data from a dedicated API endpoint.
   * @param file file
   * @returns presigned post data
   */
  private getPresignedPostData = (file: File): Promise<PresignedPostData> => {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      const bucketUrl = process.env.REACT_APP_USER_CONTENT_UPLOAD_URL || "";
      
      xhr.open("POST", bucketUrl, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(
        JSON.stringify({
          userId: "event-images",
          name: file.name,
          type: file.type
        })
      );
      xhr.onload = function() {
        resolve(JSON.parse(this.responseText));
      };
    });
  }

  /**
   * Method for uploading attachment
   *
   * @param file 
   * @param postData 
   */
  private uploadAttachment = async (file: File, postData: PostData): Promise<void> => {
    const formData = new FormData();
    formData.append("file", file);
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(postData.fields).forEach(key => {
        formData.append(key, postData.fields[key]);
      });

      formData.append("file", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", postData.url, true);
      xhr.send(formData);
      xhr.onload = function() {
        this.status === 204 ? resolve() : reject(this.responseText);
      };
    });
  }

  /**
   * Method for adding attachments
   *
   * @param event event
   */
  private onChange = async (event: React.ChangeEvent<any>) => {
    const { onChange } = this.props;
    const files: FileList = event.target.files;
    const attachments = await this.createAttachments(files);
    onChange(attachments);
  }

}

const Styled = withStyles(styles)(EventOtherAttachments);

export default Styled;