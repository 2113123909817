import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({

  palette: {
    primary: {
      main: "#3d8ebf",
      dark: "#314665"
    },
    secondary: {
      main: "#52A8D9"
    },
    background: {
      default: "#f7f7f7",
      paper: "#ffffff"
    },
    success: {
      main: "#61C88D"
    },
    error: {
      main: "#E25353"
    },
    text: {
      primary: "#252729",
      disabled: "#C2C9CF"
    },
    grey: {
      "100": "#252729",
      "200": "#51575C",
      "400": "#939BA3",
      "600": "#C2C9CF",
      "700": "#DCE2E9",
      "800": "#F7F9FC",
      "900": "#FEFEFF"
    },
    info: {
      main: "#7BD4F0"
    },
    warning: {
      main: "#F6D46C"
    }
  },

  typography: {
    fontFamily: "'Raleway', sans-serif",
    h1: {
      fontWeight: "bold",
      fontSize: 24,
    },
    h2: {
      fontWeight: 300,
      fontSize: 24,
    },
    h3: {
      fontWeight: "bold",
      fontSize: 20,
    },
    h4: {
      fontWeight: 300,
      fontSize: 20,
    },
    h5: {
      fontWeight: "bold",
      fontSize: 18,
      color: "#314665"
    },
    subtitle1: {
    },
    body1: {
    },
    body2: {

    }
  },

  overrides: {
    MuiAppBar: {
      root: {

      }
    },
    MuiButton: {
      root: {
        textTransform: "initial"
      }
    },
    MuiListItem: {
      gutters: {

      },
    },

    MuiList: {
      padding: {

      }
    },
    MuiTab: {
      root: {
        minHeight: 64,
        textTransform: "initial",
        fontWeight: "bold"
      },
    },

    MuiTextField: {
      root: {
        width: "100%",
        fontWeight: 500,
        marginTop: 16
      },
    },

    MuiTableRow: {
      root: {
        cursor: "pointer",
        transition: "background-color 0.2s ease-out",
        "&:hover": {
          backgroundColor: "rgba(82, 168, 217, 0.1)"
        },
        "&:active": {
          backgroundColor: "rgba(82, 168, 217, 0.2)"
        }
      },
      head: {
        cursor: "default",
        "&:hover": {
          backgroundColor: "rgba(82, 168, 217, 0)"
        },
        "&:active": {
          backgroundColor: "rgba(82, 168, 217, 0)"
        }
      }
    },

    MuiTableCell: {
      root: {
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        padding: 12
      }
    },

    MuiInputBase: {
      root: {
        backgroundColor: "#fff",
        fontWeight: 500,
      }
    },

    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 12px) scale(1)"
      }
    },

    MuiOutlinedInput: {
      input: {
        padding: "10.5px 14px"
      }
    },

    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#fff"
        }
      }
    },

    MuiToolbar: {
      root: {
        "&$gutters": {
          paddingRight: 4
        }
      },
      gutters: {}
    },

    MuiAccordion: {
      root: {
        "&:before": {

        },
        "&$expanded": {
          margin: 'auto',
        },
      },
      expanded: {},
    },

    MuiAccordionSummary: {
      root: {
        height: 60,
        minHeight: 60,
        backgroundColor: "#ffffff",
        transition: "box-shadow 0.2s ease-out",
        "&$expanded": {
          height: 60,
          minHeight: 60,
          boxShadow: "0 0 10px rgba(0,0,0,0.2)"
        }
      },
      expanded: {}
    },
    MuiAccordionActions: {

    },

    MuiAccordionDetails: {
      root: {
        padding: 24,
        backgroundColor: "#f7f7f7"
      }
    },

    MuiFab: {
      root: {
        boxShadow: "1xp 1px 5px rgba(0,0,0,0.2)"
      }
    },

    MuiCssBaseline: {
      "@global": {
        "body": {
          backgroundColor: "#f7f7f7"
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: "#C2C9CF"
        },
        "::-webkit-scrollbar": {
          height: 5,
          width: 5
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#004C97",
          border: "none",
          borderRadius: 1
        }
      }
    }
  },
  props: {
    MuiAccordion: {
      square: true,
    },
    MuiAppBar: {
      color: "transparent"
    },
    MuiButton: {
      variant: "contained",
      color: "primary"
    },
    MuiDrawer: {
      variant: "temporary",
      anchor: "right"
    },
    MuiTextField: {
      variant: "outlined"
    },
    MuiSelect: {
      variant: "outlined"
    },
    MuiTabs: {
      indicatorColor: "primary",
      TabIndicatorProps: {
        style: { height: 5 }
      }
    }
  }
});