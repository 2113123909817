import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2)
  },

  title: {
    marginBottom: theme.spacing(1)
  },

  richText: {
    minHeight: 200,
    background: "#fff",
    marginBottom: theme.spacing(2)
  },

});
