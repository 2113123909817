import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 1500,
    [theme.breakpoints.up("md")]: {
      width: "80vw",
    }
  },

  loaderContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    height: 500,
  },

  loader: {
    alignSelf: "center"
  },

  bannerLoader: {
    minHeight: 500
  },

  content: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    }
  },

  link: {
    textDecoration: "none"
  },

  whiteButton: {
    color: "#fff",
    borderColor: "#fff"
  }

});