import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    padding: 20,
    backgroundColor: "rgba(249, 249, 249, 0.95)",
    whiteSpace: "break-spaces",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 1500,
    [theme.breakpoints.up("md")]: {
      width: "80vw",
    }
  },

});
