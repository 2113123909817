/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Short descriptive name for the event, recommended limit: 80 characters
 * @export
 * @interface PostEventName
 */
export interface PostEventName {
    /**
     * Name of the event in Finnish
     * @type {string}
     * @memberof PostEventName
     */
    fi?: string;
    /**
     * Name of the event in Swedish
     * @type {string}
     * @memberof PostEventName
     */
    sv?: string;
    /**
     * Name of the event in English
     * @type {string}
     * @memberof PostEventName
     */
    en?: string;
}

export function PostEventNameFromJSON(json: any): PostEventName {
    return PostEventNameFromJSONTyped(json, false);
}

export function PostEventNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostEventName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fi': !exists(json, 'fi') ? undefined : json['fi'],
        'sv': !exists(json, 'sv') ? undefined : json['sv'],
        'en': !exists(json, 'en') ? undefined : json['en'],
    };
}

export function PostEventNameToJSON(value?: PostEventName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fi': value.fi,
        'sv': value.sv,
        'en': value.en,
    };
}


