import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    },
    "& .image_picker": {
      "& .responsive": {
        height: 150,
        overflow: "hidden",
        cursor: "pointer",
        display: "inline-block",
        marginRight: 6,
        position: "relative",
        "& img": {
          transition: "transform 0.2s ease-out",
        },
        "&::after": {
          zIndex: 1,
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0,0.3)",
          transition: "background-color 0.2s ease-out"
        },
        "&::before": {
          zIndex: 2,
          content: "''",
          position: "absolute",
          top: "50%",
          left: "50%",
          width: 25,
          height: 15,
          borderBottom: "5px solid #fff",
          borderLeft: "5px solid #fff",
          transform: "translate3d(-50%,-50%,0) rotate(-45deg) scale(0)",
          transition: "transform 0.2s ease-out"
        },
        "&.selected": {
          "&::after": {
            backgroundColor: "rgb(236, 167, 45, 0.3)"
          },
          "&::before": {
            transform: "translate3d(-50%,-50%,0) rotate(-45deg) scale(1)",
          },
          "& img": {
            transform: "scale(1.05)"
          }
        },
        "&:hover": {
          "&::after": {
            backgroundColor: "rgba(0,0,0,0)",
            transform: "scale(2)"
          },
          "& img": {
            transform: "scale(1.05)"
          }
        }
      }
    }
  }

});
