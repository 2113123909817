import * as React from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../store";

import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { KeycloakInstance } from "keycloak-js";
import MenuIcon from "@material-ui/icons/Menu";
import strings from "../../localization/strings";
import CloseIcon from "@material-ui/icons/Close";
import styles from "../../styles/generic/header";
import { setLocale } from "../../actions/locale";
import { CustomStyles, NullableToken } from "../../types";
import { AppBar, Box, Button, Fade, Hidden, IconButton, Toolbar, withStyles, WithStyles } from "@material-ui/core";

/**
 * Optional props
 */
export interface OptionalProps { }

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  locale: string;
  keycloak?: KeycloakInstance;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  setLocale: typeof setLocale;
}

/**
 * Header component
 *
 * @param props component props
 */
const Header: React.FC<Props & OptionalProps> = ({
  locale,
  classes,
  keycloak,
  setLocale,
  accessToken,
  customStyles
}) => {
  const [ logo, setLogo ] = React.useState<string | undefined>();
  const [ showMenu, setShowMenu ] = React.useState(false);

  /**
   * Click handler for open menu button
   */
  const handleMenuOpenClick = () => {
    setShowMenu(true);
  };

  /**
   * Click handler for close menu button
   */
  const handleMenuCloseClick = () => {
    setShowMenu(false);
  };

  /**
   * Method for changing language to finnish or english
   */
  const changeLanguage = () => {
    setLocale(locale === "en" ? "fi" : "en");
  }

  /**
   * Imports logo after component mounts
   */
  React.useEffect(() => {
    const logoPath = "resources/svg/logo.svg";
    import(`../../dynamic-content/${ logoPath }`)
      .then(logo => setLogo(logo.default))
      .catch(() => {});
  }, []);

  /**
   * Renders user auth action
   */
  const renderAuthAction = () => {
    const label = accessToken ?
      strings.auth.logout :
      strings.auth.login;

    return (
      <>
        { !accessToken &&
          <Button
            variant="text"
            className={ classes.button }
            style={ customStyles?.button }
            onClick={ () => {
              if (keycloak) {
                keycloak.register();
              }
            }}
          >
            { strings.auth.register }
          </Button>
        }
        <Button
          variant="text"
          className={ classes.button }
          style={ customStyles?.button }
          onClick={ () => {
            if (accessToken) {
              if (keycloak) {
                keycloak.logout();
              }
            } else {
              if (keycloak) {
                keycloak.login({ idpHint: "oidc" });
              }
            }
          }}
        >
          { label }
        </Button>
      </>
    );
  }

    /**
   * Renders profile page button
   */
  const renderProfileButton = () => {

      if (!accessToken) {
        return null;
      }

      return (
        <Link
          to="/profile"
          className={ classes.profileLink }
        >
          <Button
            variant="text"
            className={ classes.button }
            >
            { strings.profile.pageTitle }
          </Button>
        </Link>
      );
  }

  /**
   * Function for rendering add event button
   */
  const renderAddEventButton = () => {

    if (!accessToken) {
      return (
        <Button
          className={ classes.button }
          style={ customStyles?.button }
          endIcon={ <AddIcon /> }
          onClick={ () => {
            if (keycloak) {
              keycloak.login({ idpHint: "oidc" });
            }
          }}
        >
          { strings.event.addEvent }
        </Button>
      );
    }

    return (
      <Link
        to="/add-event"
        className={ classes.link }
      >
        <Button
          className={ classes.button }
          style={ customStyles?.button }
          endIcon={ <AddIcon /> }
          >
          { strings.event.addEvent }
        </Button>
      </Link>
    );
  }

  /**
   * Renders language button
   */
  const renderLanguageButton = () => (
    <Button
      variant="text"
      onClick={ changeLanguage }
      className={ classes.language }
      style={ customStyles?.language }
    >
      { locale === "en" ? "Suomeksi" : "In English" }
    </Button>
  );

/**
 * Function to format bold sections to the info banner
 */
function formatBold(text: string): React.ReactNode {
  const parts = text.split('*');
  return parts.map((part, index) => {
    return index % 2 === 0 ? part : <b key={index}>{part}</b>;
  });
}

  /**
   * Component render
   */
  return (
    <div
      className={ classes.container }
      style={ customStyles?.container }
    >
            <div
        style={{
          backgroundColor: 'yellow',
          textAlign: 'center',
          padding: '10px',
          position: 'static',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          fontSize: '18px',
          display: 'none' // change this to make site construction info banner visible again
        }}
      >
        {formatBold(strings.infoBanner.siteConstructionInfo)}
      </div>
      <AppBar
        elevation={ 0 }
        position="static"
        className={ classes.appBar }
        style={ customStyles?.appBar }
      >
        <Toolbar
          className={ classes.toolBar }
          style={ customStyles?.toolBar }
        >
          <Link to="/">
            <img
              src={ logo }
              alt={ strings.client.siteLogoAltText }
              className={ classes.logo }
              />
          </Link>
          <Hidden mdUp>
            <IconButton onClick={ handleMenuOpenClick }>
              <MenuIcon fontSize="large" />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <Box>
              { renderAuthAction() }
              { renderProfileButton() }
              { renderAddEventButton() }
              { renderLanguageButton() }
            </Box>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Fade in={ showMenu }>
        <div className={ classes.mobileMenu }>
          <Toolbar
            className={ classes.toolBar }
            style={ customStyles?.toolBar }
          >
            <Link to="/">
              <img
                src={ logo }
                alt={ strings.client.siteLogoAltText }
                className={ classes.logo }
              />
            </Link>
            <IconButton onClick={ handleMenuCloseClick }>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Toolbar>
          <div className={ classes.menuContent }>
            { renderAuthAction() }
            { renderProfileButton() }
            { renderAddEventButton() }
            <Button
              variant="text"
              onClick={ changeLanguage }
              className={ classes.language }
              style={ customStyles?.language }
            >
              { locale === "en" ? "Suomeksi" : "In English" }
            </Button>
          </div>
        </div>
      </Fade>
    </div>
  );
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
function mapStateToProps(state: ReduxState) {
  return {
    keycloak: state.auth.keycloak,
    locale: state.locale.locale,
    accessToken: state.auth.accessToken
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
function mapDispatchToProps(dispatch: Dispatch<ReduxActions>) {
  return {
    setLocale: (locale: string) => dispatch(setLocale(locale))
  };
}

const Styled = withStyles(styles)(Header);
const Connected = connect(mapStateToProps, mapDispatchToProps)(Styled);

export default Connected;
