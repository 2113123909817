import * as React from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../store";

import Api from "../../api/api";
import { withStyles, WithStyles, Typography } from "@material-ui/core";
import AppLayout from "../layouts/app-layout";
import styles from "../../styles/screens/home-screen";
import { History, Location } from "history";
import { NullableToken, CustomStyles, Filters, OpenPage } from "../../types";
import strings from "../../localization/strings";
import ReactHtmlParser from "react-html-parser";
import EventSearchForm from "../event/event-search-form";
import EventLister from "../event/event-lister";
import { Event, EventListRequest } from "../../generated/client";
import moment from "moment";
import AllEventsMap from "../generic/all-events-map";

/**
 * Component properties
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  history: History<History.LocationState>;
  location: Location<unknown>;
}

/**
 * Component state
 */
interface State {
  loading: boolean;
  pageOpen: OpenPage;
  longTermEvents: Event[];
  shortTermEvents: Event[];
  longTermEventsPage: number;
  shortTermEventsPage: number;
  loadMoreLongDisabled: boolean;
  loadMoreShortDisabled: boolean;
  loadingLongTermEvents: boolean;
  loadingShortTermEvents: boolean;
}

/**
 * Home screen component
 */
class HomeScreen extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      longTermEvents: [],
      shortTermEvents: [],
      pageOpen: "eventlist",
      longTermEventsPage: 1,
      shortTermEventsPage: 1,
      loadMoreLongDisabled: false,
      loadMoreShortDisabled: false,
      loadingLongTermEvents: true,
      loadingShortTermEvents: true
    }
  }

  /**
   * Component did mount life cycle method
   */
  public componentDidMount = async () => {
    const eventData = await this.fetchEventData();

    if (!eventData) {
      return;
    }

    this.setState({
      loading: false,
      loadingLongTermEvents: false,
      loadingShortTermEvents: false,
      longTermEvents: eventData.longEvents,
      shortTermEvents: eventData?.shortEvents,
      loadMoreLongDisabled: eventData.longMetaData?.next === undefined,
      loadMoreShortDisabled: eventData.shortMetaData?.next === undefined
    })
  }

  /**
   * Component did update life cycle method
   *
   * @param prevProps previous component properties
   * @param prevState previous component state
   */
  public componentDidUpdate = async (prevProps: Props, prevState: State) => {
    if (prevProps.location.search !== this.props.location.search) {
      this.setState({ loading: true });

      const eventData = await this.fetchEventData();

      if(!eventData) {
        return;
      }

      this.setState({
        shortTermEvents: eventData.shortEvents,
        longTermEvents: eventData.longEvents,
        longTermEventsPage: 1,
        shortTermEventsPage: 1,
        loadMoreLongDisabled: eventData.longMetaData?.next === undefined,
        loadMoreShortDisabled: eventData.shortMetaData?.next === undefined,
        loading: false
      });
    }
  };


  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles, history } = this.props;
    const {
      pageOpen,
      longTermEvents,
      shortTermEvents,
      loadMoreLongDisabled,
      loadMoreShortDisabled,
      loadingLongTermEvents,
      loadingShortTermEvents
    } = this.state;

    const filters = this.getFiltersFromSearchParams();

    return (
      <AppLayout>
        <div
          className={ classes.container }
          style={ customStyles?.container }
        >
          <Typography
            variant={ "h1" }
            className={ classes.heading }
            style={ customStyles?.heading }
          >
            { this.renderHeading() }
          </Typography>
          <div className={ classes.content }>
            <EventSearchForm
              filters={ filters }
              onFiltersChange={ this.onFiltersChange }
              onListButtonClick={ this.onListButtonClick }
              onMapButtonClick={ this.onMapButtonClick }
              pageOpen={ pageOpen }
            />
            { pageOpen === "eventlist" &&
            <div>
              <EventLister
                history={ history }
                events={ longTermEvents }
                loading={ loadingLongTermEvents }
                disabled={ loadMoreLongDisabled }
                title={ strings.event.longTermEvents }
                loadMoreEvents={ this.onLoadMoreLongEventsClick }
                loadMoreButtonText={ strings.event.loadMoreLongTermEvents }
                showEditButtons={ false }
              />
              <EventLister
                history={ history }
                events={ shortTermEvents }
                title={ strings.event.allEvents }
                disabled={ loadMoreShortDisabled }
                loading={ loadingShortTermEvents }
                loadMoreEvents={ this.onLoadMoreShortEventsClick }
                loadMoreButtonText={ strings.event.loadMoreEvents }
                showEditButtons={ false }
              />
            </div>
            }
            { pageOpen === "googlemaps" &&
              <AllEventsMap
                history={ history }
                filters={ filters }
              />
            }
          </div>
        </div>
      </AppLayout>
    )
  }

  /**
   * Initializes filter object
   *
   * @returns FiltersParam object
   */
  private getFiltersFromSearchParams = (): Filters => {
    const { searchParams } = new URL(window.location.href);

    return {
      selectedAudienceIds: searchParams.get("selectedAudienceIds")?.split(",") || [],
      selectedCategoryIds: searchParams.get("selectedCategoryIds")?.split(",") || [],
      selectedPlaceIds: searchParams.get("selectedPlaceIds")?.split(",") || [],
      dateStart: searchParams.has("dateStart") ? moment(searchParams.get("dateStart"), "x").toDate() : moment().toDate(),
      dateEnd: searchParams.has("dateEnd") ? moment(searchParams.get("dateEnd"), "x").toDate() : undefined,
      text: searchParams.get("text") ?? undefined
    };
  };

  /**
   * Method for fetching short term events
   *
   * @param filters filters
   */
  private fetchEventData = async () => {
    const { accessToken } = this.props;

    try {
      const eventsApi = Api.getEventApi(accessToken);
      const apiDataShort = await eventsApi.eventList(this.createRequestWithFilters("short"));
      const apiDataLong = await eventsApi.eventList(this.createRequestWithFilters("long"));

      if (!apiDataShort || !apiDataLong) {
        return;
      }

      const shortEvents = apiDataShort.data || [];
      const shortMetaData = apiDataShort.meta;
      const longEvents = apiDataLong.data || [];
      const longMetaData = apiDataLong.meta;

      return { shortEvents, shortMetaData, longEvents, longMetaData };

    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Creates and returns filterParams object from filter for short term events object in state
   *
   * @param type request type
   * @returns filterParams object
   */
  private createRequestWithFilters = (type: "short" | "long"): EventListRequest => {
    const filters = this.getFiltersFromSearchParams();

    const shortEventLimit = 60 * 60 * 24 * 14;

    return {
      keyword: [ ...filters.selectedAudienceIds, ...filters.selectedCategoryIds ].join(","),
      start: filters.dateStart,
      end: filters.dateEnd,
      addressLocalityFi: [ ...filters.selectedPlaceIds ].join(","),
      page: 1,
      pageSize: 12,
      sort: "start_time",
      text: filters.text,
      minDuration: type === "short" ? undefined : shortEventLimit,
      maxDuration: type === "short" ? shortEventLimit : undefined
    };
  };

  /**
   * Loads more short term event content by changing page count
   *
   * @returns events, metaData
   */
  private loadMoreShortTermEvents = async () => {
    const  { accessToken } = this.props;
    const { shortTermEventsPage } = this.state;

    const eventApi = Api.getEventApi(accessToken);

    const apiData = await eventApi.eventList({
      ...this.createRequestWithFilters("short"),
      page: shortTermEventsPage + 1,
      sort: "start_time"
    });

    if (!apiData) {
      return;
    }

    return {
      events: apiData.data || [],
      metaData: apiData.meta
    };
  };

  /**
   * Loads more long term event content by changing page count
   *
   * @returns event data
   */
  private loadMoreLongTermEvents = async () => {
    const  { accessToken } = this.props;
    const { longTermEventsPage } = this.state;

    const eventApi = Api.getEventApi(accessToken);

    const apiData = await eventApi.eventList({
      ...this.createRequestWithFilters("long"),
      page: longTermEventsPage + 1,
      sort: "start_time"
    });

    if (!apiData) {
      return;
    }

    return {
      events: apiData.data || [],
      metaData: apiData.meta
    };
  };


  /**
   * Handles onClick event for button
   */
  private onLoadMoreShortEventsClick = async () => {
    const { shortTermEvents, shortTermEventsPage } = this.state;

    this.setState({
      loading: true,
      loadingShortTermEvents: true
    });

    const apiData = await this.loadMoreShortTermEvents();

    if (!apiData) {
      return;
    }

    this.setState({
      loading: false,
      loadingShortTermEvents: false,
      loadMoreShortDisabled: apiData.metaData && apiData.metaData.next === undefined ? true : false,
      shortTermEvents: [ ...shortTermEvents ].concat(apiData.events),
      shortTermEventsPage: shortTermEventsPage + 1
    });
  };

  /**
   * Handles onClick event for button
   */
  private onLoadMoreLongEventsClick = async () => {
    const { longTermEvents, longTermEventsPage } = this.state;

    this.setState({
      loading: true,
      loadingLongTermEvents: true
    });

    const apiData = await this.loadMoreLongTermEvents();

    if (!apiData) {
      return;
    }

    this.setState({
      loading: false,
      loadingLongTermEvents: false,
      loadMoreLongDisabled: apiData.metaData && apiData.metaData.next === undefined ? true : false,
      longTermEventsPage: longTermEventsPage + 1,
      longTermEvents: [ ...longTermEvents ].concat(apiData.events)
    });
  };

  /**
   * Method for rendering heading
   */
  private renderHeading = () => {
    const formattedName = `<b>${ strings.client.toClientName }!</b>`;
    const formattedHeader = strings.formatString(strings.homeScreen.heading, formattedName);
    return ReactHtmlParser(formattedHeader as string);
  }

  /**
   * Shows content in List view
   */
  private onListButtonClick = () => {
    this.setState({ pageOpen: "eventlist" });
  };

  /**
   * Shows content in Map view
   */
  private onMapButtonClick = () => {
    this.setState({ pageOpen: "googlemaps" });
  };

  /**
   * Sets state new filter changes
   *
   * @param filters filters
   */
  private onFiltersChange = (filters: Filters) => {
    const { history } = this.props;
    const {
      dateStart,
      selectedAudienceIds,
      selectedCategoryIds,
      selectedPlaceIds,
      dateEnd,
      text
    } = filters;

    const searchParams = new URLSearchParams();
    searchParams.set("dateStart", dateStart.getTime().toString());
    dateEnd && searchParams.set("dateEnd", dateEnd.getTime().toString());
    selectedAudienceIds.length && searchParams.set("selectedAudienceIds", selectedAudienceIds.join(","));
    selectedCategoryIds.length && searchParams.set("selectedCategoryIds", selectedCategoryIds.join(","));
    selectedPlaceIds.length && searchParams.set("selectedPlaceIds", selectedPlaceIds.join(","));
    text && searchParams.set("text", text);

    history.push({
      pathname: "/",
      search: searchParams.toString()
    });
  };

}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(HomeScreen)
const Connected = connect(mapStateToProps, mapDispatchToProps)(Styled);

export default Connected;
