import * as React from "react";

import { History } from "history";
import { Event } from "../../generated/client/models/Event";
import { withStyles, WithStyles, Popper, Grid, Fade } from "@material-ui/core";
import styles from "../../styles/generic/popper-component";
import GoogleMarkerEventItem from "./google-marker-event-item";
import AddIcon from "@material-ui/icons/Add";

/**
 * Component props
 */
interface Props extends WithStyles<typeof styles> {
  eventsInPopper: Event[];
  lat: number;
  lng: number;
  open: boolean;
  history: History<History.LocationState>;
  markerAnchorEl: null | undefined | HTMLElement;
  onClosePopperClick(): void;
}

/**
 * Creates Popper component
 *
 * @param props
 */
 const PopperComponent: React.FC<Props> = props => {
  const { classes, open, history, markerAnchorEl, eventsInPopper, onClosePopperClick } = props;

  return (
    <Popper
      open={open}
      placement="right"
      anchorEl={markerAnchorEl}
      transition
      className={classes.popper}
      disablePortal={true}
      modifiers={{
        flip: {
          enabled: true
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: "scrollParent"
        }
      }}
    >
      {({ TransitionProps }) => (
        <Fade { ...TransitionProps }>
          <Grid container direction="row" className={classes.eventScrollContainer}>
            <Grid container item xs={11} className={classes.eventContainer}>
              { eventsInPopper.map(event => (
                <GoogleMarkerEventItem key={ event.id } event={ event } history={ history } />
              ))}
            </Grid>
            <Grid item xs="auto" className={ classes.scrollBarContainer }>
              <AddIcon onClick={ onClosePopperClick } className={ classes.scrollBar } />
            </Grid>
          </Grid>
        </Fade>
      )}
    </Popper>
  );
};

export default withStyles(styles)(PopperComponent);
