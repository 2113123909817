import { createStyles } from "@material-ui/core";

export default createStyles({

  input: {
      flex: 1,
      display: "flex",
      flexDirection: 'column'
  }

});
