import * as React from "react";

import moment from "moment";
import MomentUtils from "@date-io/moment";
import strings from "../../localization/strings";
import DeleteIcon from '@material-ui/icons/Delete';
import styles from "../../styles/generic/form-item";
import { CustomStyles, EventPeriod } from "../../types";
import SimpleReactValidator from "simple-react-validator";
import { Button, IconButton, Typography, withStyles, WithStyles } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  endValue?: Date;
  startValue?: Date;
  eventPeriods: EventPeriod[];
  customStyles?: CustomStyles;
  validator: SimpleReactValidator;
  onEndTimeChange: (date: Date) => void;
  onStartTimeChange: (date: Date) => void;
  onUpdateEventPeriods: (eventPeriods: EventPeriod[]) => void;
}

/**
 * Interface describing component state
 */
interface State { }

/**
 * Form item component
 */
class EventTimePicker extends React.Component<Props, State> {

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render
   */
  public render = () => {
    const {
      classes,
      customStyles,
      startValue,
      endValue,
    } = this.props;

    return (
      <div
        className={ classes.column }
        style={ customStyles?.column }
      >
        <MuiPickersUtilsProvider
          utils={ MomentUtils }
          libInstance={ moment }
          locale={ strings.getLanguage() }
        >
          <div className={ classes.row }>
            <div className={ classes.column }>
              <Typography>{ strings.eventForm.eventBegins }</Typography>
              <DateTimePicker
                ampm={ false }
                showTodayButton
                value={ startValue }
                onChange={ this.onStartChange }
                todayLabel={ strings.timepicker.today}
                clearLabel={ strings.timepicker.clear }
                cancelLabel={ strings.timepicker.cancel }
              />
            </div>
            <div className={ classes.column }>
              <Typography>{ strings.eventForm.eventEnds }</Typography>
              <DateTimePicker
                ampm={ false }
                showTodayButton
                value={ endValue }
                onChange={ this.onEndChange }
                todayLabel={ strings.timepicker.today}
                clearLabel={ strings.timepicker.clear }
                cancelLabel={ strings.timepicker.cancel }
              />
            </div>
          </div>
          { this.renderValidatorMessage("date_range", startValue, endValue) }
          { this.renderSubEventDateTimePickers() }
          <Button onClick={ this.addNewEventPeriod }>
            { strings.eventForm.addNewTime }
          </Button>
        </MuiPickersUtilsProvider>
      </div>
    );
  }

  /**
   * Method for rendering date time pickers for sub events
   */
  private renderSubEventDateTimePickers = () => {
    const { eventPeriods, classes } = this.props;

    if (!eventPeriods) {
      return null;
    }

    return eventPeriods.map((eventPeriod, index) => (
      <React.Fragment key={ index }>
        <div className={ classes.row }>
          <div className={ classes.column }>
            <Typography>{ strings.eventForm.eventBegins }</Typography>
            <DateTimePicker
              showTodayButton
              todayLabel={ strings.timepicker.today }
              clearLabel={ strings.timepicker.clear }
              cancelLabel={ strings.timepicker.cancel }
              ampm={ false }
              value={ eventPeriod.startTime }
              onChange={ this.onSubEventStartChange(index) }
            />
          </div>
          <div className={ classes.column }>
            <Typography>{ strings.eventForm.eventEnds }</Typography>
            <DateTimePicker
              showTodayButton
              todayLabel={ strings.timepicker.today}
              clearLabel={ strings.timepicker.clear }
              cancelLabel={ strings.timepicker.cancel }
              ampm={ false }
              onChange={ this.onSubEventEndChange(index) }
              value={ eventPeriod.endTime }
            />
          </div>
          <IconButton
            style={{ alignSelf: "center" }}
            onClick={ this.deleteEventPeriod(index) }
          >
            <DeleteIcon />
          </IconButton>
        </div>
        { this.renderValidatorMessage(`date_range_${ index }`, eventPeriod.startTime, eventPeriod.endTime) }
        { this.renderTimingArrayValidatorMessages() }
      </React.Fragment>
    ));
  }

  /**
   * Method for rendering validator message
   *
   * @param field field
   * @param startValue start value
   * @param endValue end value
   */
  private renderValidatorMessage = (field: string, startValue?: Date, endValue?: Date) => {
    const { validator } = this.props;

    return validator.message(field, endValue?.getTime(), [ { min: [startValue?.getTime(), "num"] }]);
  }

  /**
   * Method for rendering validator message for amount of timing fields
   */
  private renderTimingArrayValidatorMessages = () => {
    const { validator, eventPeriods } = this.props;

    return validator.message("date_array_size", eventPeriods, [{ max: 39 }]);
    }
  

  /**
   * Method for setting event start date
   *
   * @param date 
   */
  private onStartChange = (date: MaterialUiPickersDate) => {
    const { onStartTimeChange } = this.props;

    if (!date) {
      return;
    }

    onStartTimeChange(date.toDate());
  }

  /**
   * Method for setting event end date
   *
   * @param date 
   */
  private onEndChange = (date: MaterialUiPickersDate) => {
    const { onEndTimeChange } = this.props;

    if (!date) {
      return;
    }

    onEndTimeChange(date.toDate())
  }

  /**
   * Method for changing sub event start time
   *
   * @param eventPeriodIndex event period index
   * @param date date
   */
  private onSubEventStartChange = (eventPeriodIndex: number) => (date: MaterialUiPickersDate) => {
    const { eventPeriods, onUpdateEventPeriods } = this.props;

    if (!date) {
      return;
    }

    const updatedPeriods = eventPeriods.map((eventPeriod, index) =>
      index === eventPeriodIndex ? { ...eventPeriod, startTime: date.toDate() } : eventPeriod
    );

    onUpdateEventPeriods(updatedPeriods);
  }

  /**
   * Method for changing sub event end time
   *
   * @param eventPeriodIndex event period index
   * @param date date
   */
  private onSubEventEndChange = (eventPeriodIndex: number) => (date: MaterialUiPickersDate) => {
    const { eventPeriods, onUpdateEventPeriods } = this.props;

    if (!date) {
      return;
    }

    const updatedPeriods = eventPeriods.map((eventPeriod, index) =>
      index === eventPeriodIndex ? { ...eventPeriod, endTime: date.toDate() } : eventPeriod
    );

    onUpdateEventPeriods(updatedPeriods);
  }

  /**
   * Method for adding new event period
   */
  private addNewEventPeriod = () => {
    const { eventPeriods, onUpdateEventPeriods } = this.props;

    const startTime = moment().startOf("hour").toDate();
    const endTime = moment().add(1, "hours").startOf("hour").toDate();
    const updatedPeriods = [ ...eventPeriods, { startTime: startTime, endTime: endTime } ];

    onUpdateEventPeriods(updatedPeriods);
  }

  /**
   * Delete event period
   *
   * @param eventPeriodIndex event period index
   */
  private deleteEventPeriod = (eventPeriodIndex: number) => () => {
    const { eventPeriods, onUpdateEventPeriods } = this.props;

    const updatedPeriods = eventPeriods.filter((eventPeriod, index) => index !== eventPeriodIndex);

    onUpdateEventPeriods(updatedPeriods);
  }
}

const Styled = withStyles(styles)(EventTimePicker);
export default Styled;