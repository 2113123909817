import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },

  content: {
    display: "flex",
    flexDirection: "column",
    flex: 2
  },

  newPlaceForm: {
    marginTop: 5
  },

  column: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: `0px ${theme.spacing(1)}px 0px 0px`
  },

  row: {
    display: "flex",
    flexDirection: "row",
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px 0px`
  },

  columns: {
    "& > div": {
      marginBottom: theme.spacing(2),
      display: "flex",
      flex: 1,
      flexDirection: "column",
      "&:first-child": {
        marginRight: theme.spacing(2)
      }
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      "& > div": {
        marginBottom: 0,
      }
    }
  },

  title: { 
    marginBottom: theme.spacing(1)
  },

  inputFieldsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2)
  },

  helpContainer: {
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    marginTop: theme.spacing(2),
    "& p": {
      marginBottom: theme.spacing(2)
    },
    "& a": {
      color: theme.palette.secondary
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      paddingLeft: theme.spacing(8),
    }
  },

  helpContent: {
    boxShadow: "7px 8px 12px rgba(0,0,0,0.1)",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    position: "relative",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
    "&:before": {
      [theme.breakpoints.up("md")]: {
        content: "''",
        display: "block",
        position: "absolute",
        left: -20,
        top: "50%",
        transform: "translateY(-50%)",
        border: "10px solid transparent",
        borderRightColor: theme.palette.background.default,
      }
    }
  },

  spacing: {
    marginBottom: theme.spacing(2)
  }

});
