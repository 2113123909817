import * as React from "react";

import { Button, Typography, withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/generic/footer";
import strings from "../../localization/strings";
import ContactInfo from "./contact-info";
import SocialMedia from "./social-media";
import { CustomStyles } from "../../types";
import { Link } from "react-router-dom";

import footerLogo from  "../../resources/svg/ep-liitto.svg";

/**
 * Interface describing optional props
 */
export interface OptionalProps {
}

/**
 * Interface describing component properties
 */
export interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
}

/**
 * App footer component
 *
 * @param props component properties
 */
const Footer: React.FC<Props & OptionalProps> = ({ classes, customStyles }) => {
  /**
   * Component render
   */
  return (
    <div
      className={ classes.container }
      style={ customStyles?.container }
    >
      <div className={ classes.divider } style={ customStyles?.divider } />
      <div
        className={ classes.content }
        style={ customStyles?.content }
      >
        <div className={ classes.innerContent }>
          <div className={ classes.contactContainer }>
            <div className={ classes.logo }>
              <img alt={ strings.client.footerLogoAtlText } src={ footerLogo } />
            </div>
            <ContactInfo />
          </div>
          <div className={ classes.socialContainer }>
            <SocialMedia />
            <Button
              target="_blank"
              href={ strings.footer.feedbackLink }
              variant="contained"
              color="secondary"
              className={ classes.mailingListButton }
              >
              { strings.footer.feedbackLinkButtonText }
            </Button>
          </div>
        </div>
      </div>
      <div
        className={ classes.additionalInfo }
        style={ customStyles?.additionalInfo }
      >
        <div className={ classes.additionalInfoContent }>
          <Typography
            className={ classes.copyright }
            variant="body2"
          >
            { "\u00A9" } { strings.client.copyrightText }
          </Typography>
          <a
            href={strings.footer.privacyPolicyLink}
            target="_blank"
            rel="noopener noreferrer"
            className={ classes.link }
          >
            { strings.footer.privacyPolicy }
          </a>
          <Link
            to= "/terms_of_use"
            className={ classes.link }
          >
            { strings.footer.termsOfUse }
          </Link>
          <Link
            to= "/accessibility"
            className={ classes.link }
          >
            { strings.footer.accessibilityDescription }
          </Link>
          <Link
            to= "/publisher_help"
            className={ classes.link }
          >
            { strings.footer.publisherHelp }
          </Link>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Footer);