import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    width: "100%",
    alignSelf: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper
  },

  inputLabelContainer: {
    display: "flex"
  },

  inputLabel: {
    alignSelf: "center"
  },
  
  title: {
    marginBottom: theme.spacing(2)
  },

});
