import { Theme, createStyles } from "@material-ui/core";

/**
 * Styles for event list event place component
 * @param theme
 */
const styles = (theme: Theme) =>
  createStyles({
    button: {
      color: "#244f5f",
      minWidth: 100,
      display: "flex",
      justifyContent: "flex-start",
      margin: 2,
      borderRadius: 3,
      borderColor: "rgba(118, 90, 180, 0.3)",
      backgroundColor: "rgba(118, 90, 180, 0.2)",
      padding: "4px 8px",
      fontSize: 12,
      transition: "background-color 0.2s ease-out",
      "&:hover": {
        borderColor: "rgba(118, 90, 180, 0.4)",
        backgroundColor: "rgba(118, 90, 180, 0.3)"
      }
    },

    places: {
      borderColor: "rgba(61, 142, 191, 0.3)",
      backgroundColor: "rgba(61, 142, 191, 0.2)",
      "&:hover": {
        borderColor: "rgba(61, 142, 191, 0.4)",
        backgroundColor: "rgba(61, 142, 191, 0.3)"
      }
    },

    whom: {
      borderColor: "rgba(36, 79, 95, 0.3)",
      backgroundColor: "rgba(36, 79, 95, 0.2)",
      "&:hover": {
        borderColor: "rgba(36, 79, 95, 0.4)",
        backgroundColor: "rgba(36, 79, 95, 0.3)"
      }
    }
  });

export default styles;