/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Translation for the language name. Properties shown here are examples, it is suggested that every language supported has its name translated to every other language. Users of the API cannot rely on any translations being present.
 * @export
 * @interface LanguageName
 */
export interface LanguageName {
    /**
     * Name of the language in Finnish
     * @type {string}
     * @memberof LanguageName
     */
    fi?: string;
    /**
     * Name of the language in Swedish
     * @type {string}
     * @memberof LanguageName
     */
    sv?: string;
    /**
     * Name of the language in English
     * @type {string}
     * @memberof LanguageName
     */
    en?: string;
}

export function LanguageNameFromJSON(json: any): LanguageName {
    return LanguageNameFromJSONTyped(json, false);
}

export function LanguageNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fi': !exists(json, 'fi') ? undefined : json['fi'],
        'sv': !exists(json, 'sv') ? undefined : json['sv'],
        'en': !exists(json, 'en') ? undefined : json['en'],
    };
}

export function LanguageNameToJSON(value?: LanguageName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fi': value.fi,
        'sv': value.sv,
        'en': value.en,
    };
}


