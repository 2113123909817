import * as React from "react";

import Api from "../../api/api";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import parseHTML from "react-html-parser";
import strings from "../../localization/strings";
import { ReduxActions, ReduxState } from "../../store";
import { NullableToken, CustomStyles } from "../../types";
import SimpleReactValidator from "simple-react-validator";
import styles from "../../styles/generic/event-categories";
import { IdRef, Keyword, KeywordName } from "../../generated/client";
import { withStyles, WithStyles, FormControlLabel, Checkbox, Typography } from "@material-ui/core";

/**
 * Component properties
 */
interface Props extends WithStyles<typeof styles> {
  keywords: IdRef[];
  showHelp?: boolean;
  showMessages: boolean;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  validator: SimpleReactValidator;
  onChange: (keywords: IdRef[]) => void;
}

/**
 * Component state
 */
interface State { 
  audiencies: Keyword[];
  categories: Keyword[];
}

/**
 * Event categories component
 */
class EventCategories extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      categories: [],
      audiencies: []
    }
  }

  /**
   * Component did mount life-cycle handler
   */
  public componentDidMount = async () => {
    await this.loadFilterKeywordSets();
    this.filterDeprecatedKeywords();
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, showHelp } = this.props;
    const { audiencies, categories } = this.state;

    return (
      <>
        <div className={ classes.container }>
          <div className={ classes.content }>
            <Typography className={ classes.title } variant="h5">
              { strings.event.eventCategories }
            </Typography>
            <div className={ classes.inputGrid }>
              {
                categories.map((category, index) => (
                  <FormControlLabel
                    key={ index }
                    control={
                      <Checkbox
                        checked={ this.isChecked(category.id) }
                        onChange={ this.onChangeKeywords(category) }
                      />
                    }
                    label={ category.name[strings.getLanguage() as keyof KeywordName] }
                  />
                ))
              }
              { this.renderValidatorMessage("keywords", categories) }
            </div>
          </div>
          { showHelp &&
            <div className={ classes.helpContainer }>
              <div className={ classes.helpContent }>
                { parseHTML(strings.eventForm.keywordDescription) }
              </div>
            </div>
          }
        </div>
        <div className={ classes.container }>
          <div className={ classes.content }>
            <Typography className={ classes.title } variant="h5">
              { strings.event.audiences }
            </Typography>
            <div className={ classes.inputGrid }>
              {
                audiencies.map((audience, index) => (
                  <FormControlLabel
                    key={ index }
                    control={
                      <Checkbox
                        checked={ this.isChecked(audience.id) }
                        onChange={ this.onChangeKeywords(audience) }
                      />
                    }
                    label={ audience.name[strings.getLanguage() as keyof KeywordName] }
                  />
                ))
              }
            </div>
          </div>
          { showHelp &&
            <div className={ classes.helpContainer }>
              <div className={ classes.helpContent }>
                { parseHTML(strings.eventForm.keywordNoneDescription) }
              </div>
            </div>
          }
        </div>
      </>
    );
  }

  /**
   * Loads keyword sets
   *
   * @returns data object containing Keyword sets
   */
  private loadFilterKeywordSets = async () => {
    const { accessToken } = this.props;

    const filterApi = Api.getFilterApi(accessToken);

    const keywordSets = (await filterApi.keywordSetList({ include: ["keywords"] })).data || [];

    if (!keywordSets) {
      return;
    }
    const audienciesSet = keywordSets.find(keywordSet => keywordSet.id === "audience");
    const audiencies: Keyword[] = audienciesSet?.keywords || [];
    const categoriesSet = keywordSets.find(keywordSet => keywordSet.id === "category");
    const categories: Keyword[] =  categoriesSet?.keywords || [];

    this.setState({
      audiencies: audiencies.filter(keyword => keyword.deprecated !== true),
      categories: categories.filter(keyword => keyword.deprecated !== true)
    });
  };

  /**
   * Method for filtering deprecated keywords
   */
  private filterDeprecatedKeywords = () => {
    const { keywords, onChange } = this.props;
    const { audiencies, categories } = this.state;

    const filteredKeywords = keywords.filter(keyword =>
      audiencies.some(item => item.id === keyword.id) ||
      categories.some(item => item.id === keyword.id)
    );

    onChange(filteredKeywords);
  }

  /**
   * Method for rendering validator message
   *
   * @param keywordsInSet keyword array
   * @param min minimum inclusive amount required
   * @param max maximum inclusive amount required
   */
  private renderValidatorMessage = (field: string, keywordsInSet: Keyword[]) => {
    const { validator, keywords } = this.props;

    const checkedKeywords = keywordsInSet.filter(keyword => keywords.find(item => item.id === keyword.id));


    return validator.message(field, checkedKeywords, ['required', { max: 3 }]);
  }

  /**
   * Returns true if keyword checkbox is checked
   * 
   * @param checkedKeywordId keyword id
   */
  private isChecked = (checkedKeywordId: string): boolean => {
    const { keywords } = this.props;
    const matchingIds: string[] = [];
    keywords.forEach(keyword => {
      if (keyword.id === checkedKeywordId) {
        matchingIds.push(checkedKeywordId);
      }
    })

    if (matchingIds.includes(checkedKeywordId)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Method for changing keywords
   *
   * @param keyword keyword
   */
  private onChangeKeywords = (keyword: Keyword) => () => {
    const { keywords, onChange } = this.props;
    const existingKeyword = keywords.find(item => item.id === keyword.id);

    if (existingKeyword) {
      onChange(keywords.filter(item => item.id !== keyword.id));
    } else {
      onChange([...keywords, keyword]);
    }
  }

}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(EventCategories);
const Connected = connect(mapStateToProps, mapDispatchToProps)(Styled);

export default Connected;
