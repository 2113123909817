/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdRef,
    IdRefFromJSON,
    IdRefFromJSONTyped,
    IdRefToJSON,
    KeywordSetName,
    KeywordSetNameFromJSON,
    KeywordSetNameFromJSONTyped,
    KeywordSetNameToJSON,
} from './';

/**
 * Keyword sets are used to group keywords together into classification groups. For example, one set of keywords might describe themes used by an event provider and another could be used to describe audience groups.
 * @export
 * @interface PostKeywordSet
 */
export interface PostKeywordSet {
    /**
     * Unique identifier for this keyword_set. These should be URIs identifying the source and the keyword_set itself, and preferably also well formed http-URLs pointing to more information about the keyword.
     * @type {string}
     * @memberof PostKeywordSet
     */
    id: string;
    /**
     * 
     * @type {KeywordSetName}
     * @memberof PostKeywordSet
     */
    name: KeywordSetName;
    /**
     * Set identifier in the originating system, if any
     * @type {string}
     * @memberof PostKeywordSet
     */
    originId?: string;
    /**
     * Time when this keyword_set was created (ISO 8601)
     * @type {Date}
     * @memberof PostKeywordSet
     */
    createdTime?: Date;
    /**
     * Time when this keyword_set was last modified (ISO 8601)
     * @type {Date}
     * @memberof PostKeywordSet
     */
    lastModifiedTime?: Date;
    /**
     * Unique identifier (URI)for the system where this keyword_set originated, if any
     * @type {string}
     * @memberof PostKeywordSet
     */
    dataSource?: string;
    /**
     * FIXME(verify) Which API user most recently edited this keyword
     * @type {string}
     * @memberof PostKeywordSet
     */
    lastModifiedBy?: string;
    /**
     * Usage type for this keyword_set. These are allow UIs to show the set in appropriate place. FIXME: set of types is not finalized by any stretch
     * @type {string}
     * @memberof PostKeywordSet
     */
    usage?: PostKeywordSetUsageEnum;
    /**
     * Organization that has defined this keyword_set
     * @type {string}
     * @memberof PostKeywordSet
     */
    organization?: string;
    /**
     * Keywords that belong to this keyword_set
     * @type {Array<IdRef>}
     * @memberof PostKeywordSet
     */
    keywords: Array<IdRef>;
}

export function PostKeywordSetFromJSON(json: any): PostKeywordSet {
    return PostKeywordSetFromJSONTyped(json, false);
}

export function PostKeywordSetFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostKeywordSet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': KeywordSetNameFromJSON(json['name']),
        'originId': !exists(json, 'origin_id') ? undefined : json['origin_id'],
        'createdTime': !exists(json, 'created_time') ? undefined : (new Date(json['created_time'])),
        'lastModifiedTime': !exists(json, 'last_modified_time') ? undefined : (new Date(json['last_modified_time'])),
        'dataSource': !exists(json, 'data_source') ? undefined : json['data_source'],
        'lastModifiedBy': !exists(json, 'last_modified_by') ? undefined : json['last_modified_by'],
        'usage': !exists(json, 'usage') ? undefined : json['usage'],
        'organization': !exists(json, 'organization') ? undefined : json['organization'],
        'keywords': ((json['keywords'] as Array<any>).map(IdRefFromJSON)),
    };
}

export function PostKeywordSetToJSON(value?: PostKeywordSet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': KeywordSetNameToJSON(value.name),
        'origin_id': value.originId,
        'created_time': value.createdTime === undefined ? undefined : (value.createdTime.toISOString()),
        'last_modified_time': value.lastModifiedTime === undefined ? undefined : (value.lastModifiedTime.toISOString()),
        'data_source': value.dataSource,
        'last_modified_by': value.lastModifiedBy,
        'usage': value.usage,
        'organization': value.organization,
        'keywords': ((value.keywords as Array<any>).map(IdRefToJSON)),
    };
}

/**
* @export
* @enum {string}
*/
export enum PostKeywordSetUsageEnum {
    Any = 'any',
    Keyword = 'keyword',
    Audience = 'audience'
}


