import * as React from "react";

import { Event, EventName, EventProvider, Keyword, EventDescription, OfferPrice, OfferInfoUrl, Offer, PlaceName, Place, PlaceStreetAddress, PostEvent, KeywordSet, EventShortDescription, Eventlink } from "../generated/client";
import ReactHtmlParser, { Transform, convertNodeToElement } from "react-html-parser";
import { NullableToken, Attachment, CustomData, LocalizedStrings, AccessToken, EventPeriod, LinkedEventsObject } from "../types";
import dummyImageUrl from "../resources/img/dummy.png";
import strings from "../localization/strings";
import { DomElement } from "domhandler";
import Api from "../api/api";
import moment from "moment";
import { Button } from "@material-ui/core";

export default class EventUtils {

  /**
   * Method for getting event id
   *
   * @param event linked events event
   * @returns string
   */
  public static getEventId = (event: Event): string => {
    const { id } = event;

    if (!id) {
      return strings.errors.idNotFound;
    }

    return id;
  }

  /**
   * Resolves whether user will have permission to edit the event or not
   *
   * @param accessToken access token
   * @param event event
   * @return whether user will have permission to edit the event or not
   */
  public static canEditEvent = (accessToken: AccessToken, event: PostEvent) => {
    if (!accessToken?.userId) {
      return false;
    }

    return event?.publisher === `eptapahtumat:oidc:user:${accessToken.userId}`;
  }

  /**
   * Method for getting event name
   *
   * Note: when localized name is
   * missing defaults to finnish name
   *
   * @param event linked events event
   * @returns string
   */
  public static getEventName = (event: Event): string => {
    const { name } = event;
    const localizedName = name[strings.getLanguage() as keyof EventName];

    if (!localizedName) {
      const finnishName = name["fi" as keyof EventName];

      if (!finnishName) {
        return strings.errors.eventNameMissing;
      }

      return finnishName;
    }

    return localizedName;
  }

  /**
   * Method for getting event prices
   *
   * Note: when localized price is
   * missing defaults to finnish price
   *
   * Note: description field is omitted
   * and price field is used instead to
   * keep old event data compatible
   *
   * @param event linked events event
   */
  public static getEventPrices = (event: Event) => {
    const { offers } = event;

    if (!offers) {
      return <p>{ strings.errors.priceMissing }</p>;
    }

    const prices = offers.map((offer: Offer, index: number) => {
      const { isFree } = offer;

      const { price, infoUrl } = offer;

      if(isFree && !price) {
        return <p key={ index }>{ strings.eventForm.freeOfCharge }</p>;
      }

      if (!price) {
        return <p key={ index }>{ strings.errors.priceMissing }</p>;
      }

      const localizedPrice = EventUtils.getLocalizedPrice(price, infoUrl, isFree, index);

      return localizedPrice;
    });

    return prices;
  }

  /**
   * Method for getting
   * localized price
   *
   * Note: when localization is
   * missing defaults to finnish
   *
   * Note: description field is omitted
   * and price field is used instead to
   * keep old event data compatible
   *
   * @param price offer price
   * @param description offer description
   * @param infoUrl info URL
   */
  public static getLocalizedPrice = (price: OfferPrice, infoUrl?: OfferInfoUrl, isFree?: boolean, index?: number) => {
    let localizedPrice = price[strings.getLanguage() as keyof OfferPrice];
    let localizedInfoUrl = infoUrl ? infoUrl[strings.getLanguage() as keyof OfferInfoUrl] : undefined;

    if (!localizedPrice) {
      localizedPrice = price["fi" as keyof OfferPrice];
    }

    if (!localizedInfoUrl) {
      localizedInfoUrl = infoUrl ? infoUrl["fi" as keyof OfferInfoUrl] : undefined;
    }

    if (localizedInfoUrl && !(/^https?:\/\//.test(localizedInfoUrl))) {
      localizedInfoUrl = `https://${localizedInfoUrl}`;
    }

    return (
      <div key={ index }>
        { isFree &&
          <p>{ strings.event.freeOfCharge }</p>
        }
        <p>{ localizedPrice }</p>
        { localizedInfoUrl &&
          <p>
            <a style={{ textDecoration: "none" }} rel="noopener noreferrer" target="_blank" href={ localizedInfoUrl }>
              <Button>{ strings.event.buyTickets }</Button>
            </a>
          </p>
        }
      </div>
    );
  }

  /**
   * Method for getting event image source url
   * If url contains parenthesis, it will be encoded since Helmet provider can't handle them
   *
   * @param event linked events event
   * @returns string
   */
  public static getEventImageSrc = (event: Event): string => {
    const { images } = event;

    if (!images || images.length === 0) {
      return dummyImageUrl;
    }

    return images[0].url.replace(/\(/g, '%28').replace(/\)/g, '%29');
  }

  /**
   * Returns formatted version of event time
   *
   * @param event linked events event
   * @returns formatted version of event time
   */
  public static formatEventDate = (event: Event | PostEvent): string => {
    const { startTime, endTime, hasStartTime, hasEndTime } = event;

    if (!endTime) {
      if (!hasStartTime) {
        return moment(startTime).format("ll");
      } else {
        const startMoment = moment(startTime);
        return strings.formatString(strings.generic.dateStartFrom, startMoment.format("ll"), startMoment.format("LT")) as string;
      }
    }

    if (EventUtils.isSameDay(startTime, endTime) && !hasStartTime) {
      return moment(startTime).format("ll");
    }

    if (moment(startTime).isSame(moment(endTime))) {
      return EventUtils.formatDate(startTime, !!hasStartTime);
    }

    if (EventUtils.isSameDay(startTime, endTime)) {
      const startMoment = moment(startTime);
      const endMoment = moment(endTime);
      return strings.formatString(strings.generic.dateTimeRange, startMoment.format("ll"), startMoment.format("LT"), endMoment.format("LT")) as string;
    }

    return `${EventUtils.formatDate(startTime, !!hasStartTime)} - ${EventUtils.formatDate(endTime, !!hasEndTime)}`;
  }

  /**
   * Method for getting event duration
   *
   * @param event linked events event
   * @returns string
   */
  public static getEventDuration = (event: Event): string => {
    const { startTime, endTime } = event;

    if (!startTime || !endTime) {
      return strings.errors.eventDurationMissing;
    }

    const startDateString = moment(startTime).format("DD.MM.YYYY");
    const startTimeString = moment(startTime).format("HH.mm");
    const endDateString = moment(endTime).format("DD.MM.YYYY");
    const endTimeString = moment(endTime).format("HH.mm");

    return strings.formatString(strings.event.dateTimeRange, startDateString, startTimeString, endDateString, endTimeString) as string;
  }

  /**
   * Method for getting event provider name
   *
   * Note: when localized name is
   * missing defaults to finnish name
   *
   * @param event linked events event
   * @returns string
   */
  public static getEventProviderName = (event: Event): string => {
    const { provider } = event;

    if (!provider) {
      return "";
    }

    const localizedProviderName = provider[strings.getLanguage() as keyof EventProvider];

    if (!localizedProviderName) {
      const finnishProviderName = provider["fi" as keyof EventProvider];

      if (!finnishProviderName) {
        return "";
      }

      return finnishProviderName;
    }

    return localizedProviderName;
  }

  /**
   * Method for getting place name
   *
   * Note: when localized name is
   * missing defaults to finnish name
   *
   * @param place linked events place
   * @returns string
   */
  public static getPlaceName = (place: Place): string => {
    const { name } = place;

    const localizedName = name[strings.getLanguage() as keyof PlaceName];

    if (!localizedName) {
      const finnishName = name["fi" as keyof PlaceName];

      if (!finnishName) {
        return strings.errors.locationMissing;
      }

      return finnishName;
    }

    return localizedName;
  }

  /**
   * Method for getting place description
   *
   * Note: when localized description is
   * missing defaults to finnish description
   *
   * @param event linked events event
   * @returns string
   */
  public static getPlaceDescription = (event: Event): string => {
    const { locationExtraInfo } = event;

    if (!locationExtraInfo) {
      return "";
    }

    const localizedDescription = locationExtraInfo[strings.getLanguage() as keyof object];

    if (!localizedDescription) {
      const finnishDescription = locationExtraInfo["fi" as keyof object];

      if (!finnishDescription) {
        return "";
      }

      return finnishDescription;
    }

    return localizedDescription;
  }

  /**
   * Method for getting place address
   *
   * Note: when localized address is
   * missing defaults to finnish address
   *
   * @param place linked events place
   * @returns string
   */
  public static getPlaceAddress = (place: Place) => {
    const streetAddress = EventUtils.getStreetAddress(place);
    const addressRegion = EventUtils.getAddressRegion(place);
    const addressCountry = EventUtils.getAddressCountry(place);

    return `${streetAddress}, ${addressRegion}, ${addressCountry}`;
  }

  /**
   * Method for getting street address
   *
   * Note: when localized street address is
   * missing defaults to finnish street address
   *
   * @param place linked events place
   * @returns string
   */
  public static getStreetAddress = (place: Place) => {
    const { streetAddress } = place;

    if (!streetAddress) {
      return "";
    }

    const localizedStreetAddress = streetAddress[strings.getLanguage() as keyof PlaceStreetAddress];

    if (!localizedStreetAddress) {
      const finnishName = streetAddress["fi" as keyof PlaceStreetAddress];

      if (!finnishName) {
        return "";
      }

      return finnishName;
    }

    return localizedStreetAddress;
  }

  /**
   * Method for getting address region
   *
   * @param place linked events place
   * @returns string
   */
  public static getAddressRegion = (place: Place) => {
    const { addressRegion } = place;

    if (!addressRegion) {
      return "";
    }

    return addressRegion;
  }

  /**
   * Method for getting country
   *
   * @param place linked events place
   * @returns string
   */
  public static getAddressCountry = (place: Place) => {
    const { addressCountry } = place;

    if (!addressCountry) {
      return "";
    }

    return strings.countries[addressCountry as keyof object];
  }

  /**
   * Method for getting address
   *
   * @param event linked events event
   * @param accessToken access token
   */
  public static getEventPlace = async (event: Event, accessToken?: NullableToken) => {
    const url = event.location.id || "";
    const id = EventUtils.parseIdFromUrl(url);
    const filterApi = Api.getFilterApi(accessToken);
    const place = await filterApi.placeRetrieve({ id: id });
    return place;
  }

  /**
   * Method for getting keyword
   *
   * @param event linked events event
   * @param accessToken access token
   * @returns keyword array
   */
  public static getKeywords = async (event: Event, accessToken?: NullableToken): Promise<Keyword[]> => {
    const { keywords } = event;

    if (!keywords) {
      return [];
    }

    const filterApi = Api.getFilterApi(accessToken);

    const fetchedKeywords = await Promise.all(
      keywords.map((keyword: Keyword) => {
        const url = keyword.id;
        const id = EventUtils.parseIdFromUrl(url);
        return filterApi.keywordRetrieve({ id: id || "" });
      })
    );

    return fetchedKeywords;
  }

  /**
   * Loads keyword sets from the API
   *
   * @param accessToken access token
   * @return promise of list of KeywordSet objects
   */
  public static getKeywordSets = async (accessToken?: NullableToken): Promise<KeywordSet[]> => {
    const filterApi = Api.getFilterApi(accessToken);
    const keywordSetList = await filterApi.keywordSetList({ include: ["keywords"] });
    return keywordSetList?.data || [];
  }

  /**
   * Finds Event by id
   *
   * @param accesToken
   * @param eventId event id
   */
  public static getEventById = async (eventId: string, accesToken?: NullableToken) => {
    const eventApi = Api.getEventApi(accesToken);
    const event = eventApi.eventRetrieve({ id: eventId });

    return event;
  }

  /**
   * Method for getting all events
   *
   * @param accesToken
   * @returns array of events
   */
  public static getAllEvents = async (accesToken?: NullableToken) => {
    const eventApi = Api.getEventApi(accesToken);
    let allEvents: Event[] = [];
    let counter = 1;
    let date = new Date();

    date.setMonth(date.getMonth() - 1);

    for (var i  = 0; i < counter; i++) {
      const eventsPage = (await eventApi.eventList({ page: counter, pageSize: 100, start: date })).data;

      if (!eventsPage) {
        return [];
      }

      if (eventsPage.length === 100) {
        counter++;
      }

      allEvents = allEvents.concat(eventsPage);
    }

    return allEvents.filter(event => event.superEvent === undefined);
  }

  /**
   * Deletes existing event
   *
   * @param event event
   * @param accessToken access token
   */
  public static deleteExistingEvent = async (event: Event | PostEvent, accessToken?: NullableToken) => {
    const eventApi = Api.getEventApi(accessToken);
    const id = EventUtils.parseIdFromUrl(event.id || "");

    if (event.subEvents) {
      await Promise.all(
        event.subEvents.map(subEvent =>
          eventApi.eventDelete({ id: EventUtils.parseIdFromUrl(subEvent.id || "") })
        )
      );
    }

    await eventApi.eventDelete({ id: id });
  }

  /**
   * Method for updating an event
   *
   * @param accessToken access token
   * @param id id
   * @param event linked events event
   */
  public static updateExistingEvent = async (
    eventId: string,
    event: PostEvent,
    eventPeriods: EventPeriod[],
    accessToken?: NullableToken,
  ) => {
    try {
      const eventApi = Api.getEventApi(accessToken);
      const imageApi = Api.getImageApi(accessToken);

      const previousSubEvents = await EventUtils.getEventSubEvents(event, accessToken);

      const parsedImageId = EventUtils.parseIdFromUrl(event.images![0].id!);
      const image = await imageApi.imageRetrieve({ id: parsedImageId });

      // Create new sub events if new event periods are added
      const subEventsToCreate = eventPeriods
        .filter(period => !period.id)
        .map<PostEvent>(period => ({
          ...event,
          id: undefined,
          subEvents: [],
          superEventType: "",
          startTime: period.startTime,
          endTime: period.endTime,
          images: event.images
        }));

      const newSubEvents = subEventsToCreate.length
        ? await eventApi.eventCreate({ eventObjects: subEventsToCreate })
        : [];

      // Update existing sub events
      const subEventsToUpdate = eventPeriods
        .filter(period => !!period.id)
        .reduce<Event[]>((list, period) => {
          const subEvent = previousSubEvents.find(subEvent => EventUtils.parseIdFromUrl(subEvent.id!) === period.id!);

          if (subEvent)
            list.push({
              ...subEvent,
              startTime: period.startTime,
              endTime: period.endTime,
              images: [image],
              name: event.name,
              customData: event.customData,
              provider: event.provider,
              location: {...subEvent.location, id: event.location.id},
              keywords: [...event.keywords as Keyword[]],
              description: event.description,
              shortDescription: event.shortDescription,
              infoUrl: event.infoUrl,
              offers: event.offers,
              locationExtraInfo: event.locationExtraInfo,
              audience: event.audience,
              externalLinks: event.externalLinks
            });

          return list;
        }, []);

      const updatedSubEvents = await Promise.all(
        subEventsToUpdate.map(subEvent => eventApi.eventUpdate({ id: subEvent.id!, eventObject: subEvent }))
      );

      await Promise.all(
        previousSubEvents
          .filter(({ id: subEventId }) => eventPeriods.every(({ id }) => id !== EventUtils.parseIdFromUrl(subEventId!)))
          .map(({ id }) => eventApi.eventDelete({ id: EventUtils.parseIdFromUrl(id!) }))
      );

      const updatedListOfSubEvents = [...updatedSubEvents, ...newSubEvents];

      event.subEvents = updatedListOfSubEvents.map(subEvent => ({
        ...subEvent,
        id: EventUtils.urlFromId(subEvent.id!, LinkedEventsObject.Event)
      }));
      event.superEventType = updatedListOfSubEvents.length ? "recurring" : "";
      const updatedEvent = await eventApi.eventUpdate({ id: eventId, eventObject: event });

      return updatedEvent;
    } catch (error) {
      console.error('An error occurred during the event update:', error);
      throw error;
    }
  };

  /**
   * Method for getting all keywords
   *
   * @param accesToken
   * @returns array of keywords
   */
  public static getAllKeywords = async (accesToken?: NullableToken) => {
    const filterApi = Api.getFilterApi(accesToken);
    const allKeywords = (await filterApi.keywordList({ pageSize: 100, showAllKeywords: true })).data;

    if (!allKeywords) {
      return [];
    }

    return allKeywords;
  }

  /**
   * Method for creating a new keyword
   *
   * @param accesToken access token
   * @param keyword keyword
   */
  public static createNewKeyword = async (keyword: Keyword, accesToken?: NullableToken) => {
    const filterApi = Api.getFilterApi(accesToken);
    const newKeyword = await filterApi.keywordCreate({ keywordObject: keyword });

    return newKeyword;
  }

  /**
   * Method for updating a keyword
   *
   * @param accessToken access token
   * @param id id
   * @param keyword keyword
   */
  public static updateExistingKeyword = async (id: string, keyword: Keyword, accessToken?: NullableToken) => {
    const filterApi = Api.getFilterApi(accessToken);
    const updatedKeyword = await filterApi.keywordUpdate({ id: id, keywordObject: keyword });

    return updatedKeyword;
  }

  /**
   * Method for deleting a keyword
   *
   * @param accessToken access token
   * @param id id
   */
  public static deleteExistingKeyword = async (id: string, accessToken?: NullableToken) => {
    const filterApi = Api.getFilterApi(accessToken);
    const deletedKeyword = await filterApi.keywordDelete({ id: id });

    return deletedKeyword;
  }

  /**
   * Method for getting all places
   *
   * @param accessToken
   * @param id
   */
  public static getAllPlaces = async (accessToken?: NullableToken) => {
    const filterApi = Api.getFilterApi(accessToken);
    let allPlaces: Place[] = [];
    let counter = 1;
    for (var i  = 0; i < counter; i++) {
      const placesPage = (await filterApi.placeList({ page: counter, pageSize: 100, showAllPlaces: false })).data;

      if (!placesPage) {
        return [];
      }

      if (placesPage.length === 100) {
        counter++;
      }

      allPlaces = allPlaces.concat(placesPage);
    }

    return allPlaces;
  }

  /**
   * Method for creating a new place
   *
   * @param accesToken access token
   * @param place place
   */
  public static createNewPlace = async (place: Place, accesToken?: NullableToken) => {
    const filterApi = Api.getFilterApi(accesToken);
    const newPlace = await filterApi.placeCreate({ placeObject: place });

    return newPlace;
  }

  /**
   * Method for creating a new event
   *
   * @param accessToken access token
   * @param event event
   */
  public static createNewEvent = async (event: PostEvent, eventPeriods: EventPeriod[], accessToken: AccessToken): Promise<Event> => {
    const eventApi = Api.getEventApi(accessToken);

    event.publisher = `eptapahtumat:oidc:user:${accessToken.userId}`;
    event.superEventType = eventPeriods ? "recurring" : "";

    if (eventPeriods) {
      const subEvents = await eventApi.eventCreate({
        eventObjects: eventPeriods.map(eventPeriod => ({
          ...event,
          id: undefined,
          superEventType: "",
          subEvents: [],
          startTime: eventPeriod.startTime,
          endTime: eventPeriod.endTime
        }))
      });

      event.subEvents = subEvents.map(subEvent => ({
        id: EventUtils.urlFromId(subEvent.id || "", LinkedEventsObject.Event)
      }));
    }

    const response = await eventApi.eventCreate({ eventObjects: [event] });
    const newEvent = response.length ? response[0] : {} as Event;

    return newEvent;
  }

  /**
   * Method for updating a place
   *
   * @param accessToken access token
   * @param id place id
   * @param place place
   */
  public static updateExistingPlace = async (id: string, place: Place, accessToken?: NullableToken) => {
    const filterApi = Api.getFilterApi(accessToken);
    const updatedPlace = await filterApi.placeUpdate({ id: id, placeObject: place });

    return updatedPlace;
  }

  /**
   * Method for deleting a place
   *
   * @param accessToken access token
   * @param id place id
   */
  public static deleteExistingPlace = async (id: string, accessToken?: NullableToken) => {
    const filterApi = Api.getFilterApi(accessToken);
    const deletedPlace = await filterApi.placeDelete({ id: id });

    return deletedPlace;
  }

  /**
   * Method for getting description
   *
   * Note: when localized description is
   * missing defaults to finnish description
   *
   * @param event linked events event
   */
  public static getEventDescription = (event: Event) => {
    const { description } = event;

    if (!description) {
      return strings.errors.descriptionNotFound;
    }

    const localizedDescription = description[strings.getLanguage() as keyof EventDescription];

    if (!localizedDescription) {
      const finnishDescription = description["fi" as keyof EventDescription];

      if (!finnishDescription) {
        return strings.errors.descriptionNotFound;
      }

      return ReactHtmlParser(finnishDescription, { transform: EventUtils.transform });
    }

    return ReactHtmlParser(localizedDescription, { transform: EventUtils.transform });
  }

  /**
   * Method for getting short description
   *
   * Note: when localized short description is
   * missing defaults to finnish short description
   *
   * @param event linked events event
   */
  public static getEventShortDescription = (event: Event) => {
    const { shortDescription } = event;

    if (!shortDescription) {
      return strings.errors.shortDescriptionNotFound;
    }

    const localizedDescription = shortDescription[strings.getLanguage() as keyof EventShortDescription];

    if (!localizedDescription) {
      const finnishShortDescription = shortDescription["fi" as keyof EventShortDescription];

      if (!finnishShortDescription) {
        return strings.errors.shortDescriptionNotFound;
      }

      return ReactHtmlParser(finnishShortDescription, { transform: EventUtils.transform });
    }

    return ReactHtmlParser(localizedDescription, { transform: EventUtils.transform });
  }

  /**
   * Method for parsing id from url
   *
   * Note: method is necessary because linked
   * events returns some event properties as
   * urls that contain id as their last part
   *
   * @param url url
   * @returns string
   */
  public static parseIdFromUrl = (url: string): string => {
    if (!url) {
      return "";
    }
    const stripped = url.replace(/^\/|\/$/g, "");
    const splitted = stripped.split("/");
    const id = splitted.pop();

    if (!id) {
      return "";
    }

    return id;
  }

  /**
   * Method for creating url that
   * points to linked events api object
   *
   * Note: sometimes id is returned as id
   * but needed as url in cases where id
   * reference is required
   *
   * @param id id
   * @param type linked events object type
   */
  public static urlFromId = (id: string, type: LinkedEventsObject) => {
    return `${process.env.REACT_APP_API_BASE_PATH}/${type.toString()}/${id}/`;
  }

  /**
   * Method for transforming html
   * string to jsx elements
   *
   * Note: event data might contain
   * html code as string that needs
   * to be parsed
   *
   * @param node DOM element node
   * @param index node index
   * @param transform transform method
   */
  public static transform = (node: DomElement, index: number, transform?: Transform) => {
    const nodeDataParsed = {
      ...node,
      data: ReactHtmlParser(node.data)
    }
    return convertNodeToElement(nodeDataParsed, index, EventUtils.transform);
  }

  /**
   * Method for getting event custom data
   *
   * @param event linked events event
   */
  public static getEventCustomData = (event: Event): CustomData => {
    const { customData } = event;

    if (!customData) {
      return {
        contact_name: "",
        contact_phone: "",
        contact_email: "",
        provider_phone: "",
        provider_email: "",
        other_attachments: "[]",
        registration_description: `{
          "fi": "",
          "sv": "",
          "en": ""
        }`,
      };
    }

    return customData as CustomData;
  }

  /**
   * Method for getting event contact email
   *
   * @param event linked events event
   * @returns string
   */
  public static getEventContactEmail = (event: Event): string => {
    const customData = EventUtils.getEventCustomData(event);

    if (!customData) {
      return "";
    }

    const { contact_email } = customData;

    if (!contact_email) {
      return "";
    }

    return contact_email;
  }

  /**
   * Method for getting event contact name
   *
   * @param event linked events event
   * @returns string
   */
  public static getEventContactName = (event: Event): string => {
    const customData = EventUtils.getEventCustomData(event);

    if (!customData) {
      return "";
    }

    const { contact_name } = customData;

    if (!contact_name) {
      return "";
    }

    return contact_name;
  }

  /**
   * Method for getting event contact phone
   *
   * @param event linked events event
   * @returns string
   */
  public static getEventContactPhone = (event: Event): string => {
    const customData = EventUtils.getEventCustomData(event);

    if (!customData) {
      return "";
    }

    const { contact_phone } = customData;

    if (!contact_phone) {
      return "";
    }

    return contact_phone;
  }

  /**
   * Method for getting event attachments
   *
   * @param event linked events event
   * @returns string
   */
  public static getEventAttachments = (event: Event): Attachment[] => {
    const customData = EventUtils.getEventCustomData(event);
    const { other_attachments } = customData;

    if (!other_attachments) {
      return [];
    }

    const attachments = JSON.parse(other_attachments);

    return attachments;
  }

  /**
   * Method for getting event provider email
   *
   * @param event linked events event
   * @returns string
   */
  public static getEventProviderEmail = (event: Event): string => {
    const customData = EventUtils.getEventCustomData(event);

    if (!customData) {
      return "";
    }

    const { provider_email } = customData;

    if (!provider_email) {
      return "";
    }

    return provider_email;
  }

  /**
   * Method for getting event provider phone
   *
   * @param event linked events event
   * @returns string
   */
  public static getEventProviderPhone = (event: Event): string => {
    const customData = EventUtils.getEventCustomData(event);

    if (!customData) {
      return "";
    }

    const { provider_phone } = customData;

    if (!provider_phone) {
      return "";
    }

    return provider_phone;
  }

  /**
   * Method for getting event registration description
   *
   * Note: when localized description is
   * missing defaults to finnish description
   *
   * @param event linked events event
   * @returns string
   */
  public static getEventRegistrationDescription = (event: Event): string => {
    const customData = EventUtils.getEventCustomData(event);

    if (!customData) {
      return "";
    }

    const registrationDescription = customData["registration_description" as keyof object];
    const parsed: LocalizedStrings = JSON.parse(registrationDescription);

    const localizedName = parsed[strings.getLanguage() as keyof PlaceName];

    if (!localizedName) {
      const finnishName = parsed["fi" as keyof PlaceName];

      if (!finnishName) {
        return "";
      }

      return finnishName;
    }

    return localizedName;
  }

  /**
   * Method for getting localization keys
   *
   * @returns string array of localization keys
   */
  public static getLocalizationKeys = (): string[] => {
    return [
      "fi",
      "en"
    ];
  }

  /**
   * Method for getting event periods
   *
   * @param event linked events event
   * @param accessToken access token
   * @returns promise of event periods array
   */
  public static getEventPeriods = async (event: PostEvent, accessToken: AccessToken): Promise<EventPeriod[]> => {
    const subEvents = await EventUtils.getEventSubEvents(event, accessToken);

    return subEvents.map(event => ({
      startTime: event.startTime || new Date(),
      endTime: event.endTime || new Date(),
      id: event.id
    }));
  }

  /**
   * Method for getting event sub events
   *
   * @param event linked events event
   * @param accessToken access token
   * @returns promise of sub events array
   */
  public static getEventSubEvents = async (event: PostEvent, accessToken?: NullableToken): Promise<Event[]> => {
    const subEventRefs = event.subEvents;

    if (!subEventRefs || !subEventRefs.length) {
      return [];
    }

    const subEventPromises = subEventRefs.map(subEventRef => {
      const id = EventUtils.parseIdFromUrl(subEventRef.id || "");
      return EventUtils.getEventById(id, accessToken);
    });

    return await Promise.all(subEventPromises);
  }

  /**
   * Method for preprocessing external links
   *
   * @param event linked events event
   */
  public static preprocessExternalLinks = (event: PostEvent): Eventlink[] => {
    const externalLinks = event.externalLinks;

    if (!externalLinks) {
      return [];
    }

    const preprocessedLinks = externalLinks.map(linkObject => {
      const link = linkObject.link;

      if (link && !/^https?:\/\//g.test(link)) {
        return {
          ...linkObject,
          link: `https://${ link }`
        };
      }

      return linkObject;
    });

    return preprocessedLinks;
  }

  /**
   * Returns whether start and end are on the same date
   *
   * @param start start time
   * @param end end time
   * @return whether start and end are on the same date
   */
  private static isSameDay = (start: Date, end: Date): boolean => {
    const startMoment = moment(start);
    const endMoment = moment(end);

    return startMoment.isSame(endMoment, "day");
  }

  /**
   * Returns formatted date time
   *
   * @param date date
   * @param hasTime whether time should be returned
   * @return formatted date time
   */
  private static formatDate = (date: Date, hasTime: boolean): string => {
    if (hasTime) {
      return moment(date).format("lll");
    }

    return moment(date).format("ll");
  }

}
