import * as React from "react";

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";

/**
 * Interface representing component properties
 */
interface Props {
  positiveButtonText: string;
  cancelButtonText: string;
  onCancel?: () => void;
  onConfirm: () => void;
  open: boolean;
  title?: string;
  description?: string;
}

/**
 * Interface representing component state
 */
interface State {

}

/**
 * React component displaying confirm dialogs
 */
export default class GenericConfirmDialog extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props component properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render method
   */
  public render() {
    const {
      open,
      positiveButtonText,
      cancelButtonText,
      onCancel,
      title,
      description,
      onConfirm,
    } = this.props;

    return (
      <>
        <Dialog
          open={ open }
          onClose={ onCancel }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
          >
            { title }
          </DialogTitle>
          <DialogContent>
            <DialogContentText color="textPrimary">
              { description }
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              disableElevation
              variant="text"
              onClick={ onCancel }
              autoFocus
            >
              { cancelButtonText }
            </Button>
            <Button
              disableElevation
              variant="contained"
              onClick={ onConfirm }
              autoFocus
            >
              { positiveButtonText }
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
