/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImageName,
    ImageNameFromJSON,
    ImageNameFromJSONTyped,
    ImageNameToJSON,
} from './';

/**
 * Images are used as pictures for events, places and organizers.
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * image id
     * @type {string}
     * @memberof Image
     */
    id?: string;
    /**
     * 
     * @type {ImageName}
     * @memberof Image
     */
    name?: string;
    /**
     * The organization responsible for the image.
     * @type {string}
     * @memberof Image
     */
    publisher?: string;
    /**
     * Creation time for the image.
     * @type {Date}
     * @memberof Image
     */
    createdTime?: Date;
    /**
     * Time this image was modified in the datastore behind the API (not necessarily in the originating system)
     * @type {Date}
     * @memberof Image
     */
    lastModifiedTime?: Date;
    /**
     * URL reference to the user that created this record (user endpoint)
     * @type {string}
     * @memberof Image
     */
    createdBy?: string;
    /**
     * URL reference to the user that last modfied this record (user endpoint)
     * @type {string}
     * @memberof Image
     */
    lastModifiedBy?: string;
    /**
     * The image file URL.
     * @type {string}
     * @memberof Image
     */
    url: string;
    /**
     * Cropping data for the image.
     * @type {string}
     * @memberof Image
     */
    cropping?: string;
    /**
     * License data for the image. May be "cc_by" (default) or "event_only". The latter license restricts use of the image and is specified on the API front page.
     * @type {string}
     * @memberof Image
     */
    license?: string;
}

export function ImageFromJSON(json: any): Image {
    return ImageFromJSONTyped(json, false);
}

export function ImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Image {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, '@id') ? undefined : json['@id'],
        'name': !exists(json, 'name') ? undefined : ImageNameFromJSON(json['name']),
        'publisher': !exists(json, 'publisher') ? undefined : json['publisher'],
        'createdTime': !exists(json, 'created_time') ? undefined : (new Date(json['created_time'])),
        'lastModifiedTime': !exists(json, 'last_modified_time') ? undefined : (new Date(json['last_modified_time'])),
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'lastModifiedBy': !exists(json, 'last_modified_by') ? undefined : json['last_modified_by'],
        'url': json['url'],
        'cropping': !exists(json, 'cropping') ? undefined : json['cropping'],
        'license': !exists(json, 'license') ? undefined : json['license'],
    };
}

export function ImageToJSON(value?: Image | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': ImageNameToJSON(value.name),
        'publisher': value.publisher,
        'created_time': value.createdTime === undefined ? undefined : (value.createdTime.toISOString()),
        'last_modified_time': value.lastModifiedTime === undefined ? undefined : (value.lastModifiedTime.toISOString()),
        'created_by': value.createdBy,
        'last_modified_by': value.lastModifiedBy,
        'url': value.url,
        'cropping': value.cropping,
        'license': value.license,
    };
}


