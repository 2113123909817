/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Organizations are the entities that publish events and other data.
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * Consists of source prefix and source specific identifier. These should be URIs uniquely identifying the organization, and preferably also well formed http-URLs pointing to more information about the organization.
     * @type {string}
     * @memberof Organization
     */
    id: string;
    /**
     * The name of the organization.
     * @type {string}
     * @memberof Organization
     */
    name: string;
    /**
     * Identifier for the organization in the original data source. For standardized namespaces this will be a shared identifier.
     * @type {string}
     * @memberof Organization
     */
    originId?: string;
    /**
     * Creation time for the organization information.
     * @type {Date}
     * @memberof Organization
     */
    createdTime?: Date;
    /**
     * Time this organization was modified in the datastore behind the API (not necessarily in the originating system)
     * @type {Date}
     * @memberof Organization
     */
    lastModifiedTime?: Date;
    /**
     * Source of the organization data, typically API provider specific identifier. Will also be used to specify standardized namespaces as they are brought into use.
     * @type {string}
     * @memberof Organization
     */
    dataSource?: string;
    /**
     * Id of the organization type.
     * @type {string}
     * @memberof Organization
     */
    classification?: string;
    /**
     * Time the organization was founded.
     * @type {Date}
     * @memberof Organization
     */
    foundingDate?: Date;
    /**
     * Time the organization was dissolved. If present, the organization no longer exists.
     * @type {Date}
     * @memberof Organization
     */
    dissolutionDate?: Date;
    /**
     * Whether the organization is an affiliated organization of the parent, instead of a proper suborganization.
     * @type {boolean}
     * @memberof Organization
     */
    isAffiliated?: boolean;
    /**
     * 
     * @type {Organization}
     * @memberof Organization
     */
    replacedBy?: Organization;
    /**
     * Whether the organization has non-admin users in addition to admin users.
     * @type {boolean}
     * @memberof Organization
     */
    hasRegularUsers?: boolean;
    /**
     * 
     * @type {Organization}
     * @memberof Organization
     */
    parentOrganization?: Organization;
    /**
     * The organizations that belong to this organization.
     * @type {Array<Organization>}
     * @memberof Organization
     */
    subOrganizations?: Array<Organization>;
    /**
     * The organizations that are affiliated partners to this organization, but not proper suborganizations.
     * @type {Array<Organization>}
     * @memberof Organization
     */
    affiliatedOrganizations?: Array<Organization>;
}

export function OrganizationFromJSON(json: any): Organization {
    return OrganizationFromJSONTyped(json, false);
}

export function OrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'originId': !exists(json, 'origin_id') ? undefined : json['origin_id'],
        'createdTime': !exists(json, 'created_time') ? undefined : (new Date(json['created_time'])),
        'lastModifiedTime': !exists(json, 'last_modified_time') ? undefined : (new Date(json['last_modified_time'])),
        'dataSource': !exists(json, 'data_source') ? undefined : json['data_source'],
        'classification': !exists(json, 'classification') ? undefined : json['classification'],
        'foundingDate': !exists(json, 'founding_date') ? undefined : (new Date(json['founding_date'])),
        'dissolutionDate': !exists(json, 'dissolution_date') ? undefined : (new Date(json['dissolution_date'])),
        'isAffiliated': !exists(json, 'is_affiliated') ? undefined : json['is_affiliated'],
        'replacedBy': !exists(json, 'replaced_by') ? undefined : OrganizationFromJSON(json['replaced_by']),
        'hasRegularUsers': !exists(json, 'has_regular_users') ? undefined : json['has_regular_users'],
        'parentOrganization': !exists(json, 'parent_organization') ? undefined : OrganizationFromJSON(json['parent_organization']),
        'subOrganizations': !exists(json, 'sub_organizations') ? undefined : ((json['sub_organizations'] as Array<any>).map(OrganizationFromJSON)),
        'affiliatedOrganizations': !exists(json, 'affiliated_organizations') ? undefined : ((json['affiliated_organizations'] as Array<any>).map(OrganizationFromJSON)),
    };
}

export function OrganizationToJSON(value?: Organization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'origin_id': value.originId,
        'created_time': value.createdTime === undefined ? undefined : (value.createdTime.toISOString()),
        'last_modified_time': value.lastModifiedTime === undefined ? undefined : (value.lastModifiedTime.toISOString()),
        'data_source': value.dataSource,
        'classification': value.classification,
        'founding_date': value.foundingDate === undefined ? undefined : (value.foundingDate.toISOString()),
        'dissolution_date': value.dissolutionDate === undefined ? undefined : (value.dissolutionDate.toISOString()),
        'is_affiliated': value.isAffiliated,
        'replaced_by': OrganizationToJSON(value.replacedBy),
        'has_regular_users': value.hasRegularUsers,
        'parent_organization': OrganizationToJSON(value.parentOrganization),
        'sub_organizations': value.subOrganizations === undefined ? undefined : ((value.subOrganizations as Array<any>).map(OrganizationToJSON)),
        'affiliated_organizations': value.affiliatedOrganizations === undefined ? undefined : ((value.affiliatedOrganizations as Array<any>).map(OrganizationToJSON)),
    };
}


