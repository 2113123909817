import * as React from "react";

import { Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { SnackbarMessage } from "../layouts/app-layout";

/**
 * Interface representing component properties
 */
interface Props {
  snackbarMessage?: SnackbarMessage;
  clearSnackbar?: () => void;
}

/**
 * Interface representing component state
 */
interface State {

}

/**
 * React component displaying confirm dialogs
 */
export default class GenericSnackbar extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props component properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render method
   */
  public render() {
    const { snackbarMessage, clearSnackbar } = this.props;

    return (
      <Snackbar open={ !!snackbarMessage } autoHideDuration={ 3000 } onClose={ clearSnackbar }  anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <MuiAlert elevation={ 12 } variant="filled" onClose={ clearSnackbar } severity={ snackbarMessage?.severity }>
          { snackbarMessage?.message || "" }
        </MuiAlert>
      </Snackbar>
    );
  }
}