/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OfferDescription,
    OfferDescriptionFromJSON,
    OfferDescriptionFromJSONTyped,
    OfferDescriptionToJSON,
    OfferInfoUrl,
    OfferInfoUrlFromJSON,
    OfferInfoUrlFromJSONTyped,
    OfferInfoUrlToJSON,
    OfferPrice,
    OfferPriceFromJSON,
    OfferPriceFromJSONTyped,
    OfferPriceToJSON,
} from './';

/**
 * information record for this event. The prices are not in a structured format and the format depends on information source. An exception to this is the case of free event. These are indicated using is_free flag, which is searchable.
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {OfferPrice}
     * @memberof Offer
     */
    price?: OfferPrice;
    /**
     * 
     * @type {OfferInfoUrl}
     * @memberof Offer
     */
    infoUrl?: OfferInfoUrl;
    /**
     * 
     * @type {OfferDescription}
     * @memberof Offer
     */
    description?: OfferDescription;
    /**
     * Whether the event is of free admission
     * @type {boolean}
     * @memberof Offer
     */
    isFree?: boolean;
}

export function OfferFromJSON(json: any): Offer {
    return OfferFromJSONTyped(json, false);
}

export function OfferFromJSONTyped(json: any, ignoreDiscriminator: boolean): Offer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'price': !exists(json, 'price') ? undefined : OfferPriceFromJSON(json['price']),
        'infoUrl': !exists(json, 'info_url') ? undefined : OfferInfoUrlFromJSON(json['info_url']),
        'description': !exists(json, 'description') ? undefined : OfferDescriptionFromJSON(json['description']),
        'isFree': !exists(json, 'is_free') ? undefined : json['is_free'],
    };
}

export function OfferToJSON(value?: Offer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'price': OfferPriceToJSON(value.price),
        'info_url': OfferInfoUrlToJSON(value.infoUrl),
        'description': OfferDescriptionToJSON(value.description),
        'is_free': value.isFree,
    };
}


