import * as React from "react";
import { Event } from "../../generated/client/models/Event";
import { withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/generic/google-map-marker";
import { Coords } from "google-map-react";

/**
 * Component props
 */
interface GoogleMapMarkerProps extends WithStyles<typeof styles> {
  events: Event[];
  lat: number;
  lng: number;
  anchorEl: any;
  onMarkerClick(anchorEl: any, events: Event[], center: Coords): void;
}

/**
 * Creates google map marker
 * @param props
 */
export const GoogleMapMarker = (props: GoogleMapMarkerProps) => {
  const { classes, lat, lng } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | undefined | HTMLElement>(null);

  /**
   * Handles click event on map marker. Opens popper for spesific event
   * @param event
   */
  const onMarkerClick = (event: any) => {
    props.onMarkerClick(event.currentTarget, props.events, { lat, lng } as Coords);
    setAnchorEl(event.currentTarget);
  };

  const open: boolean = Boolean(props.anchorEl);

  return (
    <div className={ classes.pinContainer }>
      <div className={ `${ classes.pin } ${ open && anchorEl === props.anchorEl ? "open" : "" }` } onClick={ onMarkerClick } />
      <div className={ open && anchorEl === props.anchorEl ? classes.pulse : undefined } />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(GoogleMapMarker);
