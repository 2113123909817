import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Typography, withStyles, WithStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { History } from "history";
import * as React from "react";
import { Link } from "react-router-dom";
import { Event } from "../../generated/client";
import strings from "../../localization/strings";
import styles from "../../styles/event/event-lister";
import { CustomStyles } from "../../types";
import EventUtils from "../../utils/event-utils";

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  events: Event[];
  loading: boolean;
  disabled: boolean;
  showEditButtons: boolean;
  title: string | JSX.Element;
  description?: string;
  customStyles?: CustomStyles;
  history: History<History.LocationState>;
  loadMoreButtonText?: string | JSX.Element;
  editButtonText?: string;
  copyButtonText?: string;
  deleteButtonText?: string;
  loadMoreEvents?: () => void;
  onDeleteClick?: (event: Event) => void;
  onEditClick?: (event: Event) => void;
  onCopyClick?: (event: Event) => void;
}

/**
 * Interface describing component state
 */
interface State { }

/**
 * Component for event lister
 *
 * @param props component props
 */
class EventLister extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles, title, loading, description } = this.props;

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        <Typography
          variant={ "h2" }
          className={ classes.title }
          style={ customStyles?.title }
        >
          { title }
        </Typography>
        { description && (
          <Typography
            variant={ "body2" }
            style={{ marginBottom: 20 }}
          >
            { description }
          </Typography>
        )}
        <div
          className={ classes.grid }
          style={ customStyles?.grid }
        >
          { this.renderEventCards() }
        </div>
        { loading ? (
            <div
              className={ classes.container }
              style={ customStyles?.container }
            >
              <div className={ classes.grid }>
                <Card className={ classes.loaderCard }>
                  <Box className={ classes.loaderPlaceholder } />
                </Card>
                <Card className={ classes.loaderCard }>
                  <Box className={ classes.loaderPlaceholder } />
                </Card>
                <Card className={ classes.loaderCard }>
                  <Box className={ classes.loaderPlaceholder } />
                </Card>
                <Card className={ classes.loaderCard }>
                  <Box className={ classes.loaderPlaceholder } />
                </Card>
              </div>
            </div>
          ) : ( this.renderLoadMoreButton() )
        }
      </div>
    );
  }

  /**
   * Method for rendering event cards
   */
  private renderEventCards = () => {
    const { customStyles, classes, events } = this.props;

    return events.map((event: Event, index: number) => {
      const id = EventUtils.getEventId(event);
      const eventName = EventUtils.getEventName(event);
      const eventImageSrc = EventUtils.getEventImageSrc(event);
      const eventDuration = EventUtils.getEventDuration(event);
      const eventProviderName = EventUtils.getEventProviderName(event);

      return (
        <div className={ classes.cardWrapper } key={ index }>
          <Link
            key={ event.id }
            to={ `/event/${ id }` }
            component={ CardActionArea }
          >
            <Card
              key={ index }
              className={ classes.card }
              style={ customStyles?.card }
            >
              <CardMedia
                alt={ strings.generic.imageAltText }
                component="img"
                image={ eventImageSrc }
                className={ classes.media }
              />
              <CardContent>
                <div>
                  <Typography
                    gutterBottom
                    variant="caption"
                    className={ classes.eventTime }
                  >
                    { eventDuration }
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    className={ classes.eventTitle }
                  >
                    { eventName }
                  </Typography>
                </div>
                <Typography
                  gutterBottom
                  variant="caption"
                  className={ classes.eventInfo }
                >
                  { eventProviderName }
                </Typography>
              </CardContent>              
            </Card>
          </Link>
            { this.renderEventCardEditButtons(event) }
        </div>
      );
    });
  }

  /**
   * Renders event card edit buttons
   */
  private renderEventCardEditButtons = (event: Event) => {
    const { showEditButtons } = this.props;

    if (!showEditButtons) {
      return null;
    }

    return (
      <Box display="flex" justifyContent="space-between" p={ 2 }>
        { this.renderEventCardCopyButton(event) }
        { this.renderEventCardEditButton(event) }
        { this.renderEventCardDeleteButton(event) }
      </Box>
    );

  }

  /**
   * Renders event card copy button
   */
  private renderEventCardCopyButton = (event: Event) => {
    const { onCopyClick, copyButtonText } = this.props;

    if (!onCopyClick || !copyButtonText) {
      return null;
    }

    return (
      <Button onClick={ () => onCopyClick(event) }>
        { copyButtonText }
      </Button>
    );
  }

  /**
   * Renders event card edit button
   */
  private renderEventCardEditButton = (event: Event) => {
    const { onEditClick, editButtonText } = this.props;

    if (!onEditClick || !editButtonText) {
      return null;
    }

    return (
      <Button onClick={ () => onEditClick(event) }>
        { editButtonText }
      </Button>
    );
  }

  /**
   * Renders event card delete button
   */
  private renderEventCardDeleteButton = (event: Event) => {
    const { onDeleteClick, deleteButtonText } = this.props;

    if (!onDeleteClick || !deleteButtonText) {
      return null;
    }

    return (
      <Button onClick={ () => onDeleteClick(event) }>
        { deleteButtonText }
      </Button>
    );
  }

  /**
   * Method for rendering load button
   */
  private renderLoadMoreButton = () => {
    const { classes, customStyles, loadMoreEvents, disabled, loadMoreButtonText, loading } = this.props;

    if (loading || !loadMoreButtonText) {
      return null;
    }

    return (
      <div
        className={ classes.actions }
        style={ customStyles?.actions }
      >
        <Button
          color="primary"
          onClick={ loadMoreEvents }
          endIcon={ <AddIcon /> }
          disabled={ disabled }
        >
          { loadMoreButtonText }
        </Button>
      </div>
    );
  }

}

const Styled = withStyles(styles)(EventLister);

export default Styled;
