import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  previewContainer: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },

  preview: {
    width: "100%",
    height: 247
  },

  croppedPreview: {
    maxWidth: "100%",
    height: "auto"
  },

});