import * as React from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../../store";

import { Place } from "../../../generated/client";
import styles from "../../../styles/generic/coordinate-picker";
import { NullableToken, CustomStyles } from "../../../types";
import GoogleMapReact, { MapOptions } from "google-map-react";
import { Box, withStyles, WithStyles } from "@material-ui/core";
import SingleEventMapMarker from "../../generic/single-event-google-map-marker";

/**
 * Component properties
 */
interface Props extends WithStyles<typeof styles> {
  place: Place;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  onUpdateLocation: (place: Place) => void;
}

/**
 * Component state
 */
interface State {
  google?: GoogleApi;
  maps?: typeof google.maps;
  geocoder?: google.maps.Geocoder;
  autocomplete?: google.maps.places.Autocomplete;
}

interface GoogleApi {
  map: google.maps.Map;
  maps: typeof google.maps;
}

/**
 * Coordinate picker Google map component
 */
class CoordinatePicker extends React.Component<Props, State> {

  /**
   * Reference to autocomplete input element
   */
  private autocompleteInput = React.createRef<HTMLInputElement>();

  /**
   * Component constructor
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render
   */
  public render = () => {
    const { customStyles, classes } = this.props;

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        { this.renderCoordinatePicker() }
      </div>
    );
  }

  /**
   * Component did update life cycle method
   *
   * @param prevProps previous component properties
   */
  public componentDidUpdate = (prevProps: Props) => {
    const { place } = this.props;

    const autocompleteInput = this.autocompleteInput.current;

    if (!autocompleteInput) {
      return;
    }

    const prevCoordinates = prevProps.place.position.coordinates;
    const currentCoordinates = place.position.coordinates;

    if (!prevCoordinates || !currentCoordinates) {
      return;
    }

    if (currentCoordinates[0] === prevCoordinates[0] && currentCoordinates[1] === prevCoordinates[1]) {
      return;
    }

    const { streetAddress, postalCode, addressRegion, addressCountry } = place;
    const addressValues = [ streetAddress?.fi, postalCode, addressRegion, addressCountry ];

    autocompleteInput.value = addressValues.reduce<string>((address, value) => {
      if (!value?.trim()) return address;
      return address ? `${address}, ${value}` : value;
    }, "");;
  }

  /**
   * Renders coordinate picker
   */
  private renderCoordinatePicker = () => {
    const { classes, place } = this.props;
    const coordinates = place.position.coordinates;
    const lat = coordinates && coordinates.length ? coordinates[0] : undefined;
    const lng = coordinates && coordinates.length > 1 ? coordinates[1] : undefined;

    const mapOptions: MapOptions = {
      scrollwheel: true,
      mapTypeControl: true,
      disableDoubleClickZoom: true
    };

    return (
      <>
        <Box p={ 2 } bgcolor="#fff">
          <input className={ classes.input } ref={ this.autocompleteInput }/>
        </Box>
        <Box height={ 300 }>
          <GoogleMapReact
            draggable
            defaultZoom={ 13 }
            options={ mapOptions }
            onClick={ this.onClickMap }
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={ this.onGoogleApiLoaded }
            defaultCenter={{ lat: 62.7880085, lng: 22.8492787 }}
            center={{ lat: lat ?? 62.7880085, lng: lng ?? 22.8492787 }}
            >
            { lat && lng &&
              <SingleEventMapMarker lat={ lat } lng={ lng }/>
            }
          </GoogleMapReact>
        </Box>
      </>
    )};

  /**
   * Method for using google maps object
   *
   * @param google google object
   */
  private onGoogleApiLoaded = (google: GoogleApi) => {
    if (!google) {
      return;
    }
    const autocompleteRef = this.autocompleteInput;

    if (!autocompleteRef) {
      return;
    }

    const autocompleteElement = autocompleteRef.current;

    if (!autocompleteElement) {
      return;
    }

    try {
      const autocomplete = new google.maps.places.Autocomplete(autocompleteElement);
      autocomplete.setFields(["place_id"]);
      autocomplete.addListener("place_changed", this.autocompleteSetPlace);

      const geocoder = new google.maps.Geocoder();
      this.setState({ autocomplete, geocoder, google });
    } catch(error) {
      console.log(error)
    }
  }

  /**
   * Method for handling clicks on map
   *
   * @param value click event object
   */
  private onClickMap = async (value: GoogleMapReact.ClickEventValue) => {
    const { google } = this.state;
    const { lat, lng } = value;

    if (!google) {
      return;
    }

    const latLng = new google.maps.LatLng(lat, lng);
    const result = await this.getGeocoderResultByLocation(latLng);

    this.updateNewPlace(result);
  }

  /**
   * Method for setting autocomplete place
   */
  private autocompleteSetPlace = async () => {
    const { autocomplete } = this.state;

    if (!autocomplete) {
      return;
    }

    const addressObject = autocomplete.getPlace();
    const placeId = addressObject.place_id;

    if (!placeId) {
      return;
    }

    const result = await this.getGeocoderResultByPlaceId(placeId);

    this.updateNewPlace(result);
  }

  /**
   * Method for updating new place location
   *
   * @param geocodeResult geocode result
   */
  private updateNewPlace = async (geocodeResult: google.maps.GeocoderResult) => {
    try {
      const { place, onUpdateLocation } = this.props;
      const addressComponents = geocodeResult.address_components;
      const locality = addressComponents.find(component =>
        component.types.includes("locality") ||
        component.types.includes("political")
      );
      const shortName = locality?.short_name;
      const country = addressComponents.find(component => component.types.includes("country"));
      const addressCountry = country?.short_name || "";
      const postalCodeField = addressComponents.find(component => component.types.includes("postal_code"));
      const postalCode = postalCodeField?.short_name || "";
      const coordinates = [ geocodeResult.geometry.location.lat(), geocodeResult.geometry.location.lng() ];
      const routeField = addressComponents.find(component => component.types.includes("route"));
      const route = routeField?.short_name || "";
      const streetNumberField = addressComponents.find(component => component.types.includes("street_number"));
      const streetNumber = streetNumberField?.short_name || "";
      const streetAddress = `${ route } ${ streetNumber }`;

      const updatedPlace: Place = {
        ...place,
        addressLocality: {
          fi: shortName,
          sv: shortName,
          en: shortName
        },
        streetAddress: {
          fi: streetAddress,
          sv: streetAddress,
          en: streetAddress
        },
        position: {
          ...place.position,
          coordinates: coordinates
        },
        postalCode: postalCode,
        addressRegion: shortName,
        addressCountry: addressCountry
      };

      onUpdateLocation(updatedPlace);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Method for getting geocoder result
   *
   * @param placeId place id
   * @returns promise of Google Maps Geocoder result
   */
  private getGeocoderResultByPlaceId = async (placeId: string): Promise<google.maps.GeocoderResult> => {
    return new Promise((resolve, reject) => {
      const { geocoder } = this.state;

      if (!geocoder) {
        return reject();
      }

      geocoder.geocode({ placeId: placeId,  },
        (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
          if (status === "OK" && results.length) {
            resolve(results[0]);
          }
        }
      );
    });
  }

  /**
   * Method for getting geocoder result
   *
   * @param latLng google maps coordinates object
   * @returns promise of Google Maps Geocoder result
   */
  private getGeocoderResultByLocation = async (latLng: google.maps.LatLng): Promise<google.maps.GeocoderResult> => {
    return new Promise((resolve, reject) => {
      const { geocoder } = this.state;

      if (!geocoder) {
        return reject();
      }

      geocoder.geocode({ location: latLng },
        (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
          if (status === "OK" && results.length) {
            resolve(results[0]);
          }
        }
      );
    });
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(CoordinatePicker);
const Connected = connect(mapStateToProps, mapDispatchToProps)(Styled);

export default Connected;