import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../store";
import { Button, InputAdornment, TextField, withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/event/event-search-form";
import { NullableToken, CustomStyles, Filters, PlaceByLocality, OpenPage } from "../../types";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ReactComponent as CalendarIcon } from "../../resources/svg/kalenteri-icon.svg";
import { Keyword } from "../../generated/client";
import strings from "../../localization/strings";
import SearchDropDownPlaces from "../generic/search-dropdown-places"
import Api from "../../api/api";
import SearchIcon from "@material-ui/icons/Search";
import SearchDropdownGeneric from "../generic/search-dropdown-generic";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CloseIcon from "../../resources/svg-paths/closeIcon";
import ListIcon from "@material-ui/icons/List";
import MapIcon from "../../resources/svg-paths/mapIcon";
import moment from "moment";

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  onFiltersChange: (filters: Filters) => void;
  filters: Filters;
  onListButtonClick(): void;
  onMapButtonClick(): void;
  pageOpen: OpenPage;
}

/**
 * Interface describing component state
 */
interface State {
  categories: Keyword[];
  places: PlaceByLocality[];
  audiences: Keyword[];
  loading: boolean;
  freeSearchValue: string;
  timer: any;
}

/**
 * Component for event search form
 *
 * @param props component props
 */
class EventSearchForm extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      categories: [],
      places: this.initializePlaceList(),
      audiences: [],
      loading: false,
      freeSearchValue: "",
      timer: null
    };
  }

  /**
   * Component did mount life-cycle method
   */
  public componentDidMount = async () => {
    const { filters } = this.props;

    this.setState({ loading: true });

    const keywordSets = await this.loadFilterKeywordSets();

    if (!keywordSets) {
      return;
    }

    const audiences: Keyword[] = (keywordSets.find(keywordSet => keywordSet.id === "audience")?.keywords ?? [])
      .filter(keyword => !keyword.deprecated);

    const categories: Keyword[] = (keywordSets.find(keywordSet => keywordSet.id === "category")?.keywords ?? [])
      .filter(keyword => !keyword.deprecated);

    this.setState({
      audiences: audiences,
      categories: categories,
      freeSearchValue: filters.text || "",
      loading: false
    });
  };

  /**
   * Component did update lice cycle method
   */
  public componentDidUpdate = (prevProps: Props) => {
    if (prevProps.filters.text !== this.props.filters.text) {
      this.setState({
        freeSearchValue: this.props.filters.text || ""
      });
    }
  };

  /**
   * Component render
   */
  public render = () => {
    const { loading, categories, places, audiences, freeSearchValue } = this.state;
    const { dateStart, dateEnd, selectedAudienceIds, selectedCategoryIds, selectedPlaceIds } = this.props.filters;
    const { classes, customStyles } = this.props;

    const selectedCategories = categories.filter(category => !!selectedCategoryIds.find(categoryId => categoryId === category.id));
    const selectedAudiences = audiences.filter(audience => !!selectedAudienceIds.find(audienceId => audienceId === audience.id));
    const selectedPlaces = places.filter(place => !!selectedPlaceIds.find(placeId => placeId === place.id));

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        <div
          className={ classes.filters }
          style={ customStyles?.filters }
        >
          <TextField
            title={ strings.event.filters.freeSearchExample }
            variant="outlined"
            color="secondary"
            className={ classes.searchBar }
            onChange={ this.onFreeSearchChange }
            onKeyPress={ this.onFreeSearchKeyPress }
            placeholder={ strings.event.filters.freeSearch }
            value={ freeSearchValue || "" }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="primary"/>
                </InputAdornment>
              )
            }}
          />
          <div className={ classes.datePickersContainer }>
            <MuiPickersUtilsProvider
              utils={ MomentUtils }
              locale={ strings.getLanguage() }
              libInstance={ moment }
            >
              <KeyboardDatePicker
                todayLabel={ strings.timepicker.today }
                clearLabel={ strings.timepicker.clear }
                cancelLabel={ strings.timepicker.cancel }
                className={ classes.datePicker }
                style={ customStyles?.datePicker }
                placeholder={ strings.event.filters.startDate }
                keyboardIcon={ <CalendarIcon /> }
                value={ dateStart }
                disablePast
                onChange={ this.onStartDateChange }
                showTodayButton
                />
              <KeyboardDatePicker
                todayLabel={ strings.timepicker.today}
                clearLabel={ strings.timepicker.clear }
                cancelLabel={ strings.timepicker.cancel }
                className={ classes.datePicker }
                style={ customStyles?.datePicker }
                placeholder={ strings.event.filters.endDate }
                keyboardIcon={ <CalendarIcon /> }
                value={ dateEnd ? dateEnd : null }
                onChange={ this.onEndDateChange }
                showTodayButton
                />
            </MuiPickersUtilsProvider>
          </div>
          <div
            className={ classes.keywordPickers }
            style={ customStyles?.keywordPickers }
          >
            <SearchDropdownGeneric
              buttonBackgroundColor="#755ab5"
              buttonTextColor="#fff"
              label={ strings.event.filters.what }
              disabled={ loading }
              keywords={ categories }
              onFilterListChange={ this.onCategoryFilterListChange }
              selectedIds={ selectedCategoryIds}
            />
            <SearchDropDownPlaces
              buttonBackgroundColor="#3d8ebf"
              buttonTextColor="#fff"
              disabled={ loading }
              label={ strings.event.filters.where }
              places={ places }
              onFilterListChange={ this.onPlaceFilterListChange }
              selectedIds={ selectedPlaceIds }
            />
            <SearchDropdownGeneric
              buttonBackgroundColor="#244f5f"
              buttonTextColor="#fff"
              isTypeWhom
              label={ strings.event.filters.whom }
              disabled={ loading }
              keywords={ audiences }
              onFilterListChange={ this.onAudienceFilterListChange }
              selectedIds={ selectedAudienceIds }
            />
          </div>
        </div>
        <div className={ classes.filterBottomSection }>
          <div className={ classes.selectedFiltersContainer }>
            { this.renderSelectedCategories(selectedCategories) }
            { this.renderSelectedPlaces(selectedPlaces) }
            { this.renderSelectedAudiences(selectedAudiences) }
          </div>
          { this.renderListToMapNavigation() }
        </div>
      </div>
    );
  }

  /**
   * Renders list to map navigation
   */
  private renderListToMapNavigation = () => {
    const { classes, onListButtonClick, onMapButtonClick, pageOpen } = this.props;
    return (
      <div className={ classes.viewNavigation }>
        { pageOpen === "googlemaps" &&
          <Button
            title={ strings.homeScreen.showEventsOnListHelp }
            className={ classes.pageNavButton }
            variant="text"
            onClick={ onListButtonClick }
            startIcon={ <ListIcon htmlColor="#eca725" /> }
            size="large"
          >
            { strings.homeScreen.showEventsOnList }
          </Button>
        }
        { pageOpen === "eventlist" &&
          <Button
            title={ strings.homeScreen.showEventsOnMapHelp }
            className={ classes.pageNavButton }
            variant="text"
            onClick={ onMapButtonClick }
            startIcon={ <MapIcon htmlColor="#eca725" /> }
            size="large"
          >
            { strings.homeScreen.showEventsOnMap }
          </Button>
        }
      </div>
    );
  };

  /**
   * Renders selected categories
   *
   * @param selectedCategories list of selected categories
   */
  private renderSelectedCategories = (selectedCategories: Keyword[]) => {
    const { classes } = this.props;
    return selectedCategories.map((category, index) =>
      <Button
        key={ index }
        startIcon={ <CloseIcon htmlColor="#755ab5" /> }
        className={ `${ classes.button } ${ classes.selectedCategoryButton }` }
        onClick={ () => this.onCategoryFilterListChange(category.id, false) }
      >
        {/** TODO: Localize when localization with Redux is implemented */}
        { category.name.fi }
      </Button>
    );
  }

  /**
   * Renders selected places
   *
   * @param selectedPlaces list of selected places
   */
  private renderSelectedPlaces = (selectedPlaces: PlaceByLocality[]) => {
    const { classes } = this.props;
    return selectedPlaces.map((place, index) =>
      <Button
        key={ index }
        startIcon={ <CloseIcon htmlColor="#3d8ebf" /> }
        className={ `${ classes.button } ${ classes.selectedPlaceButton }` }
        onClick={ () => this.onPlaceFilterListChange(place.id, false) }
      >
        { place.address_locality_fi }
      </Button>
    );
  }

  /**
   * Renders selected audiences
   *
   * @param selectedAudiences list of selected audiences
   */
  private renderSelectedAudiences = (selectedAudiences: Keyword[]) => {
    const { classes } = this.props;
    return selectedAudiences.map((audience, index) =>
      <Button
        key={ index }
        startIcon={ <CloseIcon htmlColor="#244f5f" /> }
        className={ `${ classes.button } ${ classes.selectedAudienceButton }` }
        onClick={ () => this.onAudienceFilterListChange(audience.id, false) }
      >
        { audience.name.fi }
      </Button>
    );
  }

  /**
   * Initiliazes hard coded place list with ids
   */
  private initializePlaceList = () => {

    /**
     * Hard coded list of places
     */
    const placesList = [
      "Alajärvi",
      "Alavus",
      "Evijärvi",
      "Ilmajoki",
      "Isojoki",
      "Isokyrö",
      "Karijoki",
      "Kauhajoki",
      "Kauhava",
      "Kuortane",
      "Kurikka",
      "Lappajärvi",
      "Lapua",
      "Seinäjoki",
      "Soini",
      "Teuva",
      "Vimpeli",
      "Ähtäri"
    ];

    return placesList.map(place => ({ id: place, address_locality_fi: place }));
  };

  /**
   * Loads keyword sets
   *
   * @returns data object containing Keyword sets with parsed ids
   */
  private loadFilterKeywordSets = async () => {
    const { accessToken } = this.props;

    const keywordResponse = await Api.getFilterApi(accessToken).keywordSetList({ include: [ "keywords" ] });
    const keywordSetLists = keywordResponse?.data || [];

    keywordSetLists.forEach((keywordSetList, keywordSetIndex) => {
      keywordSetList.keywords.forEach((keyword, KeywordsIndex) => {
        if(keyword.id.charAt(keyword.id.length-1) === "/") {
          const sliced = keyword.id.slice(0, keyword.id.length - 1);
          keywordSetLists[keywordSetIndex].keywords[KeywordsIndex].id = sliced.slice(sliced.lastIndexOf("/") + 1, sliced.length)
        }
      });
    });

    return keywordSetLists;
  };

  /**
   * Triggers onFiltersChange event
   */
  private triggerChange = () => {
    const { filters, onFiltersChange } = this.props;
    const { freeSearchValue } = this.state;

    onFiltersChange({ ...filters, text: freeSearchValue });
  };

  /**
   * Filters Category filter list
   */
  private onCategoryFilterListChange = (keywordId: string, selected: boolean) => {
    const { filters, onFiltersChange } = this.props;
    const { selectedCategoryIds } = filters;

    let filteredCategoryIds = [...selectedCategoryIds];

    if (selected) {
      filteredCategoryIds.push(keywordId);
    } else {
      filteredCategoryIds = filteredCategoryIds.filter(categoryId => categoryId !== keywordId);
    }

    const newFilters: Filters = {
      ...filters,
      selectedCategoryIds: filteredCategoryIds
      };

    onFiltersChange(newFilters);
  };

  /**
   * Filters Place filter list
   */
  private onPlaceFilterListChange = (placeId: string, selected: boolean) => {
    const { filters, onFiltersChange } = this.props;
    const { selectedPlaceIds } = filters;

    let filteredPlaceIds = [...selectedPlaceIds];
    if (selected) {
      filteredPlaceIds.push(placeId);
    } else {
      filteredPlaceIds = filteredPlaceIds.filter(id => id !== placeId);
    }

    const newFilters: Filters = {
      ...filters,
      selectedPlaceIds: filteredPlaceIds
    };

    onFiltersChange(newFilters);
  };

  /**
   * Filters audience filter list
   *
   * @param keywordId key word ID
   * @param selected selected
   */
  private onAudienceFilterListChange = (keywordId: string, selected: boolean) => {
    const { filters, onFiltersChange } = this.props;
    const { selectedAudienceIds } = filters;

    let filteredAudienceIds = [ ...selectedAudienceIds ];

    if (selected) {
      filteredAudienceIds.push(keywordId);
    } else {
      filteredAudienceIds = filteredAudienceIds.filter(audienceId => audienceId !== keywordId);
    }

    onFiltersChange({ ...filters, selectedAudienceIds: filteredAudienceIds });
  };

  /**
   * Handles date start changes
   *
   * @param date moment date object
   */
  private onStartDateChange = (date: MaterialUiPickersDate) => {
    const { filters, onFiltersChange } = this.props;

    if (!date) {
      return;
    }

    onFiltersChange({ ...filters, dateStart: date.startOf("day").toDate() });
  };

  /**
   * Handles date end changes
   *
   * @param date moment date object
   */
  private onEndDateChange = (date: MaterialUiPickersDate) => {
    const { filters, onFiltersChange } = this.props;

    if (!date) {
      return;
    }

    onFiltersChange({ ...filters, dateEnd: date.endOf("day").toDate() });
  };

  /**
   * On change event to watch when user types text
   * @param e react change event
   */
  private onFreeSearchChange = (e: React.ChangeEvent<any>) => {
    const WAIT_INTERVAL = 500;

    clearTimeout(this.state.timer);

    this.setState({
      freeSearchValue: e.currentTarget.value,
      timer: setTimeout(this.triggerChange, WAIT_INTERVAL)
    });
  };

  /**
   * On free search key press to listen if user press enter
   * @param e react keyboard event
   */
  private onFreeSearchKeyPress = (e: React.KeyboardEvent<Element>) => {
    const ENTER_KEY = 13;

    if (e.charCode === ENTER_KEY) {
      clearTimeout(this.state.timer);
      this.triggerChange();
    }
  };
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(EventSearchForm);
const Connected = connect(mapStateToProps, mapDispatchToProps)(Styled);

export default Connected;
