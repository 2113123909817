import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";
import heroImgPath from "../../resources/img/EP-tausta.jpg";

export default createStyles({

  container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    margin: "0 auto",
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
  },

  content: {
    flexGrow: 1
  },

  banner: {
    top: 0,
    left: 0,
    right: 0,
    zIndex: -1,
    width: "100%",
    height: 290,
    position: "absolute",
    backgroundImage: `url(${ heroImgPath })`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("sm")]: {
      height: 400
    },
    [theme.breakpoints.up("md")]: {
      height: 610
    },
    [theme.breakpoints.up(1140)]: {
      height: 558
    },
    [theme.breakpoints.up("lg")]: {
      height: 634
    },
    [theme.breakpoints.up(1315)]: {
      height: 574
    },
    [theme.breakpoints.up("xl")]: {
      height: 574
    }
  },


});