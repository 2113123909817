import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 1500,
    [theme.breakpoints.up("md")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "85vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "80vw",
    }
  },

  heading: {
    color: "#000",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      margin: "40px 0",
      alignSelf: "center",
    },
    [theme.breakpoints.up("md")]: {
      margin: "85px 0",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
      fontSize: "24px",
      marginTop: 0
    }
  },

  content: {
    background: theme.palette.background.default
  },

  card: { },

  media: { },

});