import * as React from "react";
import { Button, WithStyles, withStyles } from "@material-ui/core";
import styles from "../../styles/generic/search-list-item-styles"
import { PlaceByLocality } from "../../types";

/**
 * Component Props
 */
interface Props extends WithStyles<typeof styles> {
  selected: boolean;
  place: PlaceByLocality;
  onChange: (id: string, selectOn: boolean) => void;
}

/**
 * Creates list item for SearchList
 *
 * @param props
 */
const SearchListItemPlace = (props: Props) => {
  const { onChange, place, classes } = props;

  /**
   * Handles Click event for Paper component
   */
  const onClick = () => {
    onChange(place.address_locality_fi, !props.selected);
  };

  return (
    <Button className={ `${ classes.button } ${ classes.places }` } onClick={ onClick }>
      {`${ place.address_locality_fi }`}
    </Button>
  );
};

export default withStyles(styles, { withTheme: true })( SearchListItemPlace );