export const IMAGES = [
  {
    name: "Elokuva",
    url: "https://epkalenteri-user-content.s3.eu-central-1.amazonaws.com/event-images/defaultimages/Elokuva.jpg"
  },
  {
    name: "Koulutus",
    url: "https://epkalenteri-user-content.s3.eu-central-1.amazonaws.com/event-images/defaultimages/Koulutus.jpg"
  },
  {
    name: "Orkesteri",
    url: "https://epkalenteri-user-content.s3.eu-central-1.amazonaws.com/event-images/defaultimages/Orkesteri.jfif"
  },
  {
    name: "Retkeily",
    url: "https://epkalenteri-user-content.s3.eu-central-1.amazonaws.com/event-images/defaultimages/Retkeily.jpg"
  },
  {
    name: "Seminaari",
    url: "https://epkalenteri-user-content.s3.eu-central-1.amazonaws.com/event-images/defaultimages/Seminaari.jpg"
  },
  {
    name: "Taide",
    url: "https://epkalenteri-user-content.s3.eu-central-1.amazonaws.com/event-images/defaultimages/Taide.jfif"
  },
  {
    name: "Teatteri",
    url: "https://epkalenteri-user-content.s3.eu-central-1.amazonaws.com/event-images/defaultimages/Teatteri.jpg"
  },
  {
    name: "Liikunta",
    url: "https://epkalenteri-user-content.s3.eu-central-1.amazonaws.com/event-images/defaultimages/shutterstock_393880156.jpg"
  },
  {
    name: "Näyttely",
    url: "https://epkalenteri-user-content.s3.eu-central-1.amazonaws.com/event-images/defaultimages/shutterstock_648326134.jpg"
  },
  {
    name: "Lukeminen",
    url: "https://epkalenteri-user-content.s3.eu-central-1.amazonaws.com/event-images/defaultimages/shutterstock_735521536.jpg"
  }
];