import * as React from "react";
import { Button, Drawer, IconButton, Toolbar, Typography, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/generic/generic-drawer";
import CloseIcon from "@material-ui/icons/Close";
import strings from "../../localization/strings";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  title: string;
  open: boolean;
  onDeleteClick: () => void;
  onCancelClick: () => void;
  onSaveClick: () => void;
  onCloseClick: () => void;
  onToggleListStatus?: () => void;
  onClose: () => void;
  showListToggle?: boolean;
  toggleButtonText?: string;
}

/**
 *  Generic drawer component to display content
 * 
 * @param props component props
 */
class GenericDrawer extends React.Component<Props> {

  /**
   * Constructor
   *
   * @param props component properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render method
   */
  public render() {
    const {
      open,
      title,
      classes,
      onDeleteClick,
      onCancelClick,
      onCloseClick,
      onSaveClick,
      onClose,
      showListToggle,
      children
    } = this.props;

    return (
      <Drawer
        style={{ overflowY: "hidden" }}
        open={ open }
        ModalProps={{
          keepMounted: true
        }}
        onClose={ onClose }
      >
        <Toolbar className={ classes.drawerToolbar }>
          <Typography variant="h3">{ title }</Typography>
          <div className={ classes.buttonBar }>
            {
              showListToggle && this.renderToggleListButton() 
            }
            <Button
              variant="text"
              onClick={ () => { onDeleteClick() } }
            >
              { strings.admin.delete }
            </Button>
            <Button
              variant="outlined"
              onClick={ () => { onCancelClick() } }
            >
              { strings.admin.cancel }
            </Button>
            <Button
              variant="contained"
              onClick={ () => { onSaveClick() } }
            >
              { strings.admin.save }
            </Button>
            <IconButton onClick={ () => onCloseClick() }>
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
        <Toolbar />
        <div className={ classes.drawerContent }>
          { children }
        </div>
      </Drawer>
    );
  }

  /**
   * renders list toggle button
   */
  private renderToggleListButton = () => {
    const { onToggleListStatus, toggleButtonText } = this.props;

    if (!onToggleListStatus || !toggleButtonText) {
      return;
    }

    return (
      <Button
        variant="text"
        onClick={ () => { onToggleListStatus() } }
      >
        { toggleButtonText }
      </Button>
    )
  }
}

export default withStyles(styles)(GenericDrawer);
