/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Key value field for custom data. FIXME: is there 6Aika-wide use case for this?
 * @export
 * @interface PlaceCustomData
 */
export interface PlaceCustomData {
    /**
     * is place listed or not
     * @type {string}
     * @memberof PlaceCustomData
     */
    listed?: string;
}

export function PlaceCustomDataFromJSON(json: any): PlaceCustomData {
    return PlaceCustomDataFromJSONTyped(json, false);
}

export function PlaceCustomDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceCustomData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'listed': !exists(json, 'listed') ? undefined : json['listed'],
    };
}

export function PlaceCustomDataToJSON(value?: PlaceCustomData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'listed': value.listed,
    };
}


