/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Image,
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
    PlaceAddressLocality,
    PlaceAddressLocalityFromJSON,
    PlaceAddressLocalityFromJSONTyped,
    PlaceAddressLocalityToJSON,
    PlaceCustomData,
    PlaceCustomDataFromJSON,
    PlaceCustomDataFromJSONTyped,
    PlaceCustomDataToJSON,
    PlaceDescription,
    PlaceDescriptionFromJSON,
    PlaceDescriptionFromJSONTyped,
    PlaceDescriptionToJSON,
    PlaceInfoUrl,
    PlaceInfoUrlFromJSON,
    PlaceInfoUrlFromJSONTyped,
    PlaceInfoUrlToJSON,
    PlaceName,
    PlaceNameFromJSON,
    PlaceNameFromJSONTyped,
    PlaceNameToJSON,
    PlacePosition,
    PlacePositionFromJSON,
    PlacePositionFromJSONTyped,
    PlacePositionToJSON,
    PlaceStreetAddress,
    PlaceStreetAddressFromJSON,
    PlaceStreetAddressFromJSONTyped,
    PlaceStreetAddressToJSON,
    PlaceTelephone,
    PlaceTelephoneFromJSON,
    PlaceTelephoneFromJSONTyped,
    PlaceTelephoneToJSON,
} from './';

/**
 * Places describe physical locations for events and means for contacting people responsible for these locations. Place definitions come from organizations publishing events (field "publisher") and can thus have slightly different semantics between places sourced from different organizations.
 * @export
 * @interface Place
 */
export interface Place {
    /**
     * Consists of source prefix and source specific identifier. These should be URIs uniquely identifying the place, and preferably also well formed http-URLs pointing to more information about the place.
     * @type {string}
     * @memberof Place
     */
    id?: string;
    /**
     * 
     * @type {PlaceCustomData}
     * @memberof Place
     */
    customData?: PlaceCustomData;
    /**
     * 
     * @type {PlaceName}
     * @memberof Place
     */
    name: PlaceName;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Place
     */
    images?: Array<Image>;
    /**
     * Place identifier in the originating system. Same as id but without the data source prefix.
     * @type {string}
     * @memberof Place
     */
    originId?: string;
    /**
     * Creation time for the place entry.
     * @type {Date}
     * @memberof Place
     */
    createdTime?: Date;
    /**
     * Time this place was modified in the datastore behind the API (not necessarily in the originating system)
     * @type {Date}
     * @memberof Place
     */
    lastModifiedTime?: Date;
    /**
     * 
     * @type {PlaceInfoUrl}
     * @memberof Place
     */
    infoUrl?: PlaceInfoUrl;
    /**
     * 
     * @type {PlaceDescription}
     * @memberof Place
     */
    description?: PlaceDescription;
    /**
     * 
     * @type {PlacePosition}
     * @memberof Place
     */
    position: PlacePosition;
    /**
     * Contact email for the place, note that this is NOT multilingual
     * @type {string}
     * @memberof Place
     */
    email?: string;
    /**
     * 
     * @type {PlaceTelephone}
     * @memberof Place
     */
    telephone?: PlaceTelephone;
    /**
     * FIXME: this seems unused in Helsinki data. Does any 6Aika city have use for describing contact type?
     * @type {string}
     * @memberof Place
     */
    contactType?: string;
    /**
     * 
     * @type {PlaceStreetAddress}
     * @memberof Place
     */
    streetAddress?: PlaceStreetAddress;
    /**
     * 
     * @type {PlaceAddressLocality}
     * @memberof Place
     */
    addressLocality?: PlaceAddressLocality;
    /**
     * Larger region for address (like states), not typically used in Finland
     * @type {string}
     * @memberof Place
     */
    addressRegion?: string;
    /**
     * Postal code of the location (as used by traditional mail)
     * @type {string}
     * @memberof Place
     */
    postalCode?: string;
    /**
     * PO box for traditional mail, in case mail is not delivered to the building
     * @type {string}
     * @memberof Place
     */
    postOfficeBoxNum?: string;
    /**
     * Country for the place, NOT multilingual
     * @type {string}
     * @memberof Place
     */
    addressCountry?: string;
    /**
     * This place entry is not used anymore, but old events still reference it. This might be because of duplicate removal.
     * @type {boolean}
     * @memberof Place
     */
    deleted?: boolean;
    /**
     * Identifies the source for data, this is specific to API provider. This is useful for API users, as any data quality issues are likely to be specific to data source and workarounds can be applied as such.
     * @type {string}
     * @memberof Place
     */
    dataSource?: string;
    /**
     * Organization that provided the location data
     * @type {string}
     * @memberof Place
     */
    publisher?: string;
    /**
     * 
     * @type {Place}
     * @memberof Place
     */
    replacedBy?: Place;
}

export function PlaceFromJSON(json: any): Place {
    return PlaceFromJSONTyped(json, false);
}

export function PlaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Place {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, '@id') ? undefined : json['@id'],
        'customData': !exists(json, 'custom_data') ? undefined : PlaceCustomDataFromJSON(json['custom_data']),
        'name': PlaceNameFromJSON(json['name']),
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(ImageFromJSON)),
        'originId': !exists(json, 'origin_id') ? undefined : json['origin_id'],
        'createdTime': !exists(json, 'created_time') ? undefined : (new Date(json['created_time'])),
        'lastModifiedTime': !exists(json, 'last_modified_time') ? undefined : (new Date(json['last_modified_time'])),
        'infoUrl': !exists(json, 'info_url') ? undefined : PlaceInfoUrlFromJSON(json['info_url']),
        'description': !exists(json, 'description') ? undefined : PlaceDescriptionFromJSON(json['description']),
        'position': PlacePositionFromJSON(json['position']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'telephone': !exists(json, 'telephone') ? undefined : PlaceTelephoneFromJSON(json['telephone']),
        'contactType': !exists(json, 'contact_type') ? undefined : json['contact_type'],
        'streetAddress': !exists(json, 'street_address') ? undefined : PlaceStreetAddressFromJSON(json['street_address']),
        'addressLocality': !exists(json, 'address_locality') ? undefined : PlaceAddressLocalityFromJSON(json['address_locality']),
        'addressRegion': !exists(json, 'address_region') ? undefined : json['address_region'],
        'postalCode': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'postOfficeBoxNum': !exists(json, 'post_office_box_num') ? undefined : json['post_office_box_num'],
        'addressCountry': !exists(json, 'address_country') ? undefined : json['address_country'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'dataSource': !exists(json, 'data_source') ? undefined : json['data_source'],
        'publisher': !exists(json, 'publisher') ? undefined : json['publisher'],
        'replacedBy': !exists(json, 'replaced_by') ? undefined : PlaceFromJSON(json['replaced_by']),
    };
}

export function PlaceToJSON(value?: Place | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'custom_data': PlaceCustomDataToJSON(value.customData),
        'name': PlaceNameToJSON(value.name),
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(ImageToJSON)),
        'origin_id': value.originId,
        'created_time': value.createdTime === undefined ? undefined : (value.createdTime.toISOString()),
        'last_modified_time': value.lastModifiedTime === undefined ? undefined : (value.lastModifiedTime.toISOString()),
        'info_url': PlaceInfoUrlToJSON(value.infoUrl),
        'description': PlaceDescriptionToJSON(value.description),
        'position': PlacePositionToJSON(value.position),
        'email': value.email,
        'telephone': PlaceTelephoneToJSON(value.telephone),
        'contact_type': value.contactType,
        'street_address': PlaceStreetAddressToJSON(value.streetAddress),
        'address_locality': PlaceAddressLocalityToJSON(value.addressLocality),
        'address_region': value.addressRegion,
        'postal_code': value.postalCode,
        'post_office_box_num': value.postOfficeBoxNum,
        'address_country': value.addressCountry,
        'deleted': value.deleted,
        'data_source': value.dataSource,
        'publisher': value.publisher,
        'replaced_by': PlaceToJSON(value.replacedBy),
    };
}


