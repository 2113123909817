import * as React from "react";

import styles from "../../styles/generic/form-item";
import { Typography, withStyles, WithStyles } from "@material-ui/core";

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  title?: string | JSX.Element;
  showHelp?: boolean;
  helpContent?: string | JSX.Element | JSX.Element[];
}

/**
 * Interface describing component state
 */
interface State { }

/**
 * Form item component
 */
class FormItem extends React.Component<Props, State> {

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, children } = this.props;

    return (
      <div className={ classes.container }>
        <div className={ classes.content }>
        { this.renderTitle() }
        { children }
        </div>
        { this.renderHelpContent() }
      </div>
    );
  }

  /**
   * Method for rendering title
   */
  private renderTitle = () => {
    const { title, classes } = this.props;

    if (!title) {
      return null;
    }

    return (
      <Typography className={ classes.title } variant="h5">
        { title }
      </Typography>
    );
  }

  /**
   * Metthod for rendering help content
   */
  private renderHelpContent = () => {
    const { classes, helpContent, showHelp } = this.props;

    if (!helpContent || !showHelp) {
      return null;
    }

    return (
      <div className={ classes.helpContainer }>
        <div className={ classes.helpContent }>
          { helpContent }
        </div>
      </div>
    );
  }

}

const Styled = withStyles(styles)(FormItem);

export default Styled;