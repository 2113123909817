import { Theme, createStyles } from "@material-ui/core";

/**
 * Styles for google map marker component
 * @param theme
 */
const styles = (theme: Theme) =>
  createStyles({
    "@keyframes pulsate": {
      "0%": {
        transform: "translate3d(-50%, 0, 0) perspective(100px) scale(0)",
        opacity: 0
      },
      "50%": {
        opacity: 1
      },
      "100%": {
        transform: "translate3d(-50%, 0, 0) perspective(100px) scale(0.5)",
        opacity: 0
      }
    },

    "@keyframes bounce": {
      "0%": {
        opacity: 0,
        transform: "translateY(-200px) rotate(-45deg)"
      },
      "60&": {
        opacity: 1,
        transform: "translateY(30px) rotate(-45deg)"
      },
      "80%": {
        opacity: 1,
        transform: "translateY(-10px) rotate(-45deg)"
      },
      "100%": {
        transform: "translateY(0) rotate(-45deg)"
      }
    },

    pin: {
      position: "absolute",
      width: 30,
      height: 30,
      backgroundColor: "rgb(61, 142, 191)",
      border: "1px solid #000",
      borderRadius: "50% 50% 50% 0",
      userSelect: "none",
      transform: "rotate(-45deg)",
      animationName: "$bounce",
      animationFillMode: "both",
      animationDuration: "1s",
      transition: "background 0.2s ease-out",
      "&::after": {
        content: "''",
        width: 15,
        height: 15,
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        boxShadow: "inset 0px 0px 2px rgba(61, 142, 191, 0.5)",
        position: "absolute",
        borderRadius: "50%",
        top: "50%",
        left: "50%",
        transform: "translate3d(-50%, -50%, 0)",
        transition: "background 0.2s ease-out"
      },
      "&.open": {
        backgroundColor: "#fff",
        border: `1px solid ${theme.palette.primary.main}`,
        "&::after": {
          backgroundColor: "rgb(61, 142, 191)",
          boxShadow: "inset 0px 0px 2px rgba(61, 142, 191, 0.5)"
        }
      }
    },
    pulse: {
      borderRadius: "50%",
      height: 15,
      width: 15,
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translate3d(-50%, -50%, 0)",
      zIndex: -2,
      "&::after": {
        content: "''",
        borderRadius: "50%",
        height: 40,
        width: 40,
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate3d(-50%, 0, 0)  perspective(100px) scale(0)",
        animation: "$pulsate 1s ease-out ",
        animationIterationCount: "infinite",
        opacity: 0,
        boxShadow: `0 0 1px 2px ${theme.palette.primary.main}`,
        animationDelay: "0.5s"
      }
    },
    pinContainer: {
      position: "absolute",
      width: 30,
      height: 30,
      left: 0,
      top: 0,
      transform: "translate(-50%, -120%)",
      cursor: "pointer"
    },
    popper: {
      [theme.breakpoints.up("xs")]: {
        width: 300,
        maxHeight: 270
      },
      [theme.breakpoints.up("sm")]: {
        maxHeight: 450,
        width: 400
      }
    }
  });

export default styles;
