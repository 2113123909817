import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: { 
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: "#404040"
  },

  name: {
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 12
  },

  contactInfo: {
    fontSize: 12,
    letterSpacing: "0.5px",
    lineHeight: "22px",
    "& p": {
      fontWeight: 700,
      marginBottom: "1.5em",
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      marginInlineEnd: 0
    },
    "& .separator-horizontal": {
      fontWeight: 700,
      margin: "0 8px",
      letterSpacing: -2
    }
  },

  link: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.secondary.main,
    textTransform: "uppercase"
  }

});