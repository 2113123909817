import * as React from "react";

import { createStore } from "redux";
import { Provider } from "react-redux";
import { ReduxActions, ReduxState, rootReducer } from "../store";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import moment from "moment";
import "moment/locale/fi";
import * as immer from "immer";
import adminTheme from "../theme/admin-theme";
import strings from "../localization/strings";
import HomeScreen from "./screens/home-screen";
import InfoScreen from "./screens/info-screen";
import EventScreen from "./screens/event-screen";
import AdminScreen from "./screens/admin-screen";
import defaultTheme from "../theme/default-theme";
import CookieConsent from "./generic/cookie-consent";
import ProfileScreen from "./screens/profile-screen";
import AccessTokenRefresh from "./containers/access-token-refresh";
import CreateOrUpdateEventScreen from "./screens/create-or-update-event-screen";
import { responsiveFontSizes, ThemeProvider, CssBaseline, Theme } from "@material-ui/core";
import * as Sentry from "@sentry/react";

/**
 * Initialize Sentry Redux enhancer
 */
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

/**
 * Initialize Redux store
 */
const store = createStore<ReduxState, ReduxActions, any, any>(rootReducer, sentryReduxEnhancer);

/**
 * Interface describing component properties
 */
interface Props { }

/**
 * Interface describing component state
 */
interface State {
  theme: Theme;
  loading: boolean;
}

/**
 * App component
 */
class App extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      theme: defaultTheme
    };
  }

  /**
   * Component did mount life cycle method
   */
  public componentDidMount = async () => {
    moment.locale(strings.getLanguage());
    immer.enableAllPlugins();
    const themePath = "/theme";
    await import(`../dynamic-content${themePath}`)
      .then(themeModule => this.setState({ theme: themeModule.default }))
      .catch(e => console.log("Custom theme not found, using default theme", e));

    this.setState({ loading: false });
  }

  /**
   * Component render
   */
  public render = () => {
    const { loading, theme } = this.state;

    if (loading) {
      return null;
    }

    return (
      <ThemeProvider theme={ responsiveFontSizes(theme) }>
        <CssBaseline />
        <Provider store={ store }>
          <AccessTokenRefresh>
            <BrowserRouter>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={ ({ history, location }) => (
                    <HomeScreen
                      history={ history }
                      location={ location }
                    />
                  )}
                />
                <Route
                  exact
                  path="/add-event"
                  render={ ({ history, location }) => (
                    <CreateOrUpdateEventScreen
                      history={ history }
                      location={ location }
                    />
                  )}
                />
                <Route
                  exact
                  path="/edit-event/:eventId"
                  render={ ({ history, location, match }) => (
                    <CreateOrUpdateEventScreen
                      history={ history }
                      location={ location }
                      eventId={ match.params.eventId }
                    />
                  )}
                />
                <Route
                  exact
                  path="/event/:eventId"
                  render={ ({ history, location, match }) => (
                    <EventScreen
                      history={ history }
                      location={ location }
                      eventId={ match.params.eventId }
                    />
                  )}
                />
                <Route
                  exact
                  path="/admin"
                  render={ ({ history }) => (
                    <ThemeProvider theme={ responsiveFontSizes(adminTheme) }>
                      <AdminScreen history={ history }/>
                    </ThemeProvider>
                  )}
                />
                <Route
                  exact
                  path="/terms_of_use"
                  render={ () => <InfoScreen infoContentKey="termsOfUse"/> }
                />
                <Route
                  exact
                  path="/accessibility"
                  render={ () => <InfoScreen infoContentKey="accessibility"/> }
                />
                <Route
                  exact
                  path="/publisher_help"
                  render={ () => <InfoScreen infoContentKey="publisherHelp"/> }
                />
                <Route
                  exact
                  path="/profile"
                  render={ ({ history }) => <ProfileScreen history={ history }/> }
                />
                <Route>
                  <Redirect to="/"/>
                </Route>
              </Switch>
            </BrowserRouter>
            <CookieConsent />
          </AccessTokenRefresh>
        </Provider>
      </ThemeProvider>
    );
  }

}

export default App;
