import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      width: "30%",
    }
  },

  content: {
    background: theme.palette.background.paper,
    padding: theme.spacing(4),
    "& h3, h4": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      "&:first-child": {
        marginTop: 0
      }
    }
  },

  externalLinks: {
    background: theme.palette.background.default,
    padding: theme.spacing(2)
  },

  link: {
    fontSize: 25,
    fontWeight: 800,
    color: "#000000"
  }

});