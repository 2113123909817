import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    padding: theme.spacing(2),
    width: "100%",
    display: "flex",
    alignSelf: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3)
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4)
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(6)
    },
  },

  searchBar: {
    flex: 2,
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-root": {
      borderRadius: 0
    }
  },

  datePickersContainer: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      flex: 2,
      marginTop: 0,
      marginBottom: 0
    }
  },

  datePicker: {
    flex: 1,
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: "#fff",
    border: "1px solid #8dafbc",
    [theme.breakpoints.up("lg")]: {
      borderLeftWidth: 0
    },
    "& .MuiInput-root": {
      height: "100%",
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
      },
    },
    "& .MuiInput-underline:before": {
      borderWidth: 0
    }
  },

  filters: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  keywords: {
    display: "flex",
  },

  keywordPickers: {
    flex: 2,
    display: "flex"
  },

  filterBottomSection: {
    flex: "1 1 auto",
    paddingTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  selectedFiltersContainer: {
    flex: 4,
  },

  viewNavigation: {
    flex: 1,
    display: "flex",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end"
    }
  },

  pageNavButton: {
    "& .MuiButton-label": {
      fontSize: 12,
      color: "#244f5f",
      textTransform: "uppercase"
    }
  },

  button: {
    fontSize: 14,
    color: "#244f5f",
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },

  selectedCategoryButton: {
    borderColor: "#755ab5",
  },

  selectedPlaceButton: {
    borderColor: "#3d8ebf",
  },

  selectedAudienceButton: {
    borderColor: "#244f5f",
  }

});