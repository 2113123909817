import { createStyles } from "@material-ui/core";
import theme from "../../../../theme/admin-theme";

export const styles = createStyles({

  root: {
    background: theme.palette.background.default
  },

  container: {
    width: "100%"
  },

  tableHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  addButton: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2)
  }

});