/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2006,
    InlineResponse2006FromJSON,
    InlineResponse2006ToJSON,
    Language,
    LanguageFromJSON,
    LanguageToJSON,
} from '../models';

export interface LanguageRetrieveRequest {
    id: string;
}

/**
 * 
 */
export class LanguageApi extends runtime.BaseAPI {

    /**
     * The returned list describes languages used for describing events in this Linked events instance
     * Return a list of languages used for describing events
     */
    async languageListRaw(): Promise<runtime.ApiResponse<InlineResponse2006>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/language/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2006FromJSON(jsonValue));
    }

    /**
     * The returned list describes languages used for describing events in this Linked events instance
     * Return a list of languages used for describing events
     */
    async languageList(): Promise<InlineResponse2006> {
        const response = await this.languageListRaw();
        return await response.value();
    }

    /**
     * Can be used to retrieve translations for a single language
     * Return information for single language
     */
    async languageRetrieveRaw(requestParameters: LanguageRetrieveRequest): Promise<runtime.ApiResponse<Language>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling languageRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/language/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LanguageFromJSON(jsonValue));
    }

    /**
     * Can be used to retrieve translations for a single language
     * Return information for single language
     */
    async languageRetrieve(requestParameters: LanguageRetrieveRequest): Promise<Language> {
        const response = await this.languageRetrieveRaw(requestParameters);
        return await response.value();
    }

}
