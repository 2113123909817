import { Theme, createStyles } from "@material-ui/core";

/**
 * Styles for google marker event component
 * @param theme
 */
const styles = (theme: Theme) =>
  createStyles({
    eventContainer: {
      backgroundColor: "#fff",
      minWidth: "100%",
      height: "60px",
      position: "relative",
      marginBottom: 2,
      cursor: "pointer"
    },
    imageContainer: {
      backgroundSize: "cover",
      backgroundPosition: "center"
    },
    infoContainer: {
      padding: "10px 15px"
    },
    name: {
      margin: 0,
      whiteSpace: "nowrap",
      width: "100%",
      textOverflow: "ellipsis",
      display: "block",
      overflow: "hidden"
    },
    time: {
      margin: 0
    }
  });

export default styles;
