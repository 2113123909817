import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    width: "100%",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: 0
    }
  },

  loaderContainer: {
    minHeight: 200,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },

  loader: {
    alignSelf: "center"
  },

  loaderCard: {
    minHeight: 300,
    background: theme.palette.background.paper,
    boxShadow: "none",
  },

  loaderPlaceholder: {
    display: "flex",
    flex: 1,
    height: "100%",
    animationDuration: "1.25s",
    animationFillMode: "forwards",
    animationIterationCount: "infinite",
    animationName: "$placeHolderShimmer",
    animationTimingFunction: "linear",
    backgroundColor: "#f7f7f7",
    background: "linear-gradient(to right, #f7f7f7 10%, #eeeeee 18%, #f7f7f7 33%)",
    backgroundSize: "800px 300px",
    position: "relative"
  },

  "@keyframes placeHolderShimmer": {
    from: {
      backgroundPosition: "-468px 0"
    },
    to: {
      backgroundPosition: "468px 0"
    }
  },

  title: {
    margin: "25px 0px"
  },

  grid: {
    flex: 1,
    display: "grid",
    gridGap: theme.spacing(2),
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.up("md")]: {
      gridGap: theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    [theme.breakpoints.up("xl")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    }
  },

  actionArea: {
    display: "block",
    flexDirection: "initial"
  },

  actions: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(5),
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },

  cardWrapper: {
    background: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column"
  },

  card: {
    background: theme.palette.background.paper,
    boxShadow: "none",
    flex: 1
  },

  media: {
    height: "15rem"
  },

  eventTime: {
    color: theme.palette.secondary.main,
    whiteSpace: "pre-line"
  },

  eventTitle: {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    fontSize: 18
  },

  eventInfo: {
    color: theme.palette.secondary.main,
    fontSize: 12
  }

});