import * as React from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../store";

import Api from "../../api/api";
import { Link } from "react-router-dom";
import { History, Location } from "history";
import { Event, PostEvent } from "../../generated/client";
import EventBanner from "../event/event-banner";
import strings from "../../localization/strings";
import EventUtils from "../../utils/event-utils";
import EventContent from "../event/event-content";
import EventDetails from "../event/event-details";
import styles from "../../styles/screens/event-screen";
import { NullableToken, CustomStyles } from "../../types";
import AppLayout, { SnackbarMessage } from "../layouts/app-layout";
import { withStyles, WithStyles, CircularProgress, Button, Box } from "@material-ui/core";


/**
 * Component properties
 */
interface Props extends WithStyles<typeof styles> {
  eventId: string;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  history: History<History.LocationState>;
  location: Location<History.LocationState>;
}

/**
 * Type for incoming message
 */
export type Message = "edited" | "created" | "no-permission" | "delete";

/**
 * Component state
 */
interface State {
  event?: Event;
  subEvents?: Event[];
  snackbarMessage?: SnackbarMessage;
}

/**
 * Event screen component 
 */
class EventScreen extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { }
  }

  /**
   * Component did mount life-cycle handler
   */
  public componentDidMount = async () =>  {
    await this.loadPage();
  }

  /**
   * Component did update life-cycle handler
   *
   * @param prevProps previous component properties
   */
  public componentDidUpdate = async (prevProps: Props) => {
    if (this.props.location !== prevProps.location) {
      await this.loadPage();
    }
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles } = this.props;
    const { event, subEvents, snackbarMessage } = this.state;
    
    if (!event) {
      return (
        <AppLayout snackbarMessage={ snackbarMessage } clearSnackbar={ this.clearSnackbar }>
        <div
          className={ classes.container }
          style={ customStyles?.container}
        >
          <div className={ classes.bannerLoader }>
            <div className={ classes.loaderContainer }>
              <CircularProgress
                className={ classes.loader }
                style={ customStyles?.loader }
              />
            </div>
          </div>
          <div className={ classes.loaderContainer }>
            <CircularProgress
              className={ classes.loader }
              style={ customStyles?.loader }
            />
          </div>
        </div>
      </AppLayout>
      );
    }

    return (
      <AppLayout snackbarMessage={ snackbarMessage } clearSnackbar={ this.clearSnackbar }>
        <div
          className={ classes.container }
          style={ customStyles?.container }
        >
          { this.renderEditButton() }
          <EventBanner
            event={ event }
          />
          <div
            className={ classes.content }
            style={ customStyles?.content }
          >
            <EventContent
              event={ event }
              subEvents={ subEvents }
            />
            <EventDetails
              event={ event }
            />
          </div>
        </div>
      </AppLayout>
    )
  }

  /**
   * Method for loading page
   */
  private loadPage = async () => {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    
    this.setState({
      snackbarMessage: this.getSnackbarMessage((query.get("message") as Message) || undefined)
    });
    
    await this.fetchEvents();
  }

  /**
   * Renders the edit event button
   */
  private renderEditButton = () => {
    const { accessToken, classes } = this.props;
    const { event } = this.state;

    if (!accessToken || !event) {
      return null;
    }

    if (!EventUtils.canEditEvent(accessToken, event)) {
      return null;
    }

    return (
      <Box position="absolute" zIndex={ 1000 } p={ 4 }>
        <Link className={ classes.link } to={ `/edit-event/${event.id}` }>
          <Button className={ classes.whiteButton }>{ strings.event.editEvent }</Button>
        </Link>
      </Box>
    );
  }

  /**
   * Method for fetching single event
   */
  private fetchEvents = async () => {
    const { eventId, accessToken } = this.props;

    try {
      const eventApi = Api.getEventApi(accessToken);
      const event = await eventApi.eventRetrieve({ id: eventId });
      const subEvents = await EventUtils.getEventSubEvents(event as PostEvent, accessToken);

      this.setState({
        event: event,
        subEvents: subEvents
      });

    } catch (error) {
      console.log(error);
      this.setState({
        snackbarMessage: {
          severity: "error",
          message: strings.errors.event.failedToLoadEvent
        }
      });
    }
  }

  /**
   * Returns snackbar message for given message object
   * 
   * @param message message
   * @return snackbar message
   */
  private getSnackbarMessage = (message?: Message): SnackbarMessage | undefined => {
    if (!message) {
      return undefined;
    }

    switch (message) {
      case "created":
        return {
          message: strings.errors.event.eventAddedSuccessfullyMessage,
          severity: "success"
        }
      case "edited":
        return {
          message: strings.errors.event.eventEditedSuccessfullyMessage,
          severity: "success"
        }
      case "no-permission":
        return {
          message: strings.errors.event.noEditPermissionMessage,
          severity: "error"
        }
    }
  }

  /**
   * Clears the snackbar message
   */
  private clearSnackbar = () => {
    this.setState({
      snackbarMessage: undefined
    });
  }

}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(EventScreen)
const Connected = connect(mapStateToProps, mapDispatchToProps)(Styled);

export default Connected;
