import * as React from "react";

import { ReduxActions, ReduxState } from "../../store";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { withStyles, WithStyles, Typography } from "@material-ui/core";
import ExternalLinks from "../generic/event-external-links";
import { CustomStyles, NullableToken } from "../../types";
import { Event, Place } from "../../generated/client";
import styles from "../../styles/event/event-details";
import GoogleMaps from "../generic/single-event-map";
import EventUtils from "../../utils/event-utils";
import strings from "../../localization/strings";

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  event: Event;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
}

interface State {
  place?: Place;
}

/**
 * Component for event content
 * 
 * @param props component props
 */
class EventDetails extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component did mount life-cycle handler
   */
  componentDidMount = async () => {
    const { event, accessToken } = this.props;
    try {
      const place = await EventUtils.getEventPlace(event, accessToken);
      this.setState({ place: place });
    } catch(e) {
      console.error(e, "error fetching events location")
    }
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles, event } = this.props;
    const { place } = this.state;

    if (!place) {
      return null;
    }

    const name = EventUtils.getPlaceName(place);
    const address = EventUtils.getPlaceAddress(place);
    const description = EventUtils.getPlaceDescription(event);

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        <GoogleMaps place={ this.state.place! } />
        <div className={ classes.content }>
          <Typography
            variant="h4"
          >
            { strings.event.location }
          </Typography>
          <Typography>
            { name }
          </Typography>
          { description &&
            <React.Fragment>
              <Typography
                variant="h4"
              >
                { strings.event.locationDescription }
              </Typography>
              <Typography>
                { description }
              </Typography>
            </React.Fragment>
          }
          <Typography
            variant="h4"
          >
            { strings.event.address }
          </Typography>
          <Typography>
            { address }
          </Typography>
          { this.renderOrganizerInformation() }
        </div>
        { this.externalLinks() }
      </div>
    );
  }

  /**
   * Method for rendering organizer information
   */
  private renderOrganizerInformation = () => {
    const { event } = this.props;
    const providerName = EventUtils.getEventProviderName(event);
    const providerEmail = EventUtils.getEventProviderEmail(event);
    const providerPhone = EventUtils.getEventProviderPhone(event);

    if (!providerName && !providerEmail && !providerPhone) {
      return null;
    }

    return (
      <React.Fragment>
        <Typography
          variant="h4"
        >
          { strings.event.organizer }
        </Typography>
        <Typography variant="body1">
          { providerName }
        </Typography>
        <Typography variant="body1">
          { providerEmail }
        </Typography>
        <Typography variant="body1">
          { providerPhone }
        </Typography>
      </React.Fragment>
    );
  }

  /**
   * Method for checking if component is needed to be rendered
   * 
   * @returns rendered view from external links
   */
  private externalLinks = () => {
    const { event, classes } = this.props;

    if (!event.externalLinks || !event.externalLinks.length) {
      return null;
    }

    const hashtagLink = event.externalLinks.find(linkObject =>
      linkObject.name === "Instagram HashTag"
    )?.link;

    const hashtag = hashtagLink ? this.parseHashtag(hashtagLink) : "";

    return (
      <div className={ classes.externalLinks }>
        <Typography
          variant="h3"
        >
          { strings.event.externalLinks }
        </Typography>
        <ExternalLinks event={ event } />
        { hashtagLink &&
          <Typography
            variant="h2"
          >
            <a className={ classes.link } href={ hashtagLink }>#{ hashtag }</a>
          </Typography>
        }
      </div>
    );
  }

  /**
   * Method for parsing hashtag
   *
   * @param link link with hashtag
   */
  private parseHashtag = (link: string) => {
    const decoded = decodeURIComponent(link);
    const stripped = decoded.replace(/^\/|\/$/g, "");
    const splitted = stripped.split("/");
    return splitted.length ? splitted.pop() : "";
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(EventDetails);
const Connected = connect(mapStateToProps, mapDispatchToProps)(Styled);

export default Connected;
