import { createStyles } from "@material-ui/core";
import theme from "../../theme/default-theme";
import backgroundImg from "../../resources/img/EP-tausta.jpg";

const mobileSideSpacing = theme.spacing(3)

export default createStyles({

  container: {
    position: "relative",
    width: "100%",
    maxWidth: 1700,
    [theme.breakpoints.up("md")]: {
      width: "90vw",
    }
  },

  appBar: {
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
  },

  toolBar: {
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: mobileSideSpacing,
    marginRight: mobileSideSpacing,
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      marginRight: 0,
    }
  },

  button: {
    padding: "10px 20px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },

  logo: {
    maxWidth: 180,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 250
    },
    [theme.breakpoints.up(1050)]: {
      maxWidth: 350
    },
  },

  link: {
    width: "100%",
    textDecoration: "none"
  },

  profileLink: {
    width: "100%",
    textDecoration: "none",
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2)
    }
  },

  language: { 
    cursor: "pointer",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: 0
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(4)
    }
  },

  mobileMenu: {
    position: "absolute",
    zIndex: 1000,
    top: 0,
    width: "100%",
    background: "#fff",
    backgroundImage: `url(${ backgroundImg })`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },

  menuContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(4)
  }

});