import { createStyles } from "@material-ui/core";
import theme from "../../../theme/admin-theme";

export const styles = createStyles({

  root: {
    background: theme.palette.background.paper,
    flexGrow: 1
  },

  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  logo: {
    width: 260,
    marginRight: theme.spacing(2)
  },

  button: {
    marginLeft: "auto",
    padding: "10px 20px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
});
