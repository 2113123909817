import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

const mobileSideSpacing = theme.spacing(3)

export default createStyles({

  container: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "70%",
      paddingRight: theme.spacing(4)
    }
  },

  info: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      marginRight: mobileSideSpacing,
      marginLeft: mobileSideSpacing
    }
  },

  infoItem: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: "center"
  },
  
  content: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      marginRight: mobileSideSpacing,
      marginLeft: mobileSideSpacing
    }
  },

  mapWrapper: {

  },

  eventInfo: {
    [theme.breakpoints.down("sm")]: {
      marginRight: mobileSideSpacing,
      marginLeft: mobileSideSpacing
    }
  },

  subEventTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },

  period: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },

  eventPeriods: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },

  subheader: {

  },

  price: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper
  },

});