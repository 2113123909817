import { Theme, createStyles } from "@material-ui/core";

/**
 * Styles for search parameters place component
 * @param theme
 */
const styles = (theme: Theme) =>
  createStyles({

    dropdownContainer: {
      flex: 1,
      height: "100%"
    },

    itemList: {
      padding: theme.spacing(1),
      display: "flex",
      flexDirection: "column"
    },

    searchParamContainer: {
      flex: 1,
      height: "100%"
    },

    buttonRoot: {
      flex: 1,
      height: "100%",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        padding: 12
      }
    },

    buttonContainer: {
      flex: 1,
      height: "100%"
    },

    buttonLabel: {
      fontSize: 14,
      fontWeight: 500,
      justifyContent: "left"
    }

  });

export default styles;
