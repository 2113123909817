import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      marginRight: theme.spacing(4),
      "& a": {
        marginRight: theme.spacing(2)
      }
    }
  },

});