import * as React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

/**
 * Interface representing component properties
 */
interface Props extends SvgIconProps {
  htmlColor?: string;
}

/**
 * Render close x Icon
 */
const CloseIcon = (props: Props) => {
  return (
    <SvgIcon {...props}>
      <path d="M10.3,7.8l1.5,2.4l0.2,0.3l0.2-0.3l1.5-2.4h2.6L13.4,12l3,4.2h-2.6l-1.6-2.4L12,13.5l-0.1,0.3l-1.6,2.4H7.6l3-4.2L7.7,7.8H10.3z"/>
    </SvgIcon>
  );
}

export default CloseIcon;