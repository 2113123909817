import * as React from "react";
import EventForm from "../event/event-form";
import Table from "../generic/admin/table/table";
import EventUtils from "../../utils/event-utils";
import GenericDrawer from "../generic/generic-drawer";
import strings from "../../localization/strings";
import { CircularProgress, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/screens/admin/editor-screen";
import GenericConfirmDialog from "../generic/generic-confirm-dialog";
import { IEventsColumnData, AccessToken, Localizations, EventPeriod } from "../../types";
import { Event, Place, PostEvent, PostEventFromJSON, PostEventToJSON, Image, Eventlink } from "../../generated/client";
import { Message } from "../screens/event-screen";
import { SnackbarMessage } from "../layouts/app-layout";
import GenericSnackbar from "../generic/generic-snackbar";
import Api from "../../api/api";
import moment from "moment";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  accessToken: AccessToken;
}

/**
 * Interface describing component state
 */
interface State {
  columns: IEventsColumnData[];
  editId?: string;
  editEvent?: PostEvent;
  drawerOpen: boolean;
  eventImage?: Image;
  eventImagePreview?: Image;
  deleteConfirmOpen: boolean;
  tableData: RowData[];
  loading: boolean;
  localizations: Localizations;
  formValid: boolean;
  showValidationMessages: boolean;
  snackbarMessage?: SnackbarMessage;
  eventLocation?: Place;
  places: Place[];
  placesLoaded: boolean;
  eventPeriods: EventPeriod[];
}

/**
 * Describes the table row data
 */
interface RowData {
  displayId: string;
  id: string;
  fi?: string;
  en?: string;
  sv?: string;
  time: string;
  event: PostEvent;
}

/**
 * Component for events screen
 */
class AdminEventsTab extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      /**
       * title: Column name that is displayed
       * field: The value that is expected from the object
       */
      columns: [
        { title: 'ID', field: 'displayId' },
        { title: 'Suomi', field: 'fi' },
        { title: 'Englanti', field: 'en' },
        { 
          title: strings.admin.createdTime,
          field: 'created_time',
          customSort: (a, b) => {
            return a.event.createdTime - b.event.createdTime;
          }
        },
        { 
          title: strings.admin.eventTime, 
          field: "time", 
          customSort: (a, b) => { 
            return a.event.startTime - b.event.startTime;
          }
        }
      ],
      localizations: {
        fi: true,
        sv: false,
        en: false
      },
      drawerOpen: false,
      deleteConfirmOpen: false,
      tableData: [],
      loading: false,
      formValid: false,
      showValidationMessages: false,
      places: [],
      snackbarMessage: this.getSnackbarMessage(undefined),
      placesLoaded: true,
      eventPeriods: []
    };
  }

  /**
   * Component did mount life-cycle handler
   */
  public componentDidMount = async () => {
    this.setState({
      loading: true
    });

    await this.fetchEvents();
    await this.fetchPlaces();

    this.setState({
      loading: false
    });
  }

  /**
   * Component render method
   */
  public render = () => {
    const { classes } = this.props;
    const { columns, drawerOpen, tableData, editId, loading, snackbarMessage } = this.state;

    if (loading) {
      return (
        <div className={ classes.loaderContainer }>
          <CircularProgress size={ 80 }/>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className={ classes.container }>
          <Table
            columns={ columns }
            data={ tableData }
            showAddButton={ true }
            onAddButtonClick={ this.onAddButtonClick }
            currentRowSelect={ this.onTableRowSelect }
          />
          <GenericDrawer
            open={ drawerOpen }
            title={ editId ? strings.admin.updateEvent : strings.admin.newEvent }
            onDeleteClick={ this.onDeleteClick }
            onCancelClick={ this.onCancelClick }
            onSaveClick={ this.onSaveClick }
            onCloseClick={ this.onCloseClick }
            onClose={ this.onCloseClick }
          >
          <div className={ classes.eventFormWrapper }>
            { this.renderEventForm() }
          </div>
          </GenericDrawer>
          <GenericSnackbar snackbarMessage={ snackbarMessage } clearSnackbar={ this.clearSnackbar } />
          { this.renderDeleteDialog() }
        </div>
      </React.Fragment>
    );
  }

  /**
   * Renders event form
   */
  private renderEventForm = () => {
    const {
      editEvent,
      showValidationMessages,
      localizations,
      places,
      placesLoaded,
      eventLocation,
      eventImage,
      eventImagePreview,
      drawerOpen,
      eventPeriods
    } = this.state;

    if (!editEvent || !drawerOpen) {
      return null;
    }

    return (
      <EventForm
        places={ places }
        showHelp={ false }
        event={ editEvent }
        eventImage={ eventImage }
        eventPeriods={ eventPeriods }
        placesLoaded={ placesLoaded }
        eventLocation={ eventLocation }
        localizations={ localizations }
        onPlaceUpdate={ this.onPlaceUpdate }
        onEventUpdate={ this.onEventUpdate }
        eventImagePreview={ eventImagePreview }
        onValidUpdate={ this.onFormValidUpdate }
        onChangeEventImage={ this.setEventImage }
        onRemoveEventImage={ this.removeEventImage }
        showValidationMessages={ showValidationMessages }
        onLocalizationUpdate={ this.onLocalizationUpdate }
        onUpdateEventPeriods={ this.onUpdateEventPeriods }
        onChangeEventImagePreview={ this.setEventImagePreview }
      />
    );
  }

  /** 
   * Renders delete dialog popup
   */
  private renderDeleteDialog = () => {
    const { deleteConfirmOpen } = this.state;

    return (
      <GenericConfirmDialog 
        positiveButtonText={ strings.admin.delete }
        cancelButtonText={ strings.admin.cancel }
        title={ strings.admin.dialogDeleteEventTitle }
        description={ strings.admin.dialogDeleteEventDescription}
        onConfirm={ this.onDeleteConfirmDialogConfirm }
        onCancel={ this.onDeleteConfirmDialogCancel }
        open={ deleteConfirmOpen }
      />
    );
  }

  /**
   * Returns events as table data
   * 
   * @param events places
   * @return table data
   */
  private getTableData = (events: Event[]): RowData[] => {
    return events.map(this.toTableData);
  }

  /**
   * Returns place as table data entry
   * 
   * @param event place
   * @return table data entry
   */
  private toTableData = (event: PostEvent) => {
    const id = event.id!;

    return {
      displayId: this.getDisplayId(id),
      id: id,
      fi: event.name.fi,
      en: event.name.en,
      created_time: moment(event.createdTime).format("lll"),
      time: EventUtils.formatEventDate(event),
      event: this.cloneEvent(event)
    };
  }

  /**
   * Creates cloned event
   * 
   * @param event original
   * @return cloned
   */
  private cloneEvent = (event: PostEvent) => {
    return PostEventFromJSON(PostEventToJSON(event));
  }

  /**
   * Returns id as displayable id
   * 
   * @param id id
   * @return displayable id
   */
  private getDisplayId = (id: string): string => {
    return id.split("").reverse().join("").substr(1, 10).split("").reverse().join("");
  }

  /**
   * Gets all events
   */
  private fetchEvents = async () => {
    const { accessToken } = this.props;

    const events = await EventUtils.getAllEvents(accessToken);

    this.setState({
      tableData: this.getTableData(events)
    })
  }

  /**
   * Creates new Event 
   */
  private createEvent = async () => {
    const { accessToken } = this.props;
    const { editEvent, tableData, eventPeriods } = this.state;

    if (!accessToken || !editEvent) {
      return;
    }

    editEvent.audience = [];
    editEvent.externalLinks = this.filterEmptyLinks(editEvent.externalLinks || []);
    
    this.setState({
      loading: true
    });

    const createEvent = { ...editEvent,
      originId: Math.random().toString(36).substr(2, 10),
      dataSource: "eptapahtumat"
    };

    try {
      const createdEvent = await EventUtils.createNewEvent(createEvent, eventPeriods, accessToken);
      createdEvent.externalLinks = [
        ...createdEvent.externalLinks ?? [],
        {
          language: "fi",
          name: "EP Kalenteri",
          link: `${ window.location.origin }/event/${ EventUtils.parseIdFromUrl(createdEvent.id || "") }` 
        }
      ];
      await EventUtils.updateExistingEvent(createdEvent.id || "", createdEvent, eventPeriods, accessToken);
      this.setState({
        loading: false,
        tableData: [ ...tableData, this.toTableData(createdEvent) ],
        drawerOpen: false,
        snackbarMessage: this.getSnackbarMessage("created")
      });
    } catch(e) { 
        console.error("Error creating event: ", e)
        this.setState({
          snackbarMessage: this.getSnackbarMessage("no-permission")
        })
      }

    this.setState({
      loading: false
    });
  }

  /**
   * Updates the event 
   */
  private updateEvent = async () => {
    const { accessToken } = this.props;
    const { editId, editEvent, tableData, eventPeriods } = this.state;

    if (!accessToken || !editId || !editEvent) {
      return;
    }

    editEvent.audience = [];
    editEvent.externalLinks = this.filterEmptyLinks(editEvent.externalLinks || []);

    this.setState({
      loading: true
    });

    const id = EventUtils.parseIdFromUrl(editId);

    const updateEvent = { ...editEvent,
      id: id,
      createdTime: editEvent.createdTime || new Date(),
      lastModifiedTime: new Date()
    };

    try {
      const updatedEvent = await EventUtils.updateExistingEvent(id, updateEvent, eventPeriods, accessToken);
      const updatedTableData = tableData.filter(event => event.id !== id);
      updatedTableData.push(this.toTableData(updatedEvent));
  
      this.setState({
        editId: undefined,
        drawerOpen: false,
        snackbarMessage: this.getSnackbarMessage("edited"),
        tableData: updatedTableData
      });
    } catch(e) {
      console.error("error updating event: ", e)

      this.setState({
        snackbarMessage: this.getSnackbarMessage("no-permission"),
      });
    }

    this.setState({
      loading: false
    });
  }

  /**
   * Method for setting event image
   *
   * @param eventImage event image
   */
  private setEventImage = (eventImage: Image) => {
    const { editEvent } = this.state;

    if (!editEvent) {
      return;
    }

    editEvent.images = [
      { id: eventImage.id }
    ];

    this.setState({ editEvent, eventImage });
  }

  /**
   * Method for removing event image
   */
  private removeEventImage = () => {
    const { editEvent } = this.state;

    if (!editEvent) {
      return;
    }

    editEvent.images = [];

    this.setState({ editEvent, eventImage: undefined });
  }

  /**
   * Method for setting event image preview
   *
   * @param eventImagePreview event image preview
   */
  private setEventImagePreview = (eventImagePreview: Image) => {
    this.setState({ eventImagePreview });
  }

  /**
   * Event handler for confirm dialog cancel click
   */
  private onDeleteConfirmDialogCancel = () => {
    this.setState({
      deleteConfirmOpen: false
    });
  }


  /**
   * Event handler for keyword deletion click
   */
  private onDeleteClick = () => {
    this.setState({
      deleteConfirmOpen: true
    });
  }

  /**
   * Event handler for cancel editing / adding keyword click
   */
  private onCancelClick = () => {
    this.setState({
      drawerOpen: false
    });
  }

  /**
   * Event handler for place save click
   */
  private onSaveClick = async () => {
    const { editId, formValid } = this.state;
    
    if (!formValid) {
      this.setState({
        showValidationMessages: true
      });

      window.scroll({ top: 0, left: 0 });
      return;
    }

    if (!editId) {
      await this.createEvent();
    } else {
      await this.updateEvent();
    }
  }

  /**
   * Event handler for drawer close click
   */
  private onCloseClick = () => {
    this.setState({
      drawerOpen: false
    });
  }

  /**
   * Event handler for event update
   * 
   * @param event event
   */
  private onEventUpdate = (event: PostEvent) => {
    this.setState({
      editEvent: { ...event } as PostEvent
    });
  }

  /**
   * Event handler for form valid update
   * 
   * @param valid valid
   */
  private onFormValidUpdate = (valid: boolean) => {
    this.setState({
      formValid: valid
    });
  }

  /**
   * Event handler for localization update
   * 
   * @param localizations localizations
   */
  private onLocalizationUpdate = (localizations: Localizations) => {
    this.setState({
      localizations: { ...localizations }
    });
  }

  /**
   * Method for updating event periods
   *
   * @param eventPeriods event periods
   */
  private onUpdateEventPeriods = (eventPeriods: EventPeriod[]) => {
    this.setState({ 
      eventPeriods: eventPeriods 
    });
  }

  /**
   * Event handler for confirm dialog confirm click
   */
  private onDeleteConfirmDialogConfirm = async () => {
    const { accessToken } = this.props;
    const { editId, editEvent, tableData } = this.state;
    this.setState({
      drawerOpen: false,
      loading: true,
      deleteConfirmOpen: false
    });

    if (!editEvent) {
      return;
    }
    
    try {
      await EventUtils.deleteExistingEvent(editEvent, accessToken);
    } catch (error) {
      console.log(error);
      this.setState({
        snackbarMessage: this.getSnackbarMessage("no-permission"),
      });
    }

    this.setState({
      snackbarMessage: this.getSnackbarMessage("delete"),
      editId: undefined,
      loading: false,
      tableData: tableData.filter(tableRow => tableRow.id !== editId)
    });
  }

    /**
   * Event handler for table row select
   * 
   * @param data selected row data
   */
  private onTableRowSelect = async (data?: RowData) => {
    const { accessToken } = this.props;

    if(!data?.event) {
      return;
    }

    this.fetchEventLocation(data.event);

    this.setState({
      editId: data?.id,
      editEvent: data?.event,
      localizations: {
        en: !!data?.en,
        fi: !!data?.fi,
        sv: !!data?.sv
      },
      drawerOpen: true,
      eventImage: await this.getEventImage(data?.event),
      eventPeriods: await EventUtils.getEventPeriods(data.event, accessToken)
    });
  }

  /**
   * Method for getting event image
   *
   * @param event linked events event
   */
  private getEventImage = async (event?: PostEvent): Promise<Image | undefined> => {
    const { accessToken } = this.props;

    if (!event) {
      return undefined;
    }
    
    const { images } = event;

    if (!images || !images.length || !images[0].id) {
      return undefined;
    }

    const imageId = EventUtils.parseIdFromUrl(images[0].id);
    const imageApi = Api.getImageApi(accessToken);
    const image = await imageApi.imageRetrieve({
      id: imageId
    });

    return image;
  }

  /**
   * Fetch event location
   * 
   * @param event event
   */
  private fetchEventLocation = async (event: PostEvent) => {
    const { accessToken } = this.props;

    try {
      if (accessToken && event.location.id) {
        const filterApi = Api.getFilterApi(accessToken);
        const placeId = EventUtils.parseIdFromUrl(event.location.id);
        const retrievedPlace = await filterApi.placeRetrieve({
          id: placeId
        });

        this.setState({
          eventLocation: retrievedPlace
        });
      }
    } catch(e) {
      console.error(e, "error fetching events location")
    }
}

  /**
   * Method for updating event's place
   *
   * @param place new place
   */
  private onPlaceUpdate = (value: Place | null) => {
    if (!value || !value.id) {
      return;
    }

    this.setState({
      eventLocation: value
    });
  }

  /**
   * Method for fetching places
   *
   * @param page page number, defaults to one
   */
  private fetchPlaces = async (page: number = 1) => {
    const { accessToken } = this.props;
    const { places } = this.state;

    const filterApi = Api.getFilterApi(accessToken);
    const response = await filterApi.placeList({
      pageSize: 100,
      page: page,
      showAllPlaces: true
    });

    if (!response.data) {
      return;
    }

    const filteredPlaces = response.data.filter(place => {
      return !place.deleted && (!place.customData || place.customData.listed === "true");
    });

    this.setState({
      places: [
        ...places,
        ...filteredPlaces
      ]
    });

    if (response.meta && response.meta.next) {
      this.fetchPlaces(++page);
    } else {
      this.setState({ placesLoaded: true });
    }
  }

  /**
   * Event handler for side menu toggle
   */
  private onAddButtonClick = () => {
    const defaultTime = moment().minutes(0).seconds(0).toDate();

    this.setState({
      drawerOpen: true,
      eventPeriods: [],
      editId: undefined,
      eventImage: undefined,
      eventImagePreview: undefined,
      localizations: {
        fi: true,
        en: false,
        sv: false
      },
      editEvent: {
        name: {
          fi: "",
          sv: "",
          en: ""
        },
        description: {
          fi: "",
          sv: "",
          en: ""
        },
        shortDescription: {
          fi: "",
          sv: "",
          en: ""
        },
        offers: [
          {
            isFree: false,
            description: {
              fi: "",
              sv: "",
              en: ""
            }
          }
        ],
        images: [
          {
            id: ""
          }
        ],
        startTime: defaultTime,
        endTime: defaultTime,
        customData: {
          contact_name: "",
          contact_phone: "",
          contact_email: "",
          provider_name: "",
          provider_phone: "",
          provider_email: "",
          other_attachments: "[]",
          registration_description: `{
            "fi": "",
            "sv": "",
            "en": ""
          }`
        },
        location: {
          id: ""
        },
        provider: {
          fi: "",
          sv: "",
          en: ""
        },
        keywords: [],
        publicationStatus: "public",
      },
      eventLocation: undefined
    });
  }

  /**
   * Method for removing empty links
   *
   * @param externalLinks external links
   * @returns event link array
   */
  private filterEmptyLinks = (externalLinks: Eventlink[]): Eventlink[] => {
    return externalLinks
      .filter((item: Eventlink) => !!item.link)
      .map((item: Eventlink) =>
        /^https?:\/\//g.test(item.link || "") ? item : {...item, link: `https://${ item.link }`}
      );
  }

  /**
   * Clears the snackbar message
   */
  private clearSnackbar = () => {
    this.setState({
      snackbarMessage: undefined
    });
  }

  /**
   * Returns snackbar message for given message object
   * 
   * @param message message
   * @return snackbar message
   */
  private getSnackbarMessage = (message?: Message): SnackbarMessage | undefined => {
    if (!message) {
      return undefined;
    }

    switch (message) {
      case "created":
        return {
          message: strings.event.eventAddedSuccess,
          severity: "success"
        }
      case "edited":
        return {
          message: strings.event.eventEditSuccess,
          severity: "success"
        }
      case "no-permission":
        return {
          message: strings.errors.failMessage,
          severity: "error"
        }
      case "delete":
        return {
          message: strings.event.eventDeleteSuccess,
          severity: "success"
        }
    }
  }

}

export default withStyles(styles)(AdminEventsTab);
