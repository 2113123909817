import * as React from "react";
import { Grid, Button, Popper, Paper, WithStyles, ClickAwayListener, Fade } from "@material-ui/core";
import SearchListItemGeneric from "./search-list-item-generic";
import { Keyword } from "../../generated/client/models/Keyword";
import styles from "../../styles/generic/search-dropdown-generic-styles";
import { withStyles } from "@material-ui/styles";

/**
 * Component Props
 */
interface Props extends WithStyles<typeof styles> {
  buttonBackgroundColor: string;
  buttonTextColor: string;
  label: string;
  disabled: boolean;
  keywords: Keyword[];
  selectedIds: string[];
  isTypeWhom?: boolean;
  onFilterListChange: (keywordId: string, selected: boolean) => void;
}

/**
 * Creates component for changing search parameters for filtter
 * @param props
 */
const SearchDropDownGeneric = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | undefined | HTMLElement>(null);
  const gridRef = React.useRef();

  /**
   * Handles click event
   */
  const handleClick = () => {
    setAnchorEl(anchorEl ? null : gridRef.current);
  };

  /**
   * Handles click away event for popper closing it
   */
  const onKeywordListClickAway = () => {
    setAnchorEl(null);
  };

  const open: boolean = Boolean(anchorEl);
  const { classes, onFilterListChange, label: title, buttonTextColor, buttonBackgroundColor, isTypeWhom } = props;
  const selectable = props.keywords.filter(keyword => props.selectedIds.indexOf(keyword.id ? keyword.id : "") === -1);

  return (
    <div className={ classes.searchParamContainer }>
      <Grid 
        className={ classes.dropdownContainer }
        container
        item
        justify="center"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className={ classes.buttonContainer }
          innerRef={ gridRef }
        >
          <Button
            style={{ color: buttonTextColor, backgroundColor: buttonBackgroundColor }}
            disableElevation
            variant="contained"
            color="inherit"
            onClick={ handleClick }
            disabled={ props.disabled }
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
          >
            { title }
          </Button>
        </Grid>
      </Grid>

      <Popper open={ open } placement="bottom" anchorEl={ anchorEl } transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={ onKeywordListClickAway }>
            <Fade { ...TransitionProps }>
              <Paper className={ classes.itemList }>
                { selectable.map(keyword => {
                  return <SearchListItemGeneric isTypeWhom={ isTypeWhom } key={ keyword.id } keyword={ keyword } onChange={ onFilterListChange } selected={ false } />;
                }) }
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(SearchDropDownGeneric);
