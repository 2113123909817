import * as React from "react";

import strings from "../../localization/strings";
import styles from "../../styles/generic/form-item";
import LocalizedInput from "../generic/localized-input";
import SimpleReactValidator from "simple-react-validator";
import { PostEventProvider } from "../../generated/client";
import { CustomStyles, Localizations, LocalizedValues } from "../../types";
import { Typography, withStyles, WithStyles, TextField } from "@material-ui/core";


/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  showHelp?: boolean;
  help?: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  providerPhone: string;
  providerEmail: string;
  customStyles?: CustomStyles;
  localizations: Localizations;
  providerName: PostEventProvider;
  validator: SimpleReactValidator;
  onChangeContactName: (value: string) => void;
  onChangeContactPhone: (value: string) => void;
  onChangeContactEmail: (value: string) => void;
  onChangeProviderName: (locale: string, value: string) => void;
  onChangeProviderPhone: (value: string) => void;
  onChangeProviderEmail: (value: string) => void;
}

/**
 * Interface describing component state
 */
interface State { }

/**
 * Event location component
 */
class EventContactInformation extends React.Component<Props, State> {

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render
   */
  public render = () => {
    const {
      classes,
      validator,
      contactName,
      customStyles,
      contactPhone,
      contactEmail,
      providerName,
      localizations,
      providerPhone,
      providerEmail,
      showHelp
    } = this.props;

    return (
      <>
        <div
          className={ classes.container }
          style={ customStyles?.container }
        >
          <div className={ classes.content }>
            <Typography className={ classes.title } variant="h6">
              { strings.eventForm.responsiblePerson }
            </Typography>
            <div className={ classes.inputFieldsContainer }>
              <TextField label={ strings.eventForm.responsiblePersonName } value={ contactName } onChange={ this.onChangeContactName } />
              { this.renderValidatorMessage("contact_name", contactName) }
              <TextField value={ contactPhone } onChange={ this.onChangeContactPhone } label={ strings.eventForm.responsiblePersonPhone } />
              { this.renderValidatorMessage("contact_phone", contactPhone) }
              <TextField value={ contactEmail } onChange={ this.onChangeContactEmail } label={ strings.eventForm.responsiblePersonEmail } />
              { this.renderValidatorMessage("contact_email", contactEmail) }
            </div>
          </div>
          { showHelp &&
            <div className={ classes.helpContainer }>
              <div className={ classes.helpContent }>
                <Typography>{ strings.eventForm.responsiblePersonDescription }</Typography>
              </div>
            </div>
          }
        </div>
        <div
          className={ classes.container }
          style={ customStyles?.container }
        >
          <div className={ classes.content }>
            <Typography className={ classes.title } variant="h6">
              { strings.eventForm.eventOrganization }
            </Typography>
            <div className={ classes.inputFieldsContainer }>
              <LocalizedInput
                required={ true }
                validator={ validator }
                localizations={ localizations }
                onChange={ this.onChangeProviderName }
                localizedValues={ providerName as LocalizedValues }
                localizedStrings={ strings.eventForm.eventOrganizationName }
              />
              <TextField value={ providerEmail } onChange={ this.onChangeProviderEmail } label={ strings.eventForm.eventOrganizationEmail }/>
              { this.renderValidatorMessage("provider_email", providerEmail) }
              <TextField value={ providerPhone } onChange={ this.onChangeProviderPhone } label={ strings.eventForm.eventOrganizationPhone }/>
            </div>
          </div>
          { showHelp &&
            <div className={ classes.helpContainer }>
              <div className={ classes.helpContent }>
                <Typography>{ strings.eventForm.eventProviderDescription }</Typography>
              </div>
            </div>
          }
        </div>
      </>
    );
  }

  /**
   * Method for rendering validator message
   *
   * @param field field
   * @param value string
   */
  private renderValidatorMessage = (field: string, value: string) => {
    const { validator } = this.props;

    return validator.message(field, value, "required");
  }

  /**
   * Method for changing contact name
   *
   * @param event event
   */
  private onChangeContactName = (event: React.ChangeEvent<any>) => {
    const { onChangeContactName } = this.props;
    const value = event.target.value;

    onChangeContactName(value);
  }

  /**
   * Method for changing contact phone
   *
   * @param event event
   */
  private onChangeContactPhone = (event: React.ChangeEvent<any>) => {
    const { onChangeContactPhone } = this.props;
    const value = event.target.value;

    onChangeContactPhone(value);
  }

  /**
   * Method for changing contact email
   *
   * @param event event
   */
  private onChangeContactEmail = (event: React.ChangeEvent<any>) => {
    const { onChangeContactEmail } = this.props;
    const value = event.target.value;

    onChangeContactEmail(value);
  }

  /**
   * Method for changing provider name
   *
   * @param event event
   */
  private onChangeProviderName = (locale: string, value: string) => {
    const { onChangeProviderName } = this.props;
    onChangeProviderName(locale, value);
  }

  /**
   * Method for changing provider phone
   *
   * @param event event
   */
  private onChangeProviderPhone = (event: React.ChangeEvent<any>) => {
    const { onChangeProviderPhone } = this.props;
    const value = event.target.value;

    onChangeProviderPhone(value);
  }

  /**
   * Method for changing provider email
   *
   * @param event event
   */
  private onChangeProviderEmail = (event: React.ChangeEvent<any>) => {
    const { onChangeProviderEmail } = this.props;
    const value = event.target.value;

    onChangeProviderEmail(value);
  }

}

const Styled = withStyles(styles)(EventContactInformation);

export default Styled;