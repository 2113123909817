import { Theme, createStyles } from "@material-ui/core";

/**
 * Styles for google map component
 * @param theme
 */
const styles = (theme: Theme) =>
  createStyles({
    eventScrollContainer: {
      width: "100%"
    },

    eventContainer: {
      width: "100%",
      padding: theme.spacing(1),
      overflowX: "auto",
      background: "#fff",
      borderRadius: 3,
      boxShadow: "2px 2px 60px rgba(0,0,0,0.2)",
      [theme.breakpoints.up("xs")]: {
        maxHeight: 270
      },
      [theme.breakpoints.up("sm")]: {
        maxHeight: 466
      }
    },
    scrollBarContainer: {
      right: 20,
      top: "-16px",
      width: 32,
      borderRadius: "50%",
      height: 32,
      position: "fixed",
      textAlign: "center",
      backgroundColor: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      transition: "background-color 0.2s ease-out",
      "&:hover": {
        backgroundColor: "#f7f7f7"
      }
    },
    scrollBar: {
      transform: "rotate(45deg)"
    },
    popper: {
      [theme.breakpoints.up("xs")]: {
        width: 350,
        maxHeight: 270
      },
      [theme.breakpoints.up("sm")]: {
        maxHeight: 466,
        width: 400
      }
    }
  });

export default styles;
