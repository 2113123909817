/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Image,
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
    KeywordName,
    KeywordNameFromJSON,
    KeywordNameFromJSONTyped,
    KeywordNameToJSON,
} from './';

/**
 * Keywords are used to describe events. Linked events uses namespaced keywords in order to support having events from different sources. Namespaces are needed because keywords are defined by the organization sourcing the events and can therefore overlap in meaning. Conversely the meaning of same keyword can vary between organizations. Organization sourcing the keyword can be identified by data_source field. Data_source field will later specify standardized namespaces as well.
 * @export
 * @interface Keyword
 */
export interface Keyword {
    /**
     * Consists of source prefix and source specific identifier. These should be URIs uniquely identifying the keyword, and preferably also well formed http-URLs pointing to more information about the keyword.
     * @type {string}
     * @memberof Keyword
     */
    id: string;
    /**
     * 
     * @type {KeywordName}
     * @memberof Keyword
     */
    name: KeywordName;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Keyword
     */
    images?: Array<Image>;
    /**
     * Identifier for the keyword in the organization using this keyword. For standardized namespaces this will be a shared identifier.
     * @type {string}
     * @memberof Keyword
     */
    originId?: string;
    /**
     * Id of the organization that has originally published this keyword.
     * @type {string}
     * @memberof Keyword
     */
    publisher?: string;
    /**
     * Creation time for the keyword entry.
     * @type {Date}
     * @memberof Keyword
     */
    createdTime?: Date;
    /**
     * Time this place was modified in the datastore behind the API (not necessarily in the originating system)
     * @type {Date}
     * @memberof Keyword
     */
    lastModifiedTime?: Date;
    /**
     * FIXME(verify) This keyword is an combination of several keywords at source
     * @type {boolean}
     * @memberof Keyword
     */
    aggregate?: boolean;
    /**
     * Source of the keyword, typically API provider specific identifier. Will also be used to specify standardized namespaces as they are brought into use.
     * @type {string}
     * @memberof Keyword
     */
    dataSource: string;
    /**
     * FIXME(verify) URL reference to the user that created this record (user endpoint)
     * @type {string}
     * @memberof Keyword
     */
    createdBy?: string;
    /**
     * FIXME(verify) URL reference to the user that last modfied this record (user endpoint)
     * @type {string}
     * @memberof Keyword
     */
    lastModifiedBy?: string;
    /**
     * FIXME(verify) alternative labels for this keyword, no language specified. Use case?
     * @type {Array<string>}
     * @memberof Keyword
     */
    altLabels?: Array<string>;
    /**
     * Whether this keyword has been deprecated in the original data source. It may still contain old events linked to it.
     * @type {boolean}
     * @memberof Keyword
     */
    deprecated?: boolean;
    /**
     * 
     * @type {Keyword}
     * @memberof Keyword
     */
    replacedBy?: Keyword;
}

export function KeywordFromJSON(json: any): Keyword {
    return KeywordFromJSONTyped(json, false);
}

export function KeywordFromJSONTyped(json: any, ignoreDiscriminator: boolean): Keyword {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['@id'],
        'name': KeywordNameFromJSON(json['name']),
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(ImageFromJSON)),
        'originId': !exists(json, 'origin_id') ? undefined : json['origin_id'],
        'publisher': !exists(json, 'publisher') ? undefined : json['publisher'],
        'createdTime': !exists(json, 'created_time') ? undefined : (new Date(json['created_time'])),
        'lastModifiedTime': !exists(json, 'last_modified_time') ? undefined : (new Date(json['last_modified_time'])),
        'aggregate': !exists(json, 'aggregate') ? undefined : json['aggregate'],
        'dataSource': json['data_source'],
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'lastModifiedBy': !exists(json, 'last_modified_by') ? undefined : json['last_modified_by'],
        'altLabels': !exists(json, 'alt_labels') ? undefined : json['alt_labels'],
        'deprecated': !exists(json, 'deprecated') ? undefined : json['deprecated'],
        'replacedBy': !exists(json, 'replaced_by') ? undefined : KeywordFromJSON(json['replaced_by']),
    };
}

export function KeywordToJSON(value?: Keyword | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': KeywordNameToJSON(value.name),
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(ImageToJSON)),
        'origin_id': value.originId,
        'publisher': value.publisher,
        'created_time': value.createdTime === undefined ? undefined : (value.createdTime.toISOString()),
        'last_modified_time': value.lastModifiedTime === undefined ? undefined : (value.lastModifiedTime.toISOString()),
        'aggregate': value.aggregate,
        'data_source': value.dataSource,
        'created_by': value.createdBy,
        'last_modified_by': value.lastModifiedBy,
        'alt_labels': value.altLabels,
        'deprecated': value.deprecated,
        'replaced_by': KeywordToJSON(value.replacedBy),
    };
}


