import * as React from "react";
import { Button, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../../../styles/generic/admin/table/table";
import { NullableToken } from "../../../../types";
import MaterialTable, { MTableToolbar } from "material-table";
import { tableIcons } from "./material-table-icons";
import strings from "../../../../localization/strings";
import AddIcon from "@material-ui/icons/Add";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  columns: any;
  data: any[];
  showAddButton?: boolean;
  onAddButtonClick?: () => void;
  currentRowSelect: (data: any | undefined) => void;
}

/**
 * Interface describing component state
 */
interface State {

}

/**
 * Component for table
 */
class Table extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  /**
   * Component render method
   */
  public render = () => {
    const { classes, columns, data, onAddButtonClick, showAddButton } = this.props;

    return (
      <div className={ classes.container }>
        <MaterialTable
          onRowClick={ (row, data) => {this.props.currentRowSelect(data) } }
          localization={{
            toolbar: {
              searchPlaceholder: strings.admin.search
            }
          }}
          title=""
          icons={ tableIcons }
          columns={ columns }
          data={ data }
          components={{
            Toolbar: props => (
              <div className={ classes.tableHeader }>
                <MTableToolbar { ...props } >
                </MTableToolbar>
                <div>
                  { showAddButton &&
                    <Button className={ classes.addButton }
                      startIcon={ <AddIcon /> }
                      variant="text"
                      onClick={ onAddButtonClick }
                    >
                      { strings.admin.new }
                    </Button>
                  }
                </div>
              </div>
            ),
          }}
          options={{
            pageSize: 15,
            pageSizeOptions: [15, 30 ,60, 90, 120 ],
            headerStyle: {
              backgroundColor: "rgba(79, 75, 91, 0.1)",
              color: '#000000'
            },
            rowStyle: {
            },
            tableLayout: "fixed"
          }}
        />
      </div>
    );
  }
}

export default (withStyles(styles)(Table));
