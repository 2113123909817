/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Contact phone number for the place, multilingual
 * @export
 * @interface PlaceTelephone
 */
export interface PlaceTelephone {
    /**
     * phone number for Finnish speakers
     * @type {string}
     * @memberof PlaceTelephone
     */
    fi?: string;
    /**
     * phone number for Swedish speakers
     * @type {string}
     * @memberof PlaceTelephone
     */
    sv?: string;
    /**
     * phone number for English speakers
     * @type {string}
     * @memberof PlaceTelephone
     */
    en?: string;
}

export function PlaceTelephoneFromJSON(json: any): PlaceTelephone {
    return PlaceTelephoneFromJSONTyped(json, false);
}

export function PlaceTelephoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceTelephone {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fi': !exists(json, 'fi') ? undefined : json['fi'],
        'sv': !exists(json, 'sv') ? undefined : json['sv'],
        'en': !exists(json, 'en') ? undefined : json['en'],
    };
}

export function PlaceTelephoneToJSON(value?: PlaceTelephone | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fi': value.fi,
        'sv': value.sv,
        'en': value.en,
    };
}


