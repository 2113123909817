import { createStyles } from "@material-ui/core";
import theme from "../../theme/admin-theme";

export const styles = createStyles({

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },

});