import * as React from "react";

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import { KeycloakInstance } from "keycloak-js";
import strings from "../../../localization/strings";
import Logo from "../../../resources/svg/ep-liitto.svg";
import { CustomStyles, NullableToken } from "../../../types";
import { styles } from "../../../styles/generic/admin/header";
import { AppBar, Link, Toolbar, withStyles, WithStyles } from "@material-ui/core";

const { REACT_APP_KEYCLOAK_URL, REACT_APP_KEYCLOAK_REALM } = process.env;

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  tabIndex: number;
  accessToken?: NullableToken;
  keycloak?: KeycloakInstance;
  customStyles?: CustomStyles;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

/**
 * Interface describing component state
 */
interface State {
}

/**
 * Component for admin header
 */
class Header extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Component render method
   */
  public render = () => {
    const { classes, tabIndex } = this.props;

    return (
      <AppBar className= { classes.root }>
        <Toolbar >
          <Link 
            href="/"
            className={ classes.logo }
          >
            <img src={ Logo } alt="ep-logo" />
          </Link>
          <Tabs
            onChange= { this.handleChange }
            value={ tabIndex }
          >
            <Tab label={ strings.admin.events } value={ 0 }/>
            <Tab label={ strings.admin.keywords } value={ 1 }/>
            <Tab label={ strings.admin.places } value={ 2 }/>
          </Tabs>
          { this.renderAdminConsoleButton() }
          { this.renderAuthAction() }
        </Toolbar>
      </AppBar>
    );
  }

  /**
   * Renders admin console button
   */
  private renderAdminConsoleButton = () => {
    if (this.props.accessToken?.clientRoles.includes("manage-users")) {
      return (
        <Button onClick={ this.onOpenAdminConsole }>
          { strings.admin.userManagement }
        </Button>
      );
    }
  }

  /**
   * Renders user auth action
   */
  private renderAuthAction = () => {
    const { accessToken, keycloak, classes, customStyles } = this.props;

    const label = accessToken ?
      strings.auth.logout :
      strings.auth.login;

    return (
      <Button
        variant="text"
        className={ classes.button }
        style={ customStyles?.button }
        onClick={ () => {
          if (accessToken) {
            if (keycloak) {
              keycloak.logout();
            }
          } else {
            if (keycloak) {
              keycloak.login({ idpHint: "oidc" });
            }
          }
        }}
      >
        { label }
      </Button>
    );
  }

  /**
   * Event handler for navigating to Keycloak admin console
   */
  private onOpenAdminConsole = () => {
    if (REACT_APP_KEYCLOAK_URL && REACT_APP_KEYCLOAK_REALM) {
      window.location.href = `${REACT_APP_KEYCLOAK_URL}/admin/${REACT_APP_KEYCLOAK_REALM}/console/index.html`;
    }
  }

  /**
   * Sets tab index
   *
   * @param event event object
   * @param value new tab index value
   */
  private handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.props.handleTabChange(event, newValue);
  }
}

const Styled = withStyles(styles)(Header);

export default Styled;