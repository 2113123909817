import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

const mobileSideSpacing = theme.spacing(3)

export default createStyles({

  container: {
    position: "relative",
    height: 200,
    display: "flex",
    maxWidth: "100%",
    objectFit: "cover",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    backgroundColor: "transparent",
    [theme.breakpoints.up(360)]: {
      height: 300,
    },
    [theme.breakpoints.up("sm")]: {
      height: 400,
    },
    [theme.breakpoints.up("md")]: {
      height: 480,
    },
    [theme.breakpoints.up("lg")]: {
      height: 540,
    },
    [theme.breakpoints.up("xl")]: {
      height: 600,
    },
    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.5) 100%)",
      zIndex: 1,
    }
  },

  eventTitle: {
    zIndex: 2,
    padding: mobileSideSpacing,
    fontSize: 18,
    width: "100%",
    fontWeight: "bold",
    alignSelf: "flex-end",
    color: theme.palette.text.secondary,
    textShadow: "0px 0px 2px rgba(0,0,0,0.5)",
    [theme.breakpoints.up(360)]: {
      fontSize: 20,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 25,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "70%",
      padding: 45,
      textShadow: "0px 0px 30px rgba(0,0,0,0.2)",
    }
  },


});