import { createStyles } from "@material-ui/core";
import theme from "../../theme/admin-theme";

export const styles = createStyles({

  drawerContent: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    height: "100%",
    minWidth: "50vw",
    maxWidth: "50vw",
    overflowY: "auto"
  },

  drawerToolbar: {
    position: "absolute",
    top: 0,
    width: "100%",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.2)",
    zIndex: 100
  },

  buttonBar: {
    "& button": {
      marginLeft: theme.spacing(2)
    }
  }

});
