import * as React from "react";
import { styles } from "../../styles/screens/admin/editor-screen";
import { AccessToken, LocalizedValue, Locale, LOCALES } from "../../types";
import Table from "..//generic/admin/table/table";
import GenericDrawer from "../generic/generic-drawer";
import { IPlaceColumnData } from "../../types/index";
import EventUtils from "../../utils/event-utils";
import { Place, PlaceCustomData, PlaceFromJSON, PlacePositionTypeEnum, PlaceToJSON } from "../../generated/client";
import strings from "../../localization/strings";
import { Container, Grid, TextField, WithStyles, withStyles, CircularProgress } from "@material-ui/core";
import GenericConfirmDialog from "../generic/generic-confirm-dialog";
import theme from "../../dynamic-content/theme";
import GenericSnackbar from "../generic/generic-snackbar";
import { SnackbarMessage } from "../layouts/app-layout";
import { Message } from "../screens/event-screen";
import CoordinatePicker from "./admin-places/coordinate-picker";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  accessToken: AccessToken;
}

/**
 * Interface describing component state
 */
interface State {
  columns: IPlaceColumnData[];
  editId?: string;
  editPlace?: Place;
  drawerOpen: boolean;
  deleteConfirmOpen: boolean;
  tableData: RowData[];
  loading: boolean;
  latitudeString: string;
  longitudeString: string;
  snackbarMessage?: SnackbarMessage;
}

/**
 * Describes the table row data
 */
interface RowData {
  displayId: string;
  id: string;
  fi?: string;
  en?: string;
  listed: string;
  place?: Place;
}

/**
 * Component for places screen
 */
class AdminPlacesTab extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      columns: [
        { title: 'ID', field: 'displayId' },
        { title: 'Suomi', field: 'fi' },
        { title: 'Englanti', field: 'en' },
        { title: 'Paikkakunta', field: 'addresLocality'},
        { title: 'Listattu', field: 'listed'},
      ],
      drawerOpen: false,
      deleteConfirmOpen: false,
      tableData: [],
      loading: false,
      latitudeString: "",
      longitudeString: "",
      snackbarMessage: this.getSnackbarMessage(undefined)
    }
  }

  /**
   * Component did mount life-cycle handler
   */
  public componentDidMount = async () => {
    this.setState({
      loading: true
    });

    await this.fetchPlaces();

    this.setState({
      loading: false
    });
  }

  /**
   * Component render method
   */
  public render = () => {
    const { classes } = this.props;
    const { columns, drawerOpen, tableData, editId, loading, snackbarMessage } = this.state;

    if (loading) {
      return (
        <div className={ classes.loaderContainer }>
          <CircularProgress size={ 80 }/>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div style={{ display: loading ? "none" : "block" }} className={ classes.container }>
          <Table
            columns={ columns }
            data={ tableData }
            showAddButton={ true }
            onAddButtonClick={ this.onAddButtonClick }
            currentRowSelect={ this.onTableRowSelect }
          />
          <GenericDrawer
            open={ drawerOpen }
            title={ editId ? strings.admin.updatePlace : strings.admin.newPlace }
            onDeleteClick={ this.onDeleteClick }
            onCancelClick={ this.onCancelClick }
            onSaveClick={ this.onSaveClick }
            onCloseClick={ this.onCloseClick }
            onToggleListStatus={ this.onToggleListStatus }
            showListToggle={ true }
            toggleButtonText={ this.createListToggleButtonText() }
            onClose={ this.clearState }
          >
          { this.renderForm() }
          </GenericDrawer>
          <GenericSnackbar snackbarMessage={ snackbarMessage } clearSnackbar={ this.clearSnackbar } />
          { this.renderDeleteDialog() }
        </div>
      </React.Fragment>
    );
  }

  /**
   * Renders form components
   */
  private renderForm = () => {
    const { classes } = this.props;
    const { editPlace, drawerOpen } = this.state;

    if (!editPlace || !drawerOpen) {
      return null;
    }

    const { name, description, addressLocality, infoUrl, streetAddress, postalCode, addressCountry } = editPlace;

    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container className={ classes.container }>
          { this.renderLocalizedField(strings.admin.placeName, "name", name) }
          { this.renderLocalizedField(strings.admin.placeDescription, "description", description) }
          { this.renderPositionField() }
          <Grid container spacing={ 2 } style={{ marginBottom: theme.spacing(2) }}>
            <Grid container spacing={ 2 } item xs={ 12 }>
              <Grid item xs={ 6 }>
              { this.renderTextField(strings.admin.addressCountry, "addressCountry", addressCountry || "") }
              </Grid>
              <Grid item xs={ 6 }>
              { this.renderTextField(strings.admin.postalCode, "postalCode", postalCode || "") }
              </Grid>
            </Grid>
          </Grid>

          { this.renderLocalizedField(strings.admin.addressLocality, "addressLocality", addressLocality) }  
          { this.renderLocalizedField(strings.admin.infoUrl, "infoUrl", infoUrl) }  
          { this.renderLocalizedField(strings.admin.streetAddress, "streetAddress", streetAddress) }
        </Container>
      </div>
    );
  }

  /**
   * Renders localized field
   * 
   * @param label label
   * @param property property
   * @param localizedValue localized value
   */
  private renderLocalizedField = (label: LocalizedValue, property: string, localizedValue?: LocalizedValue) => {
    return (
      <div style={{ marginBottom: theme.spacing(2) }}>
        {
          LOCALES.map(locale => {
            return (
              <TextField
                key={ `${ property }.${ locale }` }
                label={ label[locale] }
                onChange={ (event) => { this.onLocalizedFieldValueChange(property, locale, event.target.value) } }
                value={ localizedValue ? localizedValue[locale] : "" }
              />
            );
          })
        }
      </div>
    )
  }

  /**
   * Renders a text field
   * 
   * @param labelText field label text
   * @param key field key string
   * @param value value for new place
   */
  private renderTextField = (labelText: string, key: string, value: string) => {    
    return (
      <TextField
        label={ labelText }
        onChange={ (event) => { this.onTextFieldValueChange(key, event.target.value) } }
        value={ value }
      />
    )
  }

  /**
   * Renders position field
   */
  private renderPositionField = () => {  
    const { editPlace } = this.state;

    if (!editPlace) {
      return null;
    }

    const coordinates = editPlace.position.coordinates;
    const latitude = coordinates && coordinates.length ? coordinates[0] : "";
    const longitude = coordinates && coordinates.length > 1 ? coordinates[1] : "";

    return (
      <Grid container spacing={ 2 } style={{ marginBottom: theme.spacing(2) }}>
        <Grid container spacing={ 2 } item xs={ 12 }>
          <Grid item xs={ 12 }>
            <CoordinatePicker
              place={ editPlace }
              onUpdateLocation={ this.setNewPlaceLocation }
            />
          </Grid>
        </Grid>
        <Grid container spacing={ 2 } item xs={ 12 }>
          <Grid item xs={ 6 }>
            <TextField
              disabled
              value={ latitude }
              label={ strings.admin.coordinatesLat }
            />
          </Grid>

          <Grid item xs={ 6 }>
            <TextField
              disabled
              value={ longitude }
              label={ strings.admin.coordinatesLon }
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  /**
   * Method for setting autocomplete options
   *
   * @param updatedPlace linked events place object
   */
  private setNewPlaceLocation = (updatedPlace: Place) => {
    this.setState({ editPlace: updatedPlace });
  }

  /** 
   * Renders delete dialog popup
   */
  private renderDeleteDialog = () => {
    const { deleteConfirmOpen } = this.state;

    return (
      <GenericConfirmDialog 
        positiveButtonText={ strings.admin.delete }
        cancelButtonText={ strings.admin.cancel }
        title={ strings.admin.dialogDeletePlaceTitle }
        description={ strings.admin.dialogDeletePlaceDescription}
        onConfirm={ this.onDeleteConfirmDialogConfirm }
        onCancel={ this.onDeleteConfirmDialogCancel }
        open={ deleteConfirmOpen }
      />
    );
  }

  /**
   * Returns places as table data
   * 
   * @param places places
   * @return table data
   */
  private getTableData = (places: Place[]): RowData[] => {
    return places.map(this.toTableData);
  }

  /**
   * Returns place as table data entry
   * 
   * @param place place
   * @return table data entry
   */
  private toTableData = (place: Place) => {
    const id = place.id || "";

    return {
      displayId: this.getDisplayId(id),
      id: id,
      fi: place.name.fi,
      en: place.name.en,
      listed: this.getListedStatus(place),
      addresLocality: place.addressLocality ? place.addressLocality.fi : "",
      place: this.cloneEvent(place)
    };
  }

   /**
   * Creates cloned event
   * 
   * @param event original
   * @return cloned
   */
  private cloneEvent = (place: Place) => {
    return PlaceFromJSON(PlaceToJSON(place));
  }

  /**
   * Gets listed status of place
   * 
   * @param place place
   * 
   * @returns string based on listed status
   */
  private getListedStatus = (place: Place) => {
    if (!place.customData?.listed || place.customData.listed === "true") {
      return strings.admin.listed;
    } else {
      return strings.admin.notListed;
    }
  }

  /**
   * Toggles listed status of place
   */
  private onToggleListStatus = async () => {
    const { editPlace } = this.state;

    if (!editPlace) {
      return;
    }

    if (!editPlace.customData?.listed || editPlace.customData.listed === "true") {

      if (!editPlace.customData) {
        editPlace.customData = {} as PlaceCustomData;
      }

      this.setState({
        editPlace: { ...editPlace,
          customData: {
            listed: "false"
          }}
      })

    } else {
      this.setState({
        editPlace: { ...editPlace,
          customData: {
            listed: "true"
          }}
      })
    }
  }

  /**
   * Creates label for button based on place's listed status
   * 
   * @returns Created label
   */
  private createListToggleButtonText = () => {
    const { editPlace } = this.state;

    if (!editPlace) {
      return "";
    }

    if (!editPlace.customData?.listed || editPlace.customData.listed === "true") {
      return strings.admin.unlist;
    } else {
      return strings.admin.list;
    }
  }

  /**
   * Converts coordinates to string
   * 
   * @param coordinate coordinate to convert
   * 
   * @returns Converted coordinate
   */
  private coordinateTostring = (coordinate: number): string => {
    const parsedCoordinate = String(coordinate);

    return parsedCoordinate;
  }

  /**
   * Returns id as displayable id
   * 
   * @param id id
   * @return displayable id
   */
  private getDisplayId = (id: string) => {
    return id.split("").reverse().join("").substr(1, 10).split("").reverse().join("");
  }
  
  /**
   * Gets all places
   */
  private fetchPlaces = async () => {
    const { accessToken } = this.props;

    const places = await EventUtils.getAllPlaces(accessToken);
    this.setState({ 
      tableData: this.getTableData(places)
    });
  }

  /**
   * Creates new Place 
   */
  private createPlace = async () => {

    const { accessToken } = this.props;
    const { editPlace, tableData } = this.state;

    if (!accessToken || !editPlace) {
      return;
    }
    
    this.setState({
      loading: true
    });

    const createPlace = { ...editPlace,
      originId: Math.random().toString(36).substr(2, 10),
      createdTime: new Date(),
      dataSource: "eptapahtumat"
    };

    try {
      const createdPlace = await EventUtils.createNewPlace(createPlace, accessToken);

      this.setState({
        tableData: [ ...tableData, this.toTableData(createdPlace) ],
        drawerOpen: false,
        snackbarMessage: this.getSnackbarMessage("created")
      });
    } catch(e) {
        console.error("Error creating place: ", e)
        this.setState({
          snackbarMessage: this.getSnackbarMessage("no-permission")
        })
    }


    this.setState({
      loading: false,
    });
  }

  /**
   * Updates the Place 
   */
  private updatePlace = async () => {
    const { accessToken } = this.props;
    const { editId, editPlace, tableData } = this.state;

    if (!accessToken || !editId || !editPlace) {
      return;
    }

    this.setState({
      loading: true
    })

    const id = EventUtils.parseIdFromUrl(editId);

    const updatesPlace: Place = { ...editPlace,
      id: id,
      createdTime: editPlace.createdTime || new Date(),
      lastModifiedTime: new Date()
    };

    try {
      const updatedPlace = await EventUtils.updateExistingPlace(id, updatesPlace, accessToken);
      const updatedTableData = tableData.filter(place => place.id !== editId);
      updatedTableData.push(this.toTableData(updatedPlace));

      this.setState({
        tableData: updatedTableData,
        drawerOpen: false,
        snackbarMessage: this.getSnackbarMessage("edited")
      });
    } catch(e) {
        console.error("error updating place: ", e)

        this.setState({
          snackbarMessage: this.getSnackbarMessage("no-permission"),
      });
    }

    this.setState({
      loading: false
    });
  }

  /**
   * Event handler for localized field change
   * 
   * @param property property
   * @param locale locale
   * @param value value
   */
  private onLocalizedFieldValueChange = (property: string, locale: Locale, value: string) => {
    const { editPlace } = this.state;
    const updatedPlace = { ...editPlace } as any;
    updatedPlace[property] = updatedPlace[property] || {};
    updatedPlace[property][locale] = value;
    this.setState({
      editPlace: updatedPlace
    });
  }

  /**
   * Event handler for textfield change
   * 
   * @param key key

   * @param value value
   */
  private onTextFieldValueChange = (key: string, value: string) => {

    const { editPlace } = this.state;
    const updatedPlace = { ...editPlace } as any;
    updatedPlace[key] = updatedPlace[key] || {};
    updatedPlace[key] = value;
    this.setState({
      editPlace: updatedPlace
    });
  }

  /**
   * Event handler for confirm dialog confirm click
   */
  private onDeleteConfirmDialogConfirm = async () => {
    const { accessToken } = this.props;
    const { editId, tableData } = this.state;

    this.setState({
      drawerOpen: false,
      loading: true,
      deleteConfirmOpen: false
    });

    if (!editId) {
      return;
    }

    const id = EventUtils.parseIdFromUrl(editId);

    try {
      await EventUtils.deleteExistingPlace(id, accessToken);
    } catch (error) {
      console.log(error);
    }

    this.setState({
      editId: undefined,
      loading: false,
      tableData: tableData.filter(TableRow => TableRow.id !== editId),
      snackbarMessage: this.getSnackbarMessage("delete")
    });
  }

  /**
   * Event handler for confirm dialog cancel click
   */
  private onDeleteConfirmDialogCancel = () => {
    this.setState({
      deleteConfirmOpen: false
    });
  }

  /**
   * Event handler for place deletion click
   */
  private onDeleteClick = () => {
    this.setState({
      deleteConfirmOpen: true
    });
  }

  /**
   * Event handler for cancel editing / adding place click
   */
  private onCancelClick = () => {
    this.setState({
      drawerOpen: false
    });
  }

  /**
   * Event handler for place save click
   */
  private onSaveClick = async () => {
    const { editId } = this.state;

    if (!editId) {
      await this.createPlace();
    } else {
      const start = new Date().getTime();
      await this.updatePlace();
      const end = new Date().getTime();
      console.error(`${new Date(end - start).getSeconds()}s`);
      this.clearState();
    }
  }

  /**
   * Method for clearing state
   */
  private clearState = () => {
    this.setState({
      drawerOpen: false,
      editId: undefined,
      latitudeString: "",
      longitudeString: "",
      editPlace: {
        name: {
          fi: "",
          sv: "",
          en: ""
        },
        addressLocality: {
          fi: "",
          sv: "",
          en: ""
        },
        infoUrl: {
          fi: "",
          sv: "",
          en: ""
        },
        addressCountry: "",
        streetAddress: {
          fi: "",
          sv: "",
          en: ""
        },
        customData: {
          listed: "false"
        },
        postalCode: "",
        position: {
          type: PlacePositionTypeEnum.Point,
          coordinates: []
        }
      }
    });
  }

  /**
   * Event handler for drawer close click
   */
  private onCloseClick = () => {
    this.setState({
      drawerOpen: false
    });
  }
  
  /**
   * Event handler for table row select
   * 
   * @param data selected row data
   */
  private onTableRowSelect = (data: RowData) => {

    if (!data.place || !data) {
      return;
    }

    if (!data.place.position) {
      data.place.position = {
        type: PlacePositionTypeEnum.Point,
        coordinates: [62.787123, 22.850832]
      }
    }

    if (data.place.position.coordinates) {
      this.setState({
        editId: data?.id,
        editPlace: data?.place,
        drawerOpen: true,
        latitudeString: this.coordinateTostring(data.place.position.coordinates[0]),
        longitudeString: this.coordinateTostring(data.place.position.coordinates[1]),
      });
    }
  }

  /**
   * Event handler for sie menu toggle
   */
  private onAddButtonClick = () => {
    this.setState({
      drawerOpen: true,
      editId: undefined,
      latitudeString: "62.799252",
      longitudeString: "22.850832",
      editPlace: {
        name: {
          fi: "",
          sv: "",
          en: ""
        },
        addressLocality: {
          fi: "",
          sv: "",
          en: ""
        },
        infoUrl: {
          fi: "",
          sv: "",
          en: ""
        },
        addressCountry: "",
        streetAddress: {
          fi: "",
          sv: "",
          en: ""
        },
        customData: {
          listed: "false"
        },
        postalCode: "",
        position: {
          type: PlacePositionTypeEnum.Point,
          coordinates: []
        }
      }
    });
  }

  /**
   * Clears the snackbar message
   */
  private clearSnackbar = () => {
    this.setState({
      snackbarMessage: undefined
    });
  }

  /**
   * Returns snackbar message for given message object
   * 
   * @param message message
   * @return snackbar message
   */
  private getSnackbarMessage = (message?: Message): SnackbarMessage | undefined => {
    if (!message) {
      return undefined;
    }

    switch (message) {
      case "created":
        return {
          message: strings.place.placeAddedSuccess,
          severity: "success"
        }
      case "edited":
        return {
          message: strings.place.placeEditSuccess,
          severity: "success"
        }
      case "no-permission":
        return {
          message: strings.errors.failMessage,
          severity: "error"
        }
      case "delete":
        return {
          message: strings.place.placeDeleteSuccess,
          severity: "success"
        }
    }
  }
}

const Styled = withStyles(styles)(AdminPlacesTab);
export default Styled;
