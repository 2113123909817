/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * geographic position of the place specified using subset of GeoJSON
 * @export
 * @interface PlacePosition
 */
export interface PlacePosition {
    /**
     * coordinates in format specified by type property
     * @type {Array<number>}
     * @memberof PlacePosition
     */
    coordinates?: Array<number>;
    /**
     * interpretation of the coordinates property. Only point is supported in this version
     * @type {string}
     * @memberof PlacePosition
     */
    type?: PlacePositionTypeEnum;
}

export function PlacePositionFromJSON(json: any): PlacePosition {
    return PlacePositionFromJSONTyped(json, false);
}

export function PlacePositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlacePosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coordinates': !exists(json, 'coordinates') ? undefined : json['coordinates'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function PlacePositionToJSON(value?: PlacePosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coordinates': value.coordinates,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum PlacePositionTypeEnum {
    Point = 'Point'
}


