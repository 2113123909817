import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  loaderContainer: {
    minHeight: 500,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },

  loader: {
    alignSelf: "center"
  },

  container: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    maxWidth: 1200,
    [theme.breakpoints.up("lg")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "80vw",
    }
  },

  paper: {
    padding: theme.spacing(5)
  },

});