/* tslint:disable */
/* eslint-disable */
/**
 * Linked Events information API
 * Linked Events provides categorized data on events and places using JSON-LD format. Events can be searched by date and location. Location can be exact address or larger area such as neighbourhood or borough JSON-LD format is streamlined using include mechanism. API users can request that certain fields are included directly into the result, instead of being hyperlinks to objects. Several fields are multilingual. These are implemented as object with each language variant as property. In this specification each multilingual field has (fi,sv,en) property triplet as example.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Image,
    ImageFromJSON,
    ImageToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
} from '../models';

export interface ImageCreateRequest {
    name: string;
    url: string;
}

export interface ImageListRequest {
    page?: number;
    pageSize?: number;
    include?: Array<string>;
    sort?: string;
}

export interface ImageRetrieveRequest {
    id: string;
}

export interface ImageUpdateRequest {
    id: string;
    imageObject?: Image;
}

/**
 * 
 */
export class ImageApi extends runtime.BaseAPI {

    /**
     * There are two ways to create an image object. The image file can be posted as a multipart request, but the endpoint also accepts a simple JSON object with an external url in the url field. This allows using external images for events without saving them on the API server.
     * Create a new image
     */
    async imageCreateRaw(requestParameters: ImageCreateRequest): Promise<runtime.ApiResponse<Image>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.url !== undefined) {
            formParams.append('url', requestParameters.url as any);
        }

        if (requestParameters.name !== undefined) {
          formParams.append('name', requestParameters.name as any);
        }

        const response = await this.request({
            path: `/image/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageFromJSON(jsonValue));
    }

    /**
     * There are two ways to create an image object. The image file can be posted as a multipart request, but the endpoint also accepts a simple JSON object with an external url in the url field. This allows using external images for events without saving them on the API server.
     * Create a new image
     */
    async imageCreate(requestParameters: ImageCreateRequest): Promise<Image> {
        const response = await this.imageCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Image endpoint returns images that are used for events, places or organizers. 
     * Returns a list of images
     */
    async imageListRaw(requestParameters: ImageListRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.include) {
            queryParameters['include'] = requestParameters.include.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/image/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Image endpoint returns images that are used for events, places or organizers. 
     * Returns a list of images
     */
    async imageList(requestParameters: ImageListRequest): Promise<InlineResponse2001> {
        const response = await this.imageListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Return information for single image
     */
    async imageRetrieveRaw(requestParameters: ImageRetrieveRequest): Promise<runtime.ApiResponse<Image>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/image/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageFromJSON(jsonValue));
    }

    /**
     * Return information for single image
     */
    async imageRetrieve(requestParameters: ImageRetrieveRequest): Promise<Image> {
        const response = await this.imageRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Images can be updated if the user has appropriate access permissions. The original implementation behaves like PATCH, ie. if some field is left out from the PUT call, its value is retained in database. In order to ensure consistent behaviour, users should always supply every field in PUT call.
     * Update an image
     */
    async imageUpdateRaw(requestParameters: ImageUpdateRequest): Promise<runtime.ApiResponse<Image>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling imageUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/image/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ImageToJSON(requestParameters.imageObject),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageFromJSON(jsonValue));
    }

    /**
     * Images can be updated if the user has appropriate access permissions. The original implementation behaves like PATCH, ie. if some field is left out from the PUT call, its value is retained in database. In order to ensure consistent behaviour, users should always supply every field in PUT call.
     * Update an image
     */
    async imageUpdate(requestParameters: ImageUpdateRequest): Promise<Image> {
        const response = await this.imageUpdateRaw(requestParameters);
        return await response.value();
    }

}
