import * as React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

/**
 * Interface representing component properties
 */
interface Props extends SvgIconProps {
  htmlColor?: string;
}

/**
 * Render map icon
 */
const CloseIcon = (props: Props) => {
  return (
    <SvgIcon {...props}>
      <path 
        d="M15.3,5.4c0.9,0.9,1.4,2.1,1.4,3.3c0,1.2-0.5,2.4-1.4,3.3c-0.9,0.9-2.1,1.4-3.3,1.4
        c-1.2,0-2.4-0.5-3.3-1.4c-0.9-0.9-1.4-2.1-1.4-3.3c0-1.2,0.5-2.4,1.4-3.3h0C9.6,4.5,10.8,4,12,4C13.2,4,14.4,4.5,15.3,5.4L15.3,5.4z
        M18.2,2.5c5.7,5.6,0.3,12.9-3.7,18.4c-0.7,1-1.4,1.9-2,2.8c-0.2,0.3-0.6,0.4-0.9,0.2c-0.1-0.1-0.1-0.1-0.2-0.2
        c-0.6-0.8-1.2-1.8-2-2.8c-4-5.5-9.4-12.8-3.7-18.4c0.8-0.8,1.8-1.4,2.9-1.9C9.7,0.2,10.8,0,12,0c1.2,0,2.3,0.2,3.4,0.7
        C16.4,1.1,17.4,1.7,18.2,2.5L18.2,2.5z M13.5,20.1c3.7-5.1,8.7-11.9,3.8-16.7c-0.7-0.7-1.5-1.2-2.4-1.6C14,1.5,13,1.3,12,1.3
        c-1,0-2,0.2-2.9,0.6C8.2,2.3,7.4,2.8,6.7,3.5C1.8,8.3,6.8,15,10.5,20.1c0.5,0.7,1,1.4,1.5,2C12.4,21.5,12.9,20.8,13.5,20.1
        L13.5,20.1z M15.3,8.7c0-0.9-0.3-1.7-1-2.4c-0.6-0.6-1.5-1-2.4-1c-0.9,0-1.7,0.3-2.4,1c-0.6,0.6-1,1.5-1,2.4c0,0.9,0.4,1.7,1,2.4
        c0.6,0.6,1.5,1,2.4,1c0.9,0,1.7-0.4,2.4-1C15,10.4,15.3,9.6,15.3,8.7L15.3,8.7z"
      />
    </SvgIcon>
  );
}

export default CloseIcon;